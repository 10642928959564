import * as React from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { getAppraisalCycle, getBehaviourList } from '../action/Master';
import { onChange, validateForm, } from '../utils';
import CONSTANT from '../constant'

class Weightage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const weightage = props.Weightage;

        this.state = {
            maxActivities: { name: 'maxActivities', value: weightage ? weightage.maxActivities : '', isRequired: true, error: '' },
            maxBehaviour: { name: 'maxBehaviour', value: weightage ? weightage.maxBehaviour : '', isRequired: true, error: '' },
            maxDepartment: { name: 'maxDepartment', value: weightage ? weightage.maxDepartment : '', isRequired: true, error: '' },
            designation: { name: 'designation', value: weightage ? weightage.designation : '', isRequired: true, error: '' },
            department: { name: 'department', value: weightage ? weightage.department : '', isRequired: true, error: '' },
            activities: { name: 'activities', value: weightage ? weightage.activities : '', isRequired: true, error: '' },
            monthCycle: { name: 'monthCycle', value: weightage ? weightage.monthCycle : '', error: '', isRequired: true },
            behaviour: { name: 'behaviour', value: weightage ? weightage.behaviour : '', isRequired: true, error: '' },
            yearCycle: { name: 'yearCycle', value: weightage ? weightage.yearCycle : '', error: '', isRequired: true },
            code: { name: 'code', value: weightage ? weightage.code : '' },
            performancelList: [],
            appraisalList: [],
            behaviourList: [],

        }
    }
    componentDidMount() {
        this.laodAppraisalList();
        this.laodBheviourlList();

    }
    public render() {
        const { behaviour, department, activities, maxBehaviour, maxDepartment, designation, behaviourList, maxActivities, monthCycle, yearCycle, performancelList, appraisalList } = this.state;
        return (

            <section>
                <div className="col-lg-12 main-container">
                    <div className="fluid-container">
                        <div className="row">
                            <div className="col-lg-12 mb-3">

                                <div className="col-lg-12 mt-4">
                                    <div className="row">
                                        <div className="col-lg-6 pl-0">
                                            <h5 className="heading-h1">Weightage Defination </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mt-2">
                                    <div className="card-header">
                                        <b> Defination
                                            </b>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td scope="col">Appraisal Cycle</td>
                                                        <td>
                                                            {this.renderSelect(yearCycle, appraisalList, 'appraisal')}                                                            </td>
                                                    </tr>

                                                    <tr>
                                                        <td scope="col">Sub Performance</td>
                                                        <td>
                                                            {this.renderSelect(monthCycle, performancelList, 'preformance')}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col">Designation / Level</td>
                                                        <td>
                                                            {this.renderSelect(designation, behaviourList, 'name')}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td scope="col">Behaviour</td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className={behaviour.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                                placeholder="20"
                                                                onChange={this._onChange}
                                                                name={behaviour.name}
                                                                value={behaviour.value}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col"> Maximum Weightage of Any Behaviour </td>
                                                        <td>
                                                            <NumberFormat
                                                                type="text"
                                                                className={maxBehaviour.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                                placeholder="10"
                                                                onChange={this._onChange}
                                                                name={maxBehaviour.name}
                                                                value={maxBehaviour.value}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col">Department</td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className={department.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                                placeholder="20"
                                                                onChange={this._onChange}
                                                                name={department.name}
                                                                value={department.value}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col"> Maximum Weightage of Any Department </td>
                                                        <td>
                                                            <NumberFormat
                                                                type="text"
                                                                className={maxDepartment.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                                placeholder="10"
                                                                onChange={this._onChange}
                                                                name={maxDepartment.name}
                                                                value={maxDepartment.value}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col">Activities</td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className={activities.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                                placeholder="60"
                                                                onChange={this._onChange}
                                                                name={activities.name}
                                                                value={activities.value}
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="col"> Maximum Weightage of Any Activities </td>
                                                        <td>
                                                            <NumberFormat
                                                                type="text"
                                                                className={maxActivities.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                                placeholder="10"
                                                                onChange={this._onChange}
                                                                name={maxActivities.name}
                                                                value={maxActivities.value}
                                                            />
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-12 mt-3 mb-3">
                                    <button onClick={this.onSubmit} className="col-lg-2 btn button-full pull-right"> Submit </button>
                                    <Link to={CONSTANT.url.weightage} className="col-lg-2 btn  btn-secondary mr-3 pull-right">Cancel</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

        )
    }


    renderSelect = (select: any, option: any, flag: string) => {
        return (<select name={select.name} value={select.value}
            onChange={flag == 'appraisal' ? this._onChange1 : this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option> --Select--</option>
                {option.length > 0 && option.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={flag == 'appraisal' ? e.code : flag == 'preformance' ? e.code : e.name}>{flag == 'appraisal' ? e.yearCycle : flag == 'preformance' ? e.name : e.name}</option>)
                })}
            </React.Fragment>
        </select >)
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        if (value > 100) {
            alert('Please enter lass than 100');
            return
        }
        onChange(this, name, value)
    }

    private _onChange1 = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value)
        const prf = this.state.appraisalList.filter((res: any) => { return res.code == value; }).map((res: any) => res.performance)[0].sort((a: any, b: any, ) => b.createdOn - a.createdOn);
        this.setState({
            performancelList: prf,
        })
    }

    private laodAppraisalList = () => {
        this.setState({ showLoader: true })
        getAppraisalCycle().then((response: any) => {
            if (response && response.result && response.result.length > 0)
                this.setState({
                    appraisalList: response.result,
                    performancelList: response.result[0].performance,
                    showLoader: false,
                });

        });
    }
    private laodBheviourlList = () => {
        this.setState({ showLoader: true })
        getBehaviourList().then((response: any) => {
            if (response && response.result && response.result.length > 0)
                this.setState({
                    behaviourList: response.result,
                    showLoader: false,
                });

        });
    }

    onSubmit = (e: any) => {
        e.preventDefault()
        const { maxActivities, maxDepartment, maxBehaviour, department, designation, activities, monthCycle, behaviour, yearCycle, code } = this.state
        const model = {
            maxActivities: maxActivities.value,
            maxDepartment: maxDepartment.value,
            maxBehaviour: maxBehaviour.value,
            designation: designation.value,
            department: department.value,
            activities: activities.value,
            monthCycle: monthCycle.value,
            behaviour: behaviour.value,
            yearCycle: yearCycle.value,
            code: code.value,
        }
        if (maxActivities > 100 || maxDepartment > 100 || maxBehaviour > 100 || department > 100 || designation > 100 || activities > 100 || behaviour > 100) {
            alert('All weightage should be lass than or equal to 100');
            return
        }
        if (validateForm(this, { maxActivities, maxDepartment, maxBehaviour, department, designation, activities, monthCycle, behaviour, yearCycle, code }))
            this.props.onSubmit(model)
    }

}

export default Weightage;