import * as React from 'react';
import { Link } from 'react-router-dom';
import CONSTANT from '../../../constant';

import { getAppraisal, deleteAppraisalType, associateEmployee } from '../../../action/admin/Appraisal';


class StartAppraisal extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            leaveList: undefined
        }
    }

    componentDidMount() {
        this.getAllAppraisal();
    }

    public render() {
        const { leaveList } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-6 col-lg-6 pl-0">
                                                <h5 className="heading-h1">Start Appraisal Cycle</h5>
                                            </div>
                                            <div className="col-6 col-lg-6 text-right pr-0">
                                                <Link to={CONSTANT.url.addAppraisal} className="action-btn">
                                                    <i className="fa fa-check-square-o"></i>&nbsp; Start Appraisal</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Appraisal</b>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Year Cycle</th>
                                                            <th scope="col">Remark</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Edit</th>
                                                            <th scope="col">Associate Employee</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            leaveList === undefined && <tr>
                                                                <td className="text-center" colSpan={8}>Loading...</td>
                                                            </tr>
                                                        }
                                                        {
                                                            leaveList !== undefined && leaveList.length === 0 && <tr>
                                                                <td className="text-center" colSpan={8}>No records found</td>
                                                            </tr>
                                                        }
                                                        {
                                                            leaveList !== undefined &&
                                                            leaveList.map((item: any, ind: number) => {
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.startDate}</td>
                                                                        <td>{item.endDate}</td>
                                                                        <td>{item.yearCycle}</td>
                                                                        <td>{item.remark}</td>
                                                                        <td>{item.status}</td>

                                                                        <th scope="col">
                                                                            <Link to={CONSTANT.url.editAppraisal.replace(':code', item.code)}>
                                                                                <i className="fa fa-pencil add-plus"></i>
                                                                            </Link> &nbsp;&nbsp;&nbsp;
                                                                            <i className="fa fa-close add-plus" onClick={() => this.onDelete(item.code)}></i>

                                                                        </th>
                                                                        <th>
                                                                            <a href="javascript:void(0)" onClick={() => { this.associateEmp(item.code) }}>Click</a>
                                                                        </th>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }


    private onDelete = (code: String) => {
        deleteAppraisalType(code).then((response: any) => {
            alert('Record Update Successfully')
            if (response)
                this.getAllAppraisal();
        });

    }

    private associateEmp = (e: any) => {

        associateEmployee({ code: e }).then((response: any) => {
            if (response)
                alert('All Employee have been associated with cycle.')
        });

    }


    private getAllAppraisal = () => {
        getAppraisal(undefined).then((response: any) => {
            if (response && response.result && response.result.length > 0)
                this.setState({
                    leaveList: response.result,
                });
        });
    }

}

export default StartAppraisal;