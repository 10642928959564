export default {
    url: {

        login: '/',

        forgetPassword: '/forget-password',
        resetPassword: '/reset-password',
        changePassword: '/change-password',
        register: '/register',
        logout: '/logout',

        dashboard: '/admin/dashboard',

        editWeightage: '/admin/weightage/edit/:code',
        addWeightage: '/admin/weightage/add',
        weightage: '/admin/weightage',

        appraisalHiistory: '/admin/appraisal/history',
        editAppraisal: '/admin/appraisal/edit/:code',
        startAppraisal: '/admin/appraisal/list',
        addAppraisal: '/admin/appraisal/add',
        viewGoal: '/admin/viewGoal',

        editPerformance: '/admin/sub-performance/edit/:code',
        performanceList: '/admin/sub-performance/list',
        addPerformance: '/admin/sub-performance/add',

        editEmployee: '/admin/employee/edit/:code',
        uploadEmployee: '/admin/employee/upload',
        employeeList: '/admin/employee/list',
        addEmployee: '/admin/employee/add',

        editband: '/admin/band/edit/:code',
        bandList: '/admin/band/list',
        addband: '/admin/band/add',

        editBehaviour: '/admin/behaviour-skills/edit/:code',
        addBehaviour: '/admin/behaviour-skills/add',
        behaviourList: '/admin/behaviour-skills/list',

        editDepartment: '/admin/department-skills/edit/:code',
        departmentList: '/admin/department-skills/list',
        addDepartment: '/admin/department-skills/add',

        goalReview: '/goal/review/edit',
        review: '/goal/review/list/:id',
        pendinAssesment: '/admin/appraisal/pendingAssesmentEmployee',

        report: '/admin/report',
        rating: '/admin/rating',
        reportPdf: '/admin/report/pdf',

        employeeTransfer: '/admin/employee/transfer',
        empAppraiseEmployee: '/appraise-employee',
        empSelfAppraisal: '/self-appraisal/edit/:code',
        empAppraisal: '/appraisal-cycle',
        employeeDashboard: '/dashboard',
        empReviewCycle: '/review-cycle',
        empGoalHistory: '/goal/history',
        empEditGoal: '/goal/edit/:code',
        empApproved: '/goal/approved',
        empSetGoal: '/goal/list',
        empAddGoal: '/goal/add',
    },
    userType: {
        admin: 'admin',
        manager: 'manager'
    }

}