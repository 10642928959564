import * as React from 'react';
import { Link } from 'react-router-dom';

import menu_icon1 from '../../assets/images/menu-icon1.png';
import menu_icon3 from '../../assets/images/menu-icon3.png';

import menu_icon4 from '../../assets/images/menu-icon4.png';
import menu_con19 from '../../assets/images/menu-icon19.png';
import menu_icon2 from '../../assets/images/menu-icon2.png';
import home_icon2 from '../../assets/images/home-icon2.png';
import menu_icon6 from '../../assets/images/menu-icon6.png';
import menu_con16 from '../../assets/images/menu-icon16.png';
import menu_icon5 from '../../assets/images/menu-icon5.png';
import logo from '../../assets/images/logo_full.png';

import { getCookie } from '../../utils';
import CONSTANT from '../../constant';

class Sidebar extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    componentWillMount() {
    }

    public render() {
        const { } = this.state;
        const { userType } = JSON.parse(window.atob(getCookie('token').split('.')[1]));
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12" onMouseUp={this.closeNav}>
                        <div className="left-menu">
                            <div id="mySidebar" className="sidebar">
                                <div className="menu-header">
                                    <img src={logo} />
                                </div>
                                <a href="javascript:void(0)" className="closebtn close-icon" onClick={this.closeNav} >×</a>
                                {userType == 'admin' && <Link to={CONSTANT.url.dashboard}><img src={menu_icon1} />Dashboard</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.startAppraisal}><img src={menu_icon2} />Start Appraisal</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.performanceList}><img src={menu_icon3} />Start Sub Performance</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.uploadEmployee}><img src={menu_icon4} />Add Employee</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.employeeList}><img src={menu_icon5} />Employee List</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.behaviourList}><img src={menu_icon5} />Behaviour List</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.departmentList}><img src={menu_con19} />Department Skills</Link>}
                                {/* getCookie('token') == 'admin' && {/* <Link to={CONSTANT.url.goalReview}><img src={menu_icon8} />Goal Review</Link>*/}
                                {/*getCookie('token') == 'admin' &&*/} {/* <Link to={CONSTANT.url.review}><img src={menu_icon2} />Review View</Link>*/}
                                {userType == 'admin' && <Link to={CONSTANT.url.weightage}><img src={home_icon2} />Weightage Defination</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.bandList}><img src={home_icon2} />Define Band</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.appraisalHiistory}><img src={menu_icon6} />Appraisal History</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.viewGoal}><img src={menu_icon3} />User Goal</Link>} 
                               
                                {userType == 'admin' && <Link to={CONSTANT.url.report}><img src={menu_con16} />Report</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.rating}><img src={menu_con16} />RatingReport</Link>}


                                {userType !== 'admin' && <Link to={CONSTANT.url.employeeDashboard}><img src={menu_icon1} />Dashboard</Link>}
                                {userType !== 'admin' && <Link to={CONSTANT.url.empAppraisal}><img src={menu_icon2} /> Appraisal Cycle</Link>}
                                {userType !== 'admin' && <Link to={CONSTANT.url.empSetGoal}><img src={menu_icon3} />Set Goal</Link>}
                                {/* {userType == 'manager' && <Link to={CONSTANT.url.empReviewCycle}><img src={menu_icon4} />Review Cycle</Link>} */}
                                {/* {userType !== 'admin' && <Link to={CONSTANT.url.empSelfAppraisal}><img src={menu_icon5} />Self Appraisal</Link>} */}
                                {/* {userType !== 'admin' && <Link to={CONSTANT.url.empGoalHistory}><img src={menu_icon5} />Goal History</Link>} */}
                                {userType == 'manager' && <Link to={CONSTANT.url.empAppraiseEmployee}><img src={home_icon2} />Appraise Employee</Link>}
                                {userType == 'admin' && <Link to={CONSTANT.url.pendinAssesment}><img src={home_icon2} />Pending Assesment List</Link>}

                                <Link to={CONSTANT.url.logout}><img src={menu_icon5} />Log Out</Link>
                            </div>
                            <button className="openbtn" onClick={this.openNav}>☰ </button>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        )
    }

    openNav = () => {
        const mySidebar: any = document.getElementById("mySidebar");
        mySidebar.style.width = "250px";
        const main: any = document.getElementById("main");
        // main.style.marginLeft = "250px";
    }

    closeNav = () => {
        const mySidebar: any = document.getElementById("mySidebar")
        mySidebar.style.width = "0";
        const main: any = document.getElementById("main")
        // main.style.marginLeft = "0";
    }
}

export default Sidebar;