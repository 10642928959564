import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { validateForm, isValidDate, onChange } from '../../../utils';
import { getEmployee } from '../../../action/admin/Employee';
import { getAppraisalCycle } from '../../../action/Master';
import CONSTANT from '../../../constant';

class EmployeeList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            employeeList: undefined,
            search: { name: 'search', value: '', },
            yearCycle: { name: 'yearCycle', value: '', options: [], error: '', isRequired: true },
            monthCycle: { name: 'monthCycle', value: '', options: [], error: '', isRequired: true },

        }


    }

    componentDidMount() {
        this.laodAppraisalList();
        // this.loadEmployeeList();
    }

    public render() {

        const { employeeList, search, yearCycle, monthCycle } = this.state;
        let filterEmp: any = [];
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">List of Employee</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Appraisal
                                           </b>
                                        </div>
                                        <div className="card-body">

                                            {
                                                <form>
                                                    <div className="col-lg-12 mb-2">
                                                        <div className="row">
                                                            <div className="col-lg-6 form-group">
                                                                <label > Appraisal Cycle  </label>
                                                                {this.renderSelect(yearCycle, false)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Sub Performance </label>
                                                                {this.renderSelect(monthCycle, true)}
                                                            </div>
                                                        </div>
                                                        <a href="" className="col-lg-2 pull-right">
                                                            <button onClick={this.loadEmpList} className="btn button-full action-btn">Filter</button>

                                                        </a>
                                                    </div>
                                                </form>
                                            }
                                        </div>

                                    </div>
                                    <div className="card mt-3">
                                        <div className="card-header">
                                            <div className="row">
                                                <div className="col-lg-6 pt-2">
                                                    <b> Employee </b>
                                                </div>

                            
                                                <div className="col-lg-6">
                                                <Link to={CONSTANT.url.forgetPassword}><button className="col-lg-6 btn button-full action-btn">Reset Password</button></Link>
                                                   {"  "} <input
                                                        className="col-lg-6 form-control pull-right"
                                                        placeholder='Search By Employee Id or Name'
                                                        name={search.name}
                                                        value={search.value}
                                                        onChange={this._onChange}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Employee Id</th>
                                                            <th scope="col">Employee Name</th>
                                                            <th scope="col">Email Id</th>
                                                            <th scope="col">Mobile No</th>
                                                            <th scope="col">Designation</th>
                                                            <th scope="col">Department</th>
                                                            <th scope="col">Location</th>
                                                            <th scope="col">Reporting Officer</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">User Type</th>
                                                            <th scope="col">Created Date</th>
                                                            <th scope="col">Employee Transfer</th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            this.filterEmployee(employeeList, search.value)
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </React.Fragment>

        )
    }
    renderSelect = (select: any, f: boolean) => {
        console.log(select, f);

        return (<select name={select.name} value={select.value}
            onChange={this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {select.options.length && select.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.code}>{f ? e.name : e.yearCycle}</option>)
                })}
            </React.Fragment>
        </select>)
    }


    private filterEmployee = (employeeList: any, search: any) => {

        if (employeeList == undefined)
            return <tr key="1"><td className="text-center" colSpan={13}>Loading...</td> </tr >

        else if (employeeList !== undefined || (employeeList && employeeList.length > 0)) {

            const filterList = employeeList.filter((item: any, ) => {
                if (search === '')
                    return true;
                if (new RegExp(search, 'gi').test(item.name) || new RegExp(search, "gi").test(item.empCode))
                    return true
            })
            if (filterList.length) {
                return filterList.map((item: any, ind: number) => {
                    return (
                        <tr key={ind}>
                            <td>{item.empCode}</td>
                            <td>{item.name}</td>
                            <td>{item.emailId}</td>
                            <td>{item.mobile}</td>
                            <td>{item.designation}</td>
                            <td>{item.department}</td>

                            <td>{item.location}</td>
                            <td>{item.reportingOfficer.length ? item.reportingOfficer[0].name : 'Not Asign yet'}</td>
                            <td>{
                                item.status == 'Active' ? <span className="completed">{item.status}</span> : <span className="pending">{item.status}</span>
                            }</td>
                            <td>{item.userType}</td>
                            <td>{moment.unix(item.createdOn).format('MMM Do YYYY')}</td>
                            <th scope="col">
                                <Link className="action-btn" to={`${CONSTANT.url.employeeTransfer}?userCode=${item.code}&yearCycleCode=${item.yearCycle.code}&monthCycleCode=${item.subPerformanceCycle.code}&yName=${item.yearCycle.name}&mName=${item.subPerformanceCycle.name}`}>
                                    <i className="fa fa-check-square-o"> </i>&nbsp;Settings

                                </Link>

                            </th>
                        </tr>
                    )
                })
            }
            else
                return <tr key="1"> <td className="text-center" colSpan={13}>No records found</td> </tr>
        }
        else
            return <tr key="2"> <td className="text-center" colSpan={13}>No records found</td> </tr>
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value, () => {
            if (name === this.state.yearCycle.name) {
                let mnth = value && this.state.yearCycle.options.filter((res: any) => { return res.code == value; }).map((res: any) => res.performance)
                this.setState({
                    monthCycle: { ...this.state.monthCycle, options: this.sort(mnth[0]) },
                })
            }
        })

    }

    private laodAppraisalList = () => {
        this.setState({ showLoader: true })
        getAppraisalCycle().then((res: any) => {
            if (res && res.result && res.result.length > 0) {
                const mnth = res.result[0].performance.length > 0 ? this.sort(res.result[0].performance) : [];
                this.setState({
                    yearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[0].code },
                    monthCycle: { ...this.state.monthCycle, options: mnth, value: mnth.length > 0 ? mnth[0].code : '' },
                    showLoader: false,
                }, () => {
                    this.loadEmployeeList()
                });
            }
        });
    }

    private sort = (arr: any, order?: number) => {
        if (arr.length > 1)
            return arr.sort((a: any, b: any, ) => b.createdOn - a.createdOn)
        else
            return arr
    }


    private loadEmpList = (e: any) => {
        e.preventDefault();
        this.loadEmployeeList()
    }

    private loadEmployeeList = () => {
        const { yearCycle, monthCycle } = this.state;
        const model = {
            yearCycle: yearCycle.value, monthCycle: monthCycle.value
        }
        this.setState({ employeeList: undefined })
        getEmployee(model).then((res: any) => {
            if (res && res.result)
                this.setState({
                    employeeList: res.result,
                });
        });
    }

}

export default EmployeeList;