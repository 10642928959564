import * as React from 'react';

import { isEmpty, onChange, setCookie } from './../../utils';
import Loader from '../../component/common/Loader';
import { login } from '../../action/AuthAction'
import CONSTANT from './../../constant';

class Login extends React.Component<any, any> {
    constructor(props: any) {
        
        super(props);
        
        this.state = {
            userName: { name: 'userName', value: '', isRequired: true, error: '' },
            password: { name: 'password', value: '', isRequired: true, error: '' },
            showLoader: false
        }
    }

    public render() {
        const { userName, password, showLoader } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="login-container">
                        <div className="col-lg-12 login-block">
                            <div className="row">
                                <div className="col-md-6 login-form-container mx-auto">
                                    <div className="card px-4 py-4">
                                        <h2 className="text-left">EPMS</h2>
                                        <form className="login-form">
                                            <div className="form-group">
                                                <label>Username</label>
                                                <input
                                                    type="text"
                                                    className={userName.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                    placeholder="Enter your email Or employee Id"
                                                    name={userName.name}
                                                    value={userName.value}
                                                    onChange={this._onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label >Password</label>
                                                <input
                                                    type="password"
                                                    className={password.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                    placeholder="*******"
                                                    name={password.name}
                                                    value={password.value}
                                                    onChange={this._onChange}
                                                />
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"

                                                    />
                                                    <small>Remember Me</small>
                                                </label>
                                            </div>
                                            <a href={CONSTANT.url.forgetPassword} className="forget-password">Forget Password</a>
                                            <div className="mt-4">
                                                <button onClick={this.onSubmit} className="btn button-full float-right">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value)
    }

    private _setError = (name: string, error: string) => {
        onChange(this, name, error);
    }

    private _validateForm = () => {
        const { userName, password } = this.state;
        let status = true;

        if (isEmpty(userName.value)) {
            this._setError('userName', 'error');
            status = false;
        }

        if (isEmpty(password.value)) {
            this._setError('password', 'error');
            status = false;
        }
        return status;
    }

    private _clearFormError = () => {
        this._setError('userName', '');
        this._setError('password', '');
    }

    private _getJsonToPOST = (state: any) => {
        const stateData = JSON.parse(JSON.stringify(state));
        const jsonToReturn = {
            userName: stateData.userName.value.trim(),
            password: stateData.password.value.trim()
        };

        return jsonToReturn;
    }


    private onSubmit = (e: any) => {

        e.preventDefault();
        // this._clearFormError();
        const isFormValid = this._validateForm();

        if (isFormValid) {
            const jsonToPost = this._getJsonToPOST(this.state);
            console.log("jsonToPost  => ", jsonToPost);
            this.setState({ showLoader: true })
            login(jsonToPost)
                .then((res: any) => {
                    this.setState({ showLoader: false })
                    if (res)
                        if (CONSTANT.userType.admin == res.result.userType)
                            this.props.history.push(CONSTANT.url.dashboard)
                        else
                            this.props.history.push(CONSTANT.url.employeeDashboard)

                })

        }
    }



}

export default Login;