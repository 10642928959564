
import React from 'react';
import ReactDOM from 'react-dom';
import { savePDF } from '@progress/kendo-react-pdf';
import pdfLogo from '../../assets/images/logo_pdf.png';
import GoalHistory from '../../container/employee/setGoal/GoalHistory';
import { onChange } from '../../utils';
import moment from 'moment';


export default class App extends React.Component<any, any>{
    table: HTMLElement | any;

    constructor(props: any) {
        super(props);
        this.state = {
            data: props.data,
            filter: { name: 'filter', value: 'all', error: '', isRequired: true, },
        }
    }
    render() {
        const { data, filter } = this.state
        return (
            <section>
                {/* <div className="container"> */}
                <div className="row">
                    <div className="col-lg-12">
                        {/* <div className="col-lg-12 mt-4">
                            <div className="row">
                                <div className="col-6 col-lg-6 pl-0">
                                    <h5 className="heading-h1">Appraisal Report</h5>
                                </div>
                            </div>
                        </div> */}

                        <div className="card mt-2">
                            <div className="card-header">
                                <div className='row'>
                                    <div className="col-6 col-lg-6 form-group">
                                        <label >Goal Tracker</label>
                                        <select name={filter.name} value={filter.value}
                                            onChange={this._onChange}
                                            className={filter.error.length > 0 ? "form-control is-invalid" : "form-control"}>
                                            <option value="all">All Employee</option>
                                            <option value="pending">Pending Employee</option>
                                            <option value="approved">Approved Employee</option>
                                            <option value="reject">Rejectd Employee</option>
                                            <option value="completed">Completed Employee</option>
                                            <option value="dualReporting">Dual Reporting Employee</option>
                                        </select>
                                    </div>
                                    <div className="col-6 col-lg-6 form-group" >
                                        <button className="col-lg-4 btn button-full mt-4" onClick={this.exportPDF}>Export PDF</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" id="printArea" style={{ marginTop: 60 }} ref={(table) => { this.table = table; }}>
                                {data.length > 0 && data.map((l: any, i: number) => {
                                    console.log("datr", data);
                                    let CommonRating = data.filter((item: any) => {
                                        if (['62baf5c4570d687cedb11563', '624d2d81234a440ad18c32c1', '63329f829f66f80aed09418a'].includes(item._id)) {
                                            return item
                                        }
                                    })


                                    return (
                                        <div >
                                            {/* PART-1  */}
                                            < table className="table table-bordered tab " >
                                                {/* Basis Infromation */}
                                                < thead >
                                                    <tr key={i + "pdt"}>
                                                        <th colSpan={(l.secondReportingOfficer.length > 0 && l.secondReviewingOfficer.length > 0) ? 9 : 7} className="text-center">
                                                            <h3 className=""> <b> {`PMS APPRAISAL REPORT OF  ${l.subPerformanceCycle.name}  ${l.yearCycle.yearCycle}`}</b></h3>
                                                        </th>
                                                    </tr>
                                                    <tr key={i + "pdt"}>
                                                        <th colSpan={(l.secondReportingOfficer.length > 0 && l.secondReviewingOfficer.length > 0) ? 9 : 7} className="text-center">Assessment Report </th>
                                                    </tr>
                                                    <tr key={i + "pdt"}>
                                                        <th colSpan={(l.secondReportingOfficer.length > 0 && l.secondReviewingOfficer.length > 0) ? 9 : 7} className="text-center">PART-1 (Personal Data) </th>
                                                    </tr>
                                                    <tr key={i + "pdt"}>
                                                        <th>Appraisal Cycle</th>
                                                        <th>Sub Appraisal Cycle</th>
                                                        <th>Name of the Employee</th>
                                                        <th>Department </th>
                                                        <th>Designation </th>
                                                        <th>Reporting Officer </th>
                                                        <th>Reviewing Officer </th>
                                                        {l.secondReportingOfficer.length > 0 && <th>Second Reporting Officer </th>}
                                                        {l.secondReviewingOfficer.length > 0 && <th>Second Reviewing Officer </th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr key={i + "pdt"}>
                                                        <td>{l.yearCycle.yearCycle}</td>
                                                        <td>{l.subPerformanceCycle.name}</td>
                                                        <td>{l.name} ({l.empCode})</td>
                                                        <td> {l.department}</td>
                                                        <td> {l.designation}</td>
                                                        <td> {l.reportingOfficer.length > 0 ? l.reportingOfficer[0].name : ''}</td>
                                                        <td> {l.reviewingOfficer.length > 0 ? l.reviewingOfficer[0].name : ''}</td>
                                                        {l.secondReportingOfficer.length > 0 && <td> {l.secondReportingOfficer[0].name} </td>}
                                                        {l.secondReviewingOfficer.length > 0 && <td> {l.secondReviewingOfficer[0].name}</td>}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {/* ART-2 (Self Assessment) */}
                                            <br /><br />
                                            <table className="table table-bordered tab">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={4} className="text-center">PART-2 (Self Assessment) </th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={4}>Behavioral Skills </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Weightage</th>
                                                        <th>Marks</th>
                                                        <th>Comments </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        l.goal.behaviour.length > 0 && l.goal.behaviour.map((e: any, bi: any) => {
                                                            return (
                                                                <tr key={bi + 'sbi'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    <td>{e.pfaByEmp.length > 0 && e.pfaByEmp[0].percentage}</td>
                                                                    <td>{e.remarks}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                            <br /><br />
                                            <table className="table table-bordered tab">
                                                <thead>

                                                    <tr>
                                                        <th colSpan={4}>Departmental Skills</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Weightage</th>
                                                        <th>Marks</th>
                                                        <th>Comments </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        l.goal.department.length > 0 && l.goal.department.map((e: any, bi: any) => {
                                                            return (
                                                                <tr key={bi + 'sde'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    <td>{e.pfaByEmp.length > 0 && e.pfaByEmp[0].percentage}</td>
                                                                    <td>{e.remarks}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <br /><br />
                                            <table className="table table-bordered tab">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={5}>Assignment</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Weightage</th>
                                                        <th>Worked</th>
                                                        <th>Marks</th>
                                                        <th>Comments</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        l.goal.activity.length > 0 && l.goal.activity.map((e: any, bi: any) => {
                                                            return (
                                                                <tr key={bi + 'sde'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    <td>{e.check ? 'YES' : 'NO'}</td>
                                                                    <td>{e.pfaByEmp.length > 0 && e.pfaByEmp[0].percentage}</td>
                                                                    <td>{e.remarks}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>




                                            {/* ART-3 (Assessment by Reporting Officer) */}
                                            
                                            {/* <br className='page-break' /><br /> */}
                                            <table className="table table-bordered tab">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={4} className="text-center">PART-3</th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={4} className="text-center">(Assessment by Reporting Officer)</th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={4}>Behavioral Skills </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Weightage</th>
                                                        <th>Marks</th>
                                                        <th>Comments </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                     
                                                    {
                                                        l.goal.behaviour.length > 0 && l.goal.behaviour.map((e: any, bi: any) => {
                                                            {
                                                                console.log("Goal", e);
                                                            }

                                                            return (
                                                                <tr key={bi + 'rpbe'} >
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].percentage}</td> */}
                                                                    <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[0].percentage : e.pfaByMn.length > 0 && e.pfaByMn[0].percentage || e.pfaByRm.length > 0 && e.pfaByRm[0].percentage}</td>
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].remarks}</td> */}
                                                                    <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[0].remarks : e.pfaByMn.length > 0 && e.pfaByMn[0].remarks}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <br /><br />
                                            <table className="table table-bordered tab">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={4}>Departmental Skills</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Weightage</th>
                                                        <th>Marks</th>
                                                        <th>Comments </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        l.goal.department.length > 0 && l.goal.department.map((e: any, bi: any) => {
                                                            return (
                                                                <tr key={bi + 'rpde'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].percentage}</td> */}
                                                                    <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[0].percentage : e.pfaByMn.length > 0 && e.pfaByMn[0].percentage || e.pfaByRm.length > 0 && e.pfaByRm[0].percentage}</td>
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].remarks}</td> */}
                                                                    <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[0].remarks : e.pfaByMn.length > 0 && e.pfaByMn[0].remarks}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <br /><br />
                                            <table className="table table-bordered tab">

                                                <thead>

                                                    <tr>
                                                        <th colSpan={5}>Assignment</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Weightage</th>
                                                        <th>Worked</th>
                                                        <th>Marks</th>
                                                        <th>Comments </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        l.goal.activity.length > 0 && l.goal.activity.map((e: any, bi: any) => {
                                                            return (
                                                                <tr key={bi + 'rpe'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    <td>{e.check ? 'YES' : 'NO'}</td>
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].percentage}</td> */}
                                                                    <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[0].percentage : e.pfaByMn.length > 0 && e.pfaByMn[0].percentage || e.pfaByRm.length > 0 && e.pfaByRm[0].percentage}</td>
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].remarks}</td> */}
                                                                    <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[0].remarks : e.pfaByMn.length > 0 && e.pfaByMn[0].remarks}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>

                                            {/*PART-4 (Assessment by second Reporting Officer) */}
                                            {/* <br className='page-break' /><br /> */}
                                            {l.secondReportingOfficer.length > 0 &&
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4} className="text-center">PART-4</th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={4} className="text-center">(Assessment by Second Reporting Officer)</th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={4}>Behavioral Skills </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Comments </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='rm'>
                                                        {/* { l.goal.behaviour.length > 0 && l.goal.behaviour.map((e: any, bi: any) => { */}
                                                        {l.goal.behaviour.length > 0 && l.goal.behaviour && l.goal.behaviour.map((e: any, bi: any) => {
                                                            return (
                                                                <tr key={bi + 'rpbe'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    {/* <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.percentage : ""}</td> */}
                                                                    <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage : e.pfaByMn.length > 0 && e.pfaByMn[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[0]?.percentage}</td>
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].percentage ? e.pfaByMn.length > 0 && e.pfaByMn[0].percentage : e.pfaByRm.length > 0 && e.pfaByRm[0].percentage}</td> */}
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].remarks}</td> */}
                                                                    <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.remarks : ''}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>}
                                            <br /><br />
                                            {l.secondReportingOfficer.length > 0 &&
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4}>Departmental Skills</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Comments </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            l.goal.department.length > 0 && l.goal.department.map((e: any, bi: any) => {

                                                            return (
                                                                <tr key={bi + 'rpde'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    {/* <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.percentage : ''}</td> */}
                                                                    <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage : e.pfaByMn.length > 0 && e.pfaByMn[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[0]?.percentage}</td>
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].percentage ? e.pfaByMn.length > 0 && e.pfaByMn[0].percentage : e.pfaByRm.length > 0 && e.pfaByRm[0].percentage}</td> */}
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].remarks}</td> */}
                                                                    <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.remarks : ''}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>}
                                            <br /><br />
                                            {l.secondReportingOfficer.length > 0 &&
                                                <table className="table table-bordered tab">

                                                    <thead>

                                                        <tr>
                                                            <th colSpan={5}>Assignment</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Worked</th>
                                                            <th>Marks</th>
                                                            <th>Comments </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* { l.goal.activity.length > 0 && l.goal.activity.map((e: any, bi: any) => { */}
                                                        {l.goal.activity.length > 0 && l.goal.activity && l.goal.activity.map((e: any, bi: any) => {
                                                            return (
                                                                <tr key={bi + 'rpe'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    <td>{e.check ? 'YES' : 'NO'}</td>
                                                                    {/* <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.percentage : ''}</td> */}
                                                                    <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage : e.pfaByMn.length > 0 && e.pfaByMn[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[0]?.percentage}</td>
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].percentage ? e.pfaByMn.length > 0 && e.pfaByMn[0].percentage : e.pfaByRm.length > 0 && e.pfaByRm[0].percentage}</td> */}
                                                                    {/* <td>{e.pfaByMn.length > 0 && e.pfaByMn[0].remarks}</td> */}
                                                                    <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.remarks : ''}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>}

                                            {/*PART-5 (Assessment by Reviewing  Officer) */}
                                            {/* <br className='page-break' /><br /> */}
                                            <table className="table table-bordered tab">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={4} className="text-center">PART-5</th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={4} className="text-center">(Assessment by Reviewing Officer)</th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={4}>Behavioral Skills </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Weightage</th>
                                                        <th>Marks</th>
                                                        <th>Comments </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        l.goal.behaviour.length > 0 && l.goal.behaviour.map((e: any, bi: any) => {

                                                            return (
                                                                <tr key={bi + 'rpbe'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    <td>{e.pfaByRm.length > 0 && e.pfaByRm[0].percentage}</td>
                                                                    {/* <td>{e.pfaByRm.length > 0 && e.pfaByRm[0].percentage ? e.pfaByRm.length > 0 && e.pfaByRm[0].percentage : e.pfaByMn.length > 0 && e.pfaByMn[0].percentage}</td> */}
                                                                    <td>{e.pfaByRm.length > 0 && e.pfaByRm[0].remarks}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <br /><br />
                                            <table className="table table-bordered tab">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={4}>Departmental Skills</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Weightage</th>
                                                        <th>Marks</th>
                                                        <th>Comments </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        l.goal.department.length > 0 && l.goal.department.map((e: any, bi: any) => {

                                                            return (
                                                                <tr key={bi + 'rpde'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    <td>{e.pfaByRm.length > 0 && e.pfaByRm[0].percentage}</td>
                                                                    <td>{e.pfaByRm.length > 0 && e.pfaByRm[0].remarks}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <br /><br />
                                            <table className="table table-bordered tab">

                                                <thead>

                                                    <tr>
                                                        <th colSpan={5}>Assignment</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Weightage</th>
                                                        <th>Worked</th>
                                                        <th>Marks</th>
                                                        <th>Comments </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        l.goal.activity.length > 0 && l.goal.activity.map((e: any, bi: any) => {

                                                            return (
                                                                <tr key={bi + 'rpe'}>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.weightage}</td>
                                                                    <td>{e.check ? 'YES' : 'NO'}</td>
                                                                    <td>{e.pfaByRm.length > 0 && e.pfaByRm[0].percentage}</td>
                                                                    <td>{e.pfaByRm.length > 0 && e.pfaByRm[0].remarks}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {/*PART-6 (Assessment by Second Reviewing  Officer) */}
                                            {/* <br className='page-break' /><br /> */}
                                            {l.secondReviewingOfficer.length > 0 &&
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4} className="text-center">PART-6</th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={4} className="text-center">(Assessment by Second Reviewing Officer)</th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={4}>Behavioral Skills </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Comments </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            l.goal.behaviour.length > 0 && l.goal.behaviour.map((e: any, bi: any) => {
                                                                return (
                                                                    <tr key={bi + 'rpbe'}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        {/* <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.percentage : ''}</td> */}
                                                                        <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage : e.pfaByMn.length > 0 && e.pfaByMn[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[0]?.percentage}</td>
                                                                        {/* <td>{e.pfaByRm.length > 0 && e.pfaByRm[0].percentage ? e.pfaByRm.length > 1 && e.pfaByRm[0].percentage : e.pfaByMn.length > 0 && e.pfaByMn[0].percentage}</td> */}

                                                                        {/* <td>{e.pfaByRm.length > 1 && e.pfaByRm[0].remarks}</td> */}
                                                                        <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.remarks : ''}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>}
                                            {/* <br /><br /> */}
                                            {l.secondReviewingOfficer.length > 0 &&
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4}>Departmental Skills</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Comments </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            l.goal.department.length > 0 && l.goal.department.map((e: any, bi: any) => {
                                                                return (
                                                                    <tr key={bi + 'rpde'}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        {/* <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.percentage : ''}</td> */}
                                                                        <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage : e.pfaByMn.length > 0 && e.pfaByMn[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[0]?.percentage}</td>
                                                                        {/* <td>{e.pfaByRm.length > 0 && e.pfaByRm[0].percentage ? e.pfaByRm.length > 1 && e.pfaByRm[0].percentage : e.pfaByMn.length > 0 && e.pfaByMn[0].percentage}</td> */}
                                                                        {/* <td>{e.pfaByRm.length > 1 && e.pfaByRm[0].remarks}</td> */}
                                                                        <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.remarks : ''}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>}
                                            <br /><br />
                                            {l.secondReviewingOfficer.length > 0 &&
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={5}>Assignment</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Worked</th>
                                                            <th>Marks</th>
                                                            <th>Comments </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            l.goal.activity.length > 0 && l.goal.activity.map((e: any, bi: any) => {
                                                                return (
                                                                    <tr key={bi + 'rpe'}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        <td>{e.check ? 'YES' : 'NO'}</td>
                                                                        {/* <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.percentage : ''}</td> */}
                                                                        <td>{CommonRating && CommonRating.length > 0 ? e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage : e.pfaByMn.length > 0 && e.pfaByMn[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[1]?.percentage || e.pfaByRm.length > 0 && e.pfaByRm[0]?.percentage}</td>
                                                                        {/* <td>{e.pfaByRm.length > 0 && e.pfaByRm[0].percentage ? e.pfaByRm.length > 1 && e.pfaByRm[0].percentage : e.pfaByMn.percentage}</td> */}
                                                                        {/* <td>{e.pfaByRm.length > 1 && e.pfaByRm[0].remarks}</td> */}
                                                                        <td>{e.pfaByMn?.length > 0 ? e.pfaByMn[1]?.remarks : ''}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>}

                                            <br /><br />
                                            <table className="table table-bordered tab">
                                                <thead>

                                                    <tr>
                                                        <th colSpan={4} className="text-center">Part-7</th>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan={4} className="text-center">(Overall Assessment Report)</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Particular</th>
                                                        <th>Weightage</th>
                                                        <th>Marks</th>
                                                        <th>Rating </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Behavioral</td>
                                                        <td>{l.goal.behaviourweightage}</td>
                                                        <td>{l.goal.behaviourMarks}</td>
                                                        <td>{l.goal.behaviourGrade}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Departmental</td>
                                                        <td>{l.goal.behaviourweightage}</td>
                                                        <td>{l.goal.behaviourMarks}</td>
                                                        <td>{l.goal.behaviourGrade}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>KRA</td>
                                                        <td>{l.goal.activityweightage}</td>
                                                        <td>{l.goal.activityMarks}</td>
                                                        <td>{l.goal.activityGrade}</td>
                                                    </tr>
                                                    <tr >
                                                        <td>Overall</td>
                                                        <td>{l.goal.totalWeightage}</td>
                                                        <td>{parseInt(l.goal.totalMarks)}</td>
                                                        <td>{l.goal.totalGrad}</td>
                                                    </tr>

                                                </tbody>

                                            </table>
                                            <h4 className="text-center">Page {` ${i + 1}  / ${data.length}`}</h4>
                                            <br className='page-break' />
                                            {!(i + 1 === data.length) &&
                                                <br className='page-break' />
                                            }
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div >
                {/* </div> */}
            </section >
        );
    }
    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value)
        this.filter(value)
    }

    private filter = (f: any) => {
        // debugger
        const res = this.props.data.length > 0 && this.props.data.filter((item: any) => {
            if (f === 'completed')
                return item.goal.selfAppraisal === true;
            else if (f === 'pending')
                return item.goal.approved === 'pending';
            else if (f === 'reject')
                return item.goal.approved === 'reject';
            else if (f === 'approved') {
                return item.goal.approved === 'approved';
            }
            else if (f === 'dualReporting') {
                return item.secondReportingOfficer.length > 0 && item.secondReviewingOfficer.length > 0
            }
            else return true

        })
        this.setState({ data: res })
    }

    exportPDF = () => {
        const elelmetn: HTMLElement | any = ReactDOM.findDOMNode(this.table)
        savePDF(elelmetn, {
            repeatHeaders: false,
            paperSize: "A4",
            margin: '0cm',
            forcePageBreak: ".page-break",
            date: new Date(),
            fileName: this.props.data.length > 0 ? `${this.props.data[0].subPerformanceCycle.name.replace(/\s+/g, '-').toLowerCase()}-${this.props.data[0].yearCycle.yearCycle.replace(/\s+/g, '-').toLowerCase()}` : `${moment().format('YYYY-MM-DD-hh:mm')}`,
            scale: 0.6
        });
    }
}





{/* <Document> */ }

{/* <Page style={styles.page}>
    <View>
    <Text style={styles.vote_text}>saif</Text>
                    <Text style={styles.vote_text}>ali</Text>
    </View> */}

{/* {data
        ? data.map((a: any, index: number) => {
            return (

                <View style={styles.movieDetails}>
                    <Text style={styles.vote_text}>{a.name}</Text>
                    <Text style={styles.vote_text}>{a.age}</Text>

                </View>
            );
        })
        : ""} */}
{/* </Page>
</Document > */}