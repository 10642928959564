import * as axios from 'axios';
import { getAuthHeader, } from '../../utils';

export const uploadEmployeeByXL: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/employee/xl`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });

}

export const transferEmployee: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/employee`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });

}


export const addEmployee: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/employee`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });

}

export const getEmployee: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/employee?yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status){
                    return response.data;
                }
                else {
                    alert(response.data.error)
                }
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const getGoalData: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/employee?yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status){
                    return response.data;
                }
                else {
                    alert(response.data.error)
                }
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}


export const getEmployeeById = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/employee-byId`;
    return axios.get(url, { ...getAuthHeader(), params: reqObj })
        .then((response: any) => {
            return response.data;
        });
}
