import * as React from 'react';
import { Link } from 'react-router-dom';

import '../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { validateForm, onChange } from '../utils';
import CONSTANT from '../constant'

class Department extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const department = props.department;
        this.state = {
            name: { value: department ? department.name : '', name: 'name', error: '', isRequired: true },
            skills: { value: department ? department.skills : [], name: 'skills', error: '', isRequired: true, options: [], show: true },
            status: { value: department ? department.status : '', name: 'status', error: '', isRequired: true },
            remark: { value: department ? department.remark : '', name: 'remark', error: '', isRequired: true },
            statusList: { value: department ? department.statusList : '', name: 'statusList', error: '', options: [{ code: 1, name: 'Active' }, { code: 2, name: 'Inactive' }], isRequired: true },
            code: { name: 'code', value: department ? department.code : '' }
        }
    }

    public render() {
        const { name, skills, status, statusList, remark } = this.state;
        return (
            <React.Fragment>

                <div className="card mt-2">
                    <div className="card-header">
                        <b>Department Skills</b>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this._onSubmit}>
                            <div className="col-lg-12 mb-2">
                                <div className="row">
                                    <div className="col-lg-4 form-group">
                                        <label>Grade / Level </label>
                                        <input
                                            name={name.name}
                                            onChange={this._onChange}
                                            value={name.value}
                                            type="text"
                                            className={name.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter name/level" />
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Skills </label>

                                        <Typeahead
                                            allowNew={true}
                                            multiple={true}
                                            id="ta-course_name"
                                            placeholder="Select skills"
                                            name={skills.name}
                                            minLength={0}
                                            options={skills.options}
                                            onChange={(e: any) => this._typeaheadOnChange(skills.name, e)}
                                            selected={skills.value}
                                            labelKey="name"
                                            isInvalid={skills.error.length > 0}
                                        />

                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Status</label>
                                        {this.renderStatus(status, statusList)}
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Remark</label>
                                        <input
                                            name={remark.name}
                                            onChange={this._onChange}
                                            value={remark.value}
                                            type="text"
                                            className={remark.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter remark" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 ">
                                <button onClick={this._onSubmit} className="col-lg-2 btn button-full pull-right">Submit</button>
                                <Link to={CONSTANT.url.departmentList} className="col-lg-2 btn btn-secondary mr-3 pull-right">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>

            </React.Fragment>

        )
    }

    private _typeaheadOnChange(name: string, e: any) {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
    }

    private _onChange = (e: any, callback?: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
    }

    private renderStatus = (status: any, statusList: any) => {
        return (<select name={status.name} value={status.value}
            onChange={this._onChange} className={status.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {statusList.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.status}>{e.name}</option>)
                })}
            </React.Fragment>
        </select>)
    }
    private _onSubmit = (e: any) => {
        e.preventDefault();
        const st = this.state;
        const model = {
            name: st.name.value,
            skills: st.skills.value,
            remark: st.remark.value,
            status: st.status.value,
            code: st.code.value,
        };
        this.props.onSubmit(model);

    }

}

export default Department;