import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { validateForm, isValidDate, getCookie, onChange } from '../../../utils';
import { getAppraiseEmployee, getAppraisalCycle, getRepotie, getEmpList } from '../../../action/Master';
import CONSTANT from '../../../constant';


class Appraise extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const { code, empCode } = JSON.parse(window.atob(getCookie('token').split('.')[1]))
        console.log("cookie value is : " , JSON.parse(window.atob(getCookie('token').split('.')[1])))

        this.state = {
            code,
            empCode,
            repotie: [],
            employeeList: undefined,
            yearCycle: { value: '', name: 'yearCycle', options: [], error: '', isRequired: true },
            monthCycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            EmpList: []

        }
    }

    componentDidMount() {
        this.loadList()
    }
    public render() {

        const { employeeList, empCode, code, yearCycle, monthCycle, EmpList } = this.state;
        console.log("this.state >> ", this.state);

        return (
            <React.Fragment>

           {console.log("lsit view this state  "  , this.state )}
    
                 <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-6 col-lg-6 pl-0">
                                                <h5 className="heading-h1">List of Employee</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Appraisal
                                            </b>
                                        </div>
                                        <div className="card-body">
                                            {
                                                <form>
                                                    <div className="col-lg-12 mb-2">
                                                        <div className="row">
                                                            <div className="col-lg-6 form-group">
                                                                <label > Appraisal Cycle  </label>
                                                                {this.renderSelect(yearCycle, false)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Sub Performance </label>
                                                                {this.renderSelect(monthCycle, true)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            }
                                        </div>
                                    </div>
                                    <div className="card mt-3">
                                        <div className="card-header">
                                            <b>Employee
                                            </b>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Employee Name</th>
                                                            <th scope="col">Designation</th>
                                                            <th scope="col">Department</th>
                                                            <th scope="col">Location</th>
                                                            <th scope="col">Created Date</th>
                                                            {/* <th scope="col">Status</th> */}
                                                            <th scope="col">Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            employeeList === undefined && <tr>
                                                                <td className="text-center" colSpan={7}>Loading...</td>
                                                            </tr>
                                                        }
                                                        {
                                                            employeeList !== undefined && employeeList.length === 0 && <tr>
                                                                <td className="text-center" colSpan={7}>No records found</td>
                                                            </tr>
                                                        }
                                                        {
                                                            employeeList !== undefined &&
                                                            employeeList.map((item: any, ind: number) => {
                                                               
                                                                return (
                                                                    <tr key={ind}>

                                                                        <td>{item.name}</td>
                                                                        <td>{item.designation}</td>
                                                                        <td>{item.department}</td>
                                                                        <td>{item.location}</td>
                                                                        <td>{moment.unix(item.goal.createdOn).format('MMM Do YYYY')}</td>
                                                                        {/* <td>
                                                                            {item.status == 'Active' ? <span className="completed">{item.status}</span> : <span className="pending">{item.status}</span>}
                                                                        </td> */}
                                                                        {
                                                                            this.renderList(item)
                                                                        }

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </React.Fragment >

        )
    }

    renderSelect = (select: any, f: boolean) => {
        return (<select name={select.name} value={select.value}
            onChange={this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {select.options.length && select.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.code}>{f ? e.name : e.yearCycle}</option>)
                })}
            </React.Fragment>
        </select>)
    }

    private checkAssMn = (goal: any, userId: any) => {
         
        let res =  [...goal.department, ...goal.behaviour, ...goal.activity].map((e: any) => {
            if (e.pfaByMn.length > 0) {
                const pfaByMn = e.pfaByMn.filter((e: any) => {
                    if (e.userCode == userId || e.userCode != userId)
                        return true
                })
                
                return { ...e, pfaByMn }
            }
            else {
                return { ...e, pfaByRm: [], pfaByMn: [] }
            }
        })
          
        return res
    }

    private checkAssRm = (goal: any, userId: any) => {
        const ass = [...goal.department, ...goal.behaviour, ...goal.activity];
        return ass.map((e: any) => {

            if (e.pfaByRm.length > 0) {
                const pfaByRm = e.pfaByRm.filter((e: any) => {
                    if (e.userCode == userId || e.userCode != userId)                          //if (e.userCode == userId)
                        return true
                })
                return { ...e, pfaByRm }
            }
            else {
                return { ...e, pfaByRm: [], pfaByMn: [] }
            }
        })

    }

    private checkOurRm = (goal: any) => {
        
        const { repotie } = this.state
        const mn = repotie.length && repotie.filter((m: any) => {
            for (let ass of [...goal.department, ...goal.behaviour, ...goal.activity]) {
                for (let Mn of ass.pfaByMn)
                    if (Mn.userCode === m.code)
                        return true
            }
        })
        return mn.length > 0 ? mn[0].code : null
    }

    private renderList = (item: any) => {
            const { empCode, yearCycle, monthCycle, code } = this.state
            const mnCode = this.checkOurRm(item.goal)
           
          if (item.goal.approved == 'approved') {
            
            
            const checkMn = this.checkAssMn(item.goal, code).every((e) => e.pfaByMn.length > 0)
            const checkSMn = this.checkAssMn(item.goal, code).every((e) => e.pfaByMn.length > 1)
            const checkRM = this.checkAssRm(item.goal, code).every((e) => e.pfaByRm.length > 0)
            const checkSRM = this.checkAssRm(item.goal, code).every((e) => e.pfaByRm.length > 1)
            const checkRmRepoty = mnCode ? !this.checkAssMn(item.goal, mnCode).every((e) => e.pfaByMn.length > 0) : true
            
              
            if ((item.reportingOfficer === empCode && item.reportingOfficer != item.reviewingOfficer && (!checkMn)) || (item.secondReportingOfficer === empCode && item.secondReportingOfficer != item.secondReviewingOfficer && (!checkMn) && (!checkSMn))) {
                
                if (item.goal.selfAppraisal)
                    return <td><Link to={`${CONSTANT.url.goalReview}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}`}> Assessment Pending  </Link></td >
                else
                    return <td><a href="javascript:void(0)" onClick={() => alert('Self Appraisal not completed by employee yet.')}> Self-assessment Pending</a></td>
            }

            else if ((item.reportingOfficer === empCode && item.reportingOfficer == item.reviewingOfficer && (!checkMn) && (!checkRM)) || (item.secondReportingOfficer === empCode && item.secondReportingOfficer != item.secondReviewingOfficer && (!checkMn))) {
                 
                if (item.goal.selfAppraisal)
                    return <td><Link to={`${CONSTANT.url.goalReview}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}`}> Assessment Pending </Link></td >
                else
                    return <td><a href="javascript:void(0)" onClick={() => alert('Self Appraisal not completed by employee yet.')}> Self-assessment Pending</a></td>
            }
            // added by Vimal Pandey ---------------------------
            else if ((item.reviewingOfficer === empCode && item.reportingOfficer == item.reviewingOfficer && (!checkMn) && (!checkRM)) || (item.secondReviewingOfficer === empCode && item.secondReportingOfficer == item.secondReviewingOfficer && (!checkMn) && (!checkRM))) {
               
               if (item.goal.selfAppraisal)
                   return < td > <Link to={`${CONSTANT.url.goalReview}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=true`}> Assessment Pending </Link> </td>
               else
                   return <td><a href="javascript:void(0)" onClick={() => alert('Self Appraisal not completed by employee yet.')}>Self-assessment Pending</a></td>
           }
           //------------------------- 

            else if ((item.reviewingOfficer === empCode && item.reportingOfficer == item.reviewingOfficer && (checkMn) && (!checkRM)) || (item.secondReviewingOfficer === empCode && item.secondReportingOfficer == item.secondReviewingOfficer && (!checkMn) && (!checkRM))) {
                 
                if (item.goal.selfAppraisal)
                    return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=true`}> Assessment Pending </Link> </td>
                else
                    return <td><a href="javascript:void(0)" onClick={() => alert('Self Appraisal not completed by employee yet.')}>Self-assessment Pending</a></td>
            }
            // Added by Vimal Pandey----------------
            else if ((item.reviewingOfficer === empCode && (item.reportingOfficer == item.reviewingOfficer) && (checkMn) && (checkRM) || ( ( item.secondReportingOfficer == empCode && item.secondReportingOfficer == item.secondReviewingOfficer) && (checkSRM) && (checkSMn) && (item.secondReportingOfficer != null && item.secondReviewingOfficer != null)))) {
                 
                if (item.goal.selfAppraisal)
                    return <td><a href="javascript:void(0)" onClick={() => alert('Assement Completed.')}>Assessment Completed </a></td>
                else
                    return <td><a href="javascript:void(0)" onClick={() => alert('Self Appraisal not completed by employee yet.')}>Self-assessment Pending</a></td>
            }
            //-----------------------------
            else if ((item.reviewingOfficer === empCode && (item.reportingOfficer == item.reviewingOfficer) && (checkMn) && (checkRM) && (item.goal.selfAppraisal) && (item.secondReportingOfficer == item.secondReviewingOfficer) && (!checkSRM) && (item.secondReportingOfficer != null && item.secondReviewingOfficer != null))) {
                 
                if ((!checkSMn))
                    /***Change By Jitender**** */
                    return < td > <Link to={`${CONSTANT.url.goalReview}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=true`}> Assessment Pending </Link> </td>
                // return < td > <Link to={`${CONSTANT.url.goalReview}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=true`}> Assessment Completed </Link> </td>
                /****End******* */
                else
                    return <td><a href="javascript:void(0)" onClick={() => alert('Assement Completed.')}>Assessment Completed </a></td>
            }

            //  ============================================================================================

            else if (item.reportingOfficer == item.reviewingOfficer && (item.secondReviewingOfficer === empCode && item.secondReportingOfficer == item.secondReviewingOfficer && (checkMn) && (checkRM))) {
                  
                if (!checkSMn && !checkSRM) return < td > <Link to={`${CONSTANT.url.goalReview}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=true`}> Assessment Pending</Link> </td>
                
                 /***Add by jitender**** */
                if (checkSMn && !checkSRM) return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=true`}> Assessment Pending</Link> </td>
                /***End**** */
                /***Chang Pending To Complete** */
                if (!checkSMn && checkSRM) return < td > <Link to={`${CONSTANT.url.goalReview}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=true`}> Assessment Completed </Link> </td>
                /***End** */
                else
                    return <td><a href="javascript:void(0)" onClick={() => alert('Assement Completed .')}>Assessment Completed </a></td>
            }

            // else if (item.reportingOfficer == item.reviewingOfficer && (item.secondReviewingOfficer === empCode && item.secondReportingOfficer == item.secondReviewingOfficer && (checkMn) && (checkRM))) {
            //     if (!checkSMn)
            //         return < td > <a href="javascript:void(0)" onClick={() => alert('Assement Pending .')}>Assessment Pending at Second Reviewing officer</a> </td>
            //     else if (checkSMn && !checkSRM) return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=true`}> Assessment Pending </Link> </td>
            //     else
            //         return <td><a href="javascript:void(0)" onClick={() => alert('Assement Completed .')}>Assessment Completed</a></td>
            // }

            else if ((item.reviewingOfficer !== item.currentReviewingOfficer || item.reportingOfficer !== item.currentReportingOfficer) && (checkMn) && (!checkRM) && (item.reportingOfficer !== empCode && item.reportingOfficer != item.reviewingOfficer) || (item.secondReviewingOfficer === empCode && item.secondReportingOfficer == item.secondReviewingOfficer) && (checkMn) && (!checkRM)) {
                 
                if (item.goal.selfAppraisal)
                    return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=true`}> Assessment Pending </Link> </td>
                else
                    return <td><a href="javascript:void(0)" onClick={() => alert('Assessment Completed.')}>Assessment Completed </a></td>
            }

            else if ((item.reportingOfficer !== item.reviewingOfficer && item.reportingOfficer === item.currentReviewingOfficer && (checkMn) && (!checkRM)) || (item.secondReviewingOfficer === empCode && item.secondReportingOfficer == item.secondReviewingOfficer && (checkMn) && (!checkRM))) {
                 
                if (item.goal.selfAppraisal)
                    return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=true`}> Assessment Pending </Link> </td>
                else
                    return <td><a href="javascript:void(0)" onClick={() => alert('Assessment Completed.')}>Assessment Completed </a></td>
            }

            // ==============================================================================================================================================================================================================

            else if ((item.reviewingOfficer === empCode && item.reportingOfficer != item.reviewingOfficer && (!checkRM)) || (item.secondReviewingOfficer === empCode && item.reportingOfficer != item.reviewingOfficer && (!checkRM))) {
                 
                if (checkRmRepoty && (!checkMn))
                    return <td><a href="javascript:void(0)" onClick={() => alert('Appraisal Assessment not completed by Reporting Manager yet.')}> Reporting Officer Assessment Pending</a></td>
                else
                    if (item.goal.selfAppraisal)
                        // return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=false`}> Assessment Completed</Link> </td>
                        return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=false`}> Assessment Pending</Link> </td>
                    else
                        return <td><a href="javascript:void(0)" onClick={() => alert('Self Appraisal not completed by employee yet.')}> Self-assessment Pending</a></td>
            }


            // /****Add By jitender*** */
            //if (item.goal.selfAppraisal)
            //return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=false`}> Assessment Completed</Link> </td>

            // /****Add By jitender*** */
            // else if ((item.reviewingOfficer === empCode && item.reportingOfficer != item.reviewingOfficer && (!checkRM)) || (item.secondReviewingOfficer === empCode && item.reportingOfficer != item.reviewingOfficer && (!checkRM))) {
            //     if (checkRmRepoty && (!checkRM))
            //         return <td><a href="javascript:void(0)" onClick={() => alert('Appraisal Assessment not completed by Reporting Manager yet.')}> Reporting Officer Assessment Pending</a></td>
            //     else
            //         if (item.goal.selfAppraisal)
            //             return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=false`}> Assessment Pending</Link> </td>
            //         else
            //             return <td><a href="javascript:void(0)" onClick={() => alert('Self Appraisal not completed by employee yet.')}> Self-assessment Pending</a></td>
            // }
            /*****End**** */
            // /****Add By jitender*** */
            // if (item.goal.selfAppraisal)
            //     return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=false`}> Assessment Completed</Link> </td>

            /*****End**** */
            else
                 debugger 
                //return < td > <Link to={`${CONSTANT.url.review}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}&same=false`}> Assessment Completed</Link> </td>
                return <td><a href="javascript:void(0)" onClick={() => { alert('Assessment already done.') }}>Assessment Completed </a></td>

        }
        else {
                 
            if (item.approvedBy && item.approvedBy.some((e: any) => e.empCode === empCode)){
                
                return <td> <Link to={`${CONSTANT.url.empApproved}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}`}> Review Goals </Link></td>
            }
            else {
                  
                if (item.reviewingOfficer == empCode || item.secondReviewingOfficer == empCode)
                    // return <td> <Link to={`${CONSTANT.url.goalReview}?code=${item.code}&yearCycle=${yearCycle.value}&monthCycle=${monthCycle.value}`}> Goal not approved by Reporting Officer yet </Link></td>
                    return <td><a href="javascript:void(0)" onClick={() => alert('Goal not approved by Reporting Officer yet,after approved by the reporting officer you can access ')}> Goal not approved by Reporting Officer yet</a></td>
                else
                    return <td><a href="javascript:void(0)" onClick={() => alert('You have not authority to Approve this employee')}> Review Goals</a></td>
            }
        }

    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value, () => {
            if (name === this.state.yearCycle.name) {
                let mnth = value && this.state.yearCycle.options.filter((res: any) => { return res.code == value; }).map((res: any) => res.performance)
                this.setState({
                    monthCycle: { ...this.state.monthCycle, options: this.sort(mnth[0]) },
                })
            }
            if (name == this.state.monthCycle.name) {
                this.loadAppraisEmp()
            }
        })

    }


    // private loadEmpList = () => {
    //     debugger
    //     getEmpList().then((res: any) => {
    //         if (res && res.result) {
    //             this.setState({
    //                 EmpList: res.result
    //             })
    //         }
    //     });
    // }

    private sort = (arr: any, order?: number) => {
        if (arr.length > 1)
            return arr.sort((a: any, b: any,) => b.createdOn - a.createdOn)
        else
            return arr
    }

    private loadList = () => {
        getRepotie().then((res: any) => {
            if (res && res.result) {
                console.log("getRepotie is : ", res.result)
                this.setState({
                    repotie: res.result
                })
            }
        });
        getAppraisalCycle().then((res: any) => {
            if (res && res.result && res.result.length) {
                console.log("getAppraisal Cycle list is : " , res.result)
                const mnth = res.result[0].performance.length > 0 ? this.sort(res.result[0].performance) : [];
                this.setState({
                    yearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[0].code },
                    monthCycle: { ...this.state.monthCycle, options: mnth, value: mnth.length > 0 ? mnth[0].code : '' },
                }, () => this.loadAppraisEmp());
            }
        });

    }

    loadAppraisEmp = () => {
        const { yearCycle, monthCycle } = this.state
        const model = {
            yearCycle: yearCycle.value,
            monthCycle: monthCycle.value,
        }
        getAppraiseEmployee(model).then((res: any) => {
            console.log("appraisal list is : " , res.result)
            this.setState({
                employeeList: (res && res.result) ? res.result : [],
            });
        })
    }
}



export default Appraise;