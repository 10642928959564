import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { getAppraisalStatus, addAppraisal } from '../../action/admin/Appraisal';
import { validateForm, onChange } from '../../utils';
import CONSTANT from '../../constant'


class Appraisal extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const appraisalDetail = props.appraisalDetail;
        console.log(props);

        this.state = {
            name: { value: appraisalDetail ? appraisalDetail.name : '', name: 'name', error: '', isRequired: true },
            startDate: { value: appraisalDetail ? appraisalDetail.startDate : '', name: 'startDate', error: '', isRequired: true },
            endDate: { value: appraisalDetail ? appraisalDetail.endDate : '', name: 'endDate', error: '', isRequired: true },
            yearCycle: { value: appraisalDetail ? appraisalDetail.yearCycle : '', name: 'yearCycle', error: '', isRequired: true },
            status: { value: appraisalDetail ? appraisalDetail.status : '', name: 'status', error: '', isRequired: true },
            remark: { value: appraisalDetail ? appraisalDetail.remark : '', name: 'remark', error: '', isRequired: true },
            statusList: { name: 'statusList', value: appraisalDetail ? appraisalDetail.statusList : '', error: '', options: [], isRequired: false },
            code: { name: 'code', value: appraisalDetail ? appraisalDetail.code : '' }
        }
    }

    componentDidMount() {
        this.getStatus()
    }

    public render() {
        const { name, startDate, endDate, yearCycle, status, statusList, remark } = this.state;
        return (
            <React.Fragment>

                <div className="card mt-2">
                    <div className="card-header">
                        <b>Appraisal</b>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this._onSubmit}>
                            <div className="col-lg-12 mb-2">
                                <div className="row">
                                    <div className="col-lg-4 form-group">
                                        <label>Name </label>

                                        <input
                                            name={name.name}
                                            onChange={this._onChange}
                                            value={name.value}
                                            type="text"
                                            className={name.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter name"
                                            disabled={this.props.appraisalDetail ? true : false}
                                        />
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Start Date </label>

                                        <input
                                            name={startDate.name}
                                            onChange={this._onChange}
                                            value={startDate.value}
                                            type="date"
                                            className={startDate.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter start date"
                                            disabled={this.props.appraisalDetail ? true : false}
                                        />
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>End Date</label>
                                        <input
                                            name={endDate.name}
                                            onChange={this._onChange}
                                            value={endDate.value}
                                            type="date"
                                            className={endDate.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter end date"
                                            disabled={this.props.appraisalDetail ? true : false}
                                        />

                                    </div>

                                    <div className="col-lg-4 form-group">
                                        <label>Year Cycle</label>
                                        <input
                                            name={yearCycle.name}
                                            onChange={this._onChange}
                                            value={yearCycle.value}
                                            type="text"
                                            className={yearCycle.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter year cycle"
                                            disabled={this.props.appraisalDetail ? true : false}
                                        />
                                    </div>

                                    <div className="col-lg-4 form-group">
                                        <label>Status</label>
                                        {this.renderDepartmentSelect(status, statusList)}
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Remark</label>
                                        <input
                                            name={remark.name}
                                            onChange={this._onChange}
                                            value={remark.value}
                                            type="text"
                                            className={remark.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter remark" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 ">
                                <button
                                    className="col-lg-2 btn button-full pull-right">Submit</button>
                                <Link to={CONSTANT.url.startAppraisal} className="col-lg-2 btn  btn-secondary mr-3 pull-right">Cancel</Link>

                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        if (this.state.endDate.name === name)
            if (Number(moment(value).format('YYYY')) <= Number(moment(this.state.startDate.value).format('YYYY')))
                alert('Appraisal Cycle should be minimum 1 year');
            else
                onChange(this, name, value)
        else
            onChange(this, name, value)
    }

    renderDepartmentSelect = (status: any, statusList: any) => {
        return (<select name={status.name} value={status.value}
            onChange={this._onChange} className={status.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {statusList.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.status}>{e.name}</option>)
                })}
            </React.Fragment>
        </select>)
    }

    getStatus = () => {
        this.setState({ showLoader: true })
        getAppraisalStatus()
            .then((res: any) => {
                if (res) {
                    this.setState({
                        statusList: { ...this.state.statusList, options: res.result },
                        showLoader: false
                    })
                }
            })
            .catch((err: any) => {
                console.log(err);

            })
    }

    private _onSubmit = (e: any) => {
        e.preventDefault();
        const st = this.state;
        if (validateForm(this, st)) {
            const model = {
                name: st.name.value,
                startDate: st.startDate.value,
                endDate: st.endDate.value,
                yearCycle: st.yearCycle.value,
                status: st.status.value,
                remark: st.remark.value,
                code: st.code.value,
            };
            this.props.onSubmit(model);
        }

    }
}

export default Appraisal;