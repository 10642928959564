import * as React from 'react';

import { getBand, updateBandType } from '../../../action/admin/Band';
import { getAppraisal } from '../../../action/admin/Appraisal';
import Band from '../../../component/common/Band';
import Loder from '../../../component/common/Loader'
import { validateForm } from '../../../utils';
import CONSTANT from '../../../constant';

class Editband extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            code: { value: props.match.params.code },
            bandDetail: undefined,
            appraisalList: undefined,
            showLoader: false,
        }
    }

    componentDidMount() {
        this.loadList();
        this.getAllAppraisal();

    }

    public render() {
        const { bandDetail, appraisalList, showLoader } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">Edit Band Cycle</h5>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        bandDetail && appraisalList &&
                                        <Band
                                            onSubmit={this.onSubmit}
                                            bandDetail={bandDetail}
                                            appraisalList={appraisalList}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    showLoader && <Loder />
                }
            </React.Fragment>

        )
    }

    loadList = () => {
        getBand(this.state.code.value)
            .then((res: any) => {
                if (res) {
                    if (res.result) {
                        this.setState({
                            bandDetail: res.result
                        });
                    }

                }
            })
            .catch((err: any) => {
                console.log(err);

            })
    }

    private getAllAppraisal = () => {
        this.setState({ showLoader: true });
        getAppraisal(undefined).then((response: any) => {
            this.setState({
                appraisalList: response.result,
                showLoader: false,
            });
        })
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        updateBandType(model)
            .then((res: any) => {
                alert('Record Update Successfully')
                this.setState({ showLoader: false });
                this.props.history.push(CONSTANT.url.bandList)
            })
            .catch((err: any) => {
                alert(err)
                this.setState({ showLoader: false });

            })
    }


}

export default Editband;