import * as axios from 'axios';
import CONFIG from '../../config';


/**  |||||||||||||||||||||||||||||||||||   ADD  |||||||||||||||||||||||||||||||||||||||||||||||||*/ 

export const addBehaviour: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/behaviour`;
    return axios.post(url, reqObj)
    .then((response: any) => {
        if (response)
            if (response.data.status)
                return response.data;
            else
                alert(response.data.error)
    })
    .catch((response: any) => {
        console.log('error', response);
        alert('NetWork Error')
    });

}

/**  |||||||||||||||||||||||||||||||||||   UPDATE  |||||||||||||||||||||||||||||||||||||||||||||||||*/ 


export const updateBehavioural: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/behaviour`;
    return axios.put(url, reqObj,)
    .then((response: any) => {
        if (response)
            if (response.data.status)
                return response.data;
            else
                alert(response.data.error)
    })
    .catch((response: any) => {
        console.log('error', response);
        alert('NetWork Error')
    });

}

/**  |||||||||||||||||||||||||||||||||||   GET  |||||||||||||||||||||||||||||||||||||||||||||||||*/ 


export const getBehavioural : any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/behaviour?code=${reqObj}`;
    return axios.get(url, reqObj)
    .then((response: any) => {
        if (response)
            if (response.data.status)
                return response.data;
            else
                alert(response.data.error)
    })
    .catch((response: any) => {
        console.log('error', response);
        alert('NetWork Error')
    });

}


