import * as React from 'react';

import { getPerformance, updatePeformanceType, } from '../../../action/admin/Appraisal';
import Performance from '../../../component/common/Performance';
import { getAppraisalCycle } from '../../../action/Master';
import { validateForm } from '../../../utils';
import CONSTANT from '../../../constant';

class EditPerformance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            code: { value: props.match.params.code },
            performanceDetail: undefined,
            appraisalList: undefined

        }
    }

    componentDidMount() {
        this.loadList();
        this.laodAppraisalList();

    }

    public render() {
        const { performanceDetail, appraisalList } = this.state;
        return (
            <React.Fragment>

                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">Edit Sub Performance</h5>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        performanceDetail && appraisalList &&
                                        <Performance
                                            onSubmit={this.onSubmit}
                                            performanceDetail={performanceDetail}
                                            appraisalList={appraisalList}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>

        )
    }


    loadList = () => {
        getPerformance({ code: this.state.code.value })
            .then((res: any) => {
                if (res) {
                    if (res.result) {
                        this.setState({
                            performanceDetail: res.result
                        });
                    }
                }
            })
    }

    private laodAppraisalList = () => {
        this.setState({ showLoader: true })
        getAppraisalCycle().then((response: any) => {
            if (response.result.length > 0)
                this.setState({
                    appraisalList: response.result,
                    showLoader: false
                });
        });
    }

    onSubmit = (model: any) => {
        //  model.leaveTypeId = this.state.leaveTypeId.value
        updatePeformanceType(model)
            .then((res: any) => {
                alert('Record Update Successfully')
                this.props.history.push(CONSTANT.url.performanceList)
            })
            .catch((err: any) => {
                alert(err)

            })
    }

}

export default EditPerformance;