import * as React from 'react';

import Performance from '../../../component/common/Performance';
import { addSubPerformance } from '../../../action/admin/Appraisal';
import { getAppraisalCycle } from '../../../action/Master';
import { validateForm } from '../../../utils';
import CONSTANT from '../../../constant';

class AddPerformance extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            appraisalList: undefined
        }

    }

    componentDidMount() {
        this.laodAppraisalList();
    }

    public render() {

        const { appraisalList } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">Add Sub Performance</h5>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        appraisalList &&
                                        <Performance
                                            onSubmit={this.onSubmit}
                                            appraisalList={appraisalList}
                                        />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        )
    }


    onSubmit = (model: any) => {
        addSubPerformance(model)
            .then((res: any) => {
                alert('Record Update Successfully')
                this.props.history.push(CONSTANT.url.performanceList)
            })
            .catch((err: any) => {
                alert(err)

            })

    }


    private laodAppraisalList = () => {
        this.setState({ showLoader: true })
        getAppraisalCycle().then((response: any) => {
            if (response && response.result && response.result.length > 0)
                if (response.result.length > 0)
                    this.setState({
                        appraisalList: response.result,
                        showLoader: false
                    });
        });
    }

}

export default AddPerformance;