import * as React from 'react';
import { Link } from 'react-router-dom';

import { getPerformance, deletePeformanceType, associateEmployeeS } from '../../../action/admin/Appraisal';
import { getAppraisalCycle } from '../../../action/Master';
import CONSTANT from '../../../constant';
import { onChange } from '../../../utils';

class PerformanceList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            yearCycle: { name: 'yearCycle', value: '', options: [], error: '' },
            performanceList: undefined,
        }
    }

    componentDidMount() {
        this.laodAppraisalList();
    }

    public render() {
        const { performanceList, yearCycle } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">

                                <div className="col-lg-12">

                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-8 col-lg-8 pl-0">
                                                <h5 className="heading-h1">Start Sub Performance</h5>
                                            </div>

                                            <div className="col-2 col-lg-2 text-right pr-0">
                                                {this.renderSelect(yearCycle)}
                                            </div>

                                            <div className="col-2 col-lg-2 text-right pr-0">
                                                <Link to={CONSTANT.url.addPerformance} className="action-btn">
                                                    <i
                                                        className="fa fa-check-square-o"></i>&nbsp; Add Sub Performance
                                                 </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Sub Performance List</b>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Sub Performance Name</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Year Cycle</th>
                                                            <th scope="col">Remark</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Edit</th>
                                                            <th scope="col">Associate Employee</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            performanceList === undefined && <tr>
                                                                <td className="text-center" colSpan={8}>Loading...</td>
                                                            </tr>
                                                        }
                                                        {
                                                            performanceList !== undefined && performanceList.length === 0 && <tr>
                                                                <td className="text-center" colSpan={8}>No records found</td>
                                                            </tr>
                                                        }
                                                        {
                                                            performanceList !== undefined &&
                                                            performanceList.map((item: any, ind: number) => {
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.startDate}</td>
                                                                        <td>{item.endDate}</td>
                                                                        <td>{item.yearCycle}</td>
                                                                        <td>{item.remark}</td>
                                                                        <td>{item.status}</td>

                                                                        <th scope="col">

                                                                            <Link to={CONSTANT.url.editPerformance.replace(':code', item.code)}>
                                                                                 <i className="fa add-plus fa-pencil"></i> 
                                                                            </Link>
                                                                            &nbsp;&nbsp;&nbsp;
                                                                            <i className="fa fa-close add-plus" onClick={() => this.onDelete(item.code)}></i>
                                                                        </th>
                                                                        <th>
                                                                            <a href="javascript:void(0)" onClick={() => { this.associateEmp(item.code) }}>Click</a>
                                                                        </th>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>

        )
    }

    private onDelete = (code: String) => {
        deletePeformanceType(code).then((response: any) => {
            if (response)
                this.laodAppraisalList();
        });

    }
    renderSelect = (select: any, ) => {
        return (<select name={select.name} value={select.value}
            onChange={this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                {select.options.length > 0 && select.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.code}>{e.yearCycle}</option>)
                })}
            </React.Fragment>
        </select>)
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(
            this, name, value,
            () => {
                if (name == this.state.yearCycle.name)
                    this.getAllPerformance();
            })

    }

    private associateEmp = (e: any) => {

        associateEmployeeS({ code: e }).then((response: any) => {
            if (response)
                alert('All Employee have been associated with cycle.')
        });

    }

    private laodAppraisalList = () => {
        this.setState({ showLoader: true })
        getAppraisalCycle().then((response: any) => {
            if (response && response.result && response.result.length)
                this.setState({
                    yearCycle: { ...this.state.yearCycle, options: response.result, value: response.result.length > 0 ? response.result[0].code : '' },
                },
                    () => this.getAllPerformance()
                );
        });
    }
    private getAllPerformance = () => {
        getPerformance({ yearCycle: this.state.yearCycle.value }).then((response: any) => {
            if (response && response.result)
                this.setState({
                    performanceList: response.result,
                    showLoader: false
                });
        });
    }


}

export default PerformanceList;