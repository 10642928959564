import moment from 'moment'
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from "xlsx";

import { getDepartment } from '../../../action/admin/Department';
import CONSTANT from '../../../constant';

class DepartmentList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            departmentList: undefined
        }
    }

    componentDidMount() {
        this.getAllDepartment();
    }

    public render() {
        const { departmentList } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">

                                <div className="col-lg-12">

                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-6 col-lg-6 pl-0">
                                                <h5 className="heading-h1">Department Skills</h5>
                                            </div>

                                            <div className="col-6 col-lg-6 text-right pr-0">
                                                <a className="action-btn" style={{ marginRight: 2 }} onClick={this.downloadXL}>Export Excel</a>
                                                <Link to={CONSTANT.url.addDepartment} className="action-btn">
                                                    <i className="fa fa-check-square-o"></i>&nbsp; Add Department Skills</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Group List</b>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"> Department Name</th>
                                                            <th scope="col">Skills</th>
                                                            <th scope="col">Created Date</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Remark</th>
                                                            <th scope="col">Edit</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            departmentList === undefined && <tr>
                                                                <td className="text-center" colSpan={7}>Loading...</td>
                                                            </tr>
                                                        }
                                                        {
                                                            departmentList !== undefined && departmentList.length === 0 && <tr>
                                                                <td className="text-center" colSpan={7}>No records found</td>
                                                            </tr>
                                                        }
                                                        {
                                                            departmentList !== undefined &&
                                                            departmentList.map((item: any, ind: number) => {
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td title={`${item.skills.map((res: any) => res.name).join(',   ')}`}>
                                                                            {
                                                                                `${item.skills.map((res: any) => res.name).join(',  ').substr(0, 50)}...`
                                                                            }
                                                                        </td>
                                                                        <td>{moment.unix(item.createdOn).format('MMM Do YYYY')}</td>
                                                                        <td>{item.status}</td>
                                                                        <td>{item.remark}</td>

                                                                        <th scope="col">

                                                                            <Link to={CONSTANT.url.editDepartment.replace(':code', item.code)}> <i className="fa fa-pencil"></i> </Link>

                                                                        </th>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        )
    }

    private getAllDepartment = () => {
        getDepartment(undefined).then((response: any) => {
            this.setState({
                departmentList: response.result,
                showLoader: false
            });
        }, (error: any) => {
            alert(error.Message);
            this.setState({ showLoader: false });
        });
    }
    private downloadXL = (e: any) => {
        e.preventDefault()

        let date = new Date();
        let filename = `department-${date.getTime()}.xlsx`;
        let ws_name = "Sheet1";


        let dataToExport = this.state.departmentList.length > 0 ? this.state.departmentList.map((item: any, index: any) => {
            return {
                'Department Name ': item.name,
                'Skils': item.skills.map((res: any) => res.name).join(',   '),

            }

        })
            : []
        var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(dataToExport);
        // add worksheet to workbook /
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        // write workbook /
        XLSX.writeFile(wb, filename);

    }
}

export default DepartmentList;