import * as axios from 'axios';
import { getAuthHeader } from "../../utils";

/**  |||||||||||||||||||||||||||||||||||   ADD  |||||||||||||||||||||||||||||||||||||||||||||||||*/

export const addGoal: any = (reqObj: any) => {
    debugger
    const url = `${process.env.REACT_APP_BASE_URL}/v1/goal`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

/**  |||||||||||||||||||||||||||||||||||   UPDATE  |||||||||||||||||||||||||||||||||||||||||||||||||*/


export const updateGoal: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/goal`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const updateGoalMarks: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/goal/marks`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
    return true
}

export const approvedGoal: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/goal/approved`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

/**  |||||||||||||||||||||||||||||||||||   GET  |||||||||||||||||||||||||||||||||||||||||||||||||*/


export const getGoal: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/goal?code=${reqObj}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const getGoalAdmin: any = (reqObj: any) => {

    const url = `${process.env.REACT_APP_BASE_URL}/v1/goalData?empCode=${reqObj.empCode}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const deleteData: any = (item: string) => {
    // const url = `${process.env.REACT_APP_BASE_URL}/v1/deleteData?code=${item}`;
    // return axios.delete(url, getAuthHeader())
    //     .then((response: any) => {
    //         if (response)
    //             if (response.data.status)
    //                 return response.data;
    //             else
    //                 alert(response.data.error)
    //     })
    //     .catch((response: any) => {
    //         console.log('error', response);
    //     });
}

export const deleteGoalAdminData: any = (item: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/deleteData?code=${item}`;
    return axios.delete(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}
export const getGoalReview: any = (reqObj?: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/goal/review?userCode=${reqObj.code}&yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });

}
