import * as React from 'react';

import { updateGoal } from '../../../action/employee/Goal';
import CONSTANT from '../../../constant';
import Add from '../../../component/Goal'
class Edit extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            code: props.match.params.code,
        }
    }


    public render() {

        return (
            <React.Fragment>
                <Add
                    code={this.state.code}
                    onSubmit={this.onSubmit}
                />
            </React.Fragment>

        )
    }

    private onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        updateGoal(model).then((response: any) => {
            this.setState({ showLoader: false });
            if (response) {
                this.props.history.push(CONSTANT.url.empSetGoal);
            }
        });
    }
}

export default Edit;