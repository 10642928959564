import * as React from 'react';

import { addGoal } from '../../../action/employee/Goal';
import CONSTANT from '../../../constant';
import Add from '../../../component/Goal'

class AddGoal extends React.Component<any, any> {




    public render() {
        return (
            <React.Fragment>
                <Add 
                 
                    onSubmit={this.onSubmit} 
                
                 />
            </React.Fragment>

        )
    }


    private onSubmit = (model: any) => {
        debugger
        this.setState({ showLoader: true })
        addGoal(model).then((response: any) => {
            this.setState({ showLoader: false });
            if (response) {
                this.props.history.push(CONSTANT.url.empSetGoal);
            }
        });
    }
}

export default AddGoal;
