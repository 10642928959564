import moment from "moment";
import * as React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import menu_icon1 from "../../../assets/images/home-icon1.png";
import { validateForm, isValidDate, getCookie } from "../../../utils";
import { getGoal, deleteData } from "../../../action/employee/Goal";
import {
  getAppraisalCycle,
  getReportingManagerDetailsOfEmp,
} from "../../../action/Master";
import CONSTANT from "../../../constant";
import { rejects } from "assert";

class SetGoal extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      goalList: undefined,
      yearCycle: undefined,
      monthCycle: undefined,
      appraisal_cycle: undefined,
      quaterId: undefined,
      managersData: undefined,
    };
  }

  componentDidMount() {
    this.getAllPerformance();
    const { designation, department } = JSON.parse(
      window.atob(getCookie("token").split(".")[1])
    );
    this.loadList(designation, department);
  }

  loadList = (designation: string, department: string) => {
    getAppraisalCycle().then((res: any) => {
      if (res && res.result && res.result.length)
        this.setState({
          appraisal_cycle: res.result,
        });
    });
  };

  handleAppraisalCycle = (e: any) => {
    this.setState({
      yearCycle: e.target.value,
    });
    const { designation, department } = JSON.parse(
      window.atob(getCookie("token").split(".")[1])
    );
    this.loadList(designation, department);

    let quaterLists = this.state.appraisal_cycle.filter(
      (data: any, index: any) => {
        return data.code === e.target.value ? data : "";
      }
    );

    this.setState({
      monthCycle: quaterLists[0].performance,
    });
  };

  handleSubPerformance = (e: any) => {
    this.setState({
      quaterId: e.target.value,
    });
  };

  getReportingManagerDetails = () => {
    let data = {
      yearCycle: this.state.yearCycle,
      monthCycle: this.state.quaterId,
    };
    getReportingManagerDetailsOfEmp(data).then((res: any) => {
      if (res && res.result && res.result.length)
        this.setState({
          managersData: res.result,
        });
    });
  };

  public render() {
    const { goalList } = this.state;
    return (
      <React.Fragment>
        <section>
          <div className="col-lg-12 main-container">
            <div className="fluid-container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="col-lg-12 mt-4">
                    <div className="row">
                      <div className="col-6 col-lg-6 pl-0">
                        {/* <h5 className="heading-h1">Start Appraisal Cycle</h5> */}
                      </div>

                      <div className="col-6 col-lg-6 text-right pr-0">
                        <Link
                          to={CONSTANT.url.empAddGoal}
                          className="action-btn"
                        >
                          <i className="fa fa-check-square-o"></i>&nbsp; Add
                          Goal
                        </Link>
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col className="col-md-12 mt-2">
                      <div className="card">
                        <div className="card-header">
                          View Assigned Officers
                        </div>
                        <div className="card-body">
                          <Row>
                            <Col className="col-md-6">
                              <div className="form-group">
                                <label>Select appraisal cycle</label>
                                <select
                                  className="form-control"
                                  onChange={(e) => this.handleAppraisalCycle(e)}
                                >
                                  <option>Please select appraisal cycle</option>
                                  {this.state.appraisal_cycle !== undefined &&
                                    this.state.appraisal_cycle.map(
                                      (data: any, index: any) => {
                                        return (
                                          <option value={data.code}>
                                            {data.yearCycle}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </Col>
                            <Col className="col-md-6">
                              <div className="form-group">
                                <label>Select sub performance</label>
                                <select
                                  className="form-control"
                                  onChange={(e) => this.handleSubPerformance(e)}
                                >
                                  <option>
                                    {" "}
                                    Please select sub performance
                                  </option>
                                  {this.state.monthCycle !== undefined &&
                                    this.state.monthCycle.map(
                                      (data: any, index: any) => {
                                        return (
                                          <option value={data.code}>
                                            {data.name}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </Col>
                          </Row>
                          <Col className="col-md-12">
                            <Button
                              className=" pull-right action-btn"
                              onClick={this.getReportingManagerDetails}
                            >
                              Submit
                            </Button>
                          </Col>
                        </div>
                        {this.state.managersData !== undefined &&
                          this.state.managersData.map(
                            (data: any, index: any) => {
                              return (
                                <div className="pl-3">
                                  <Row>
                                    <Col className="col-md-6">
                                      <div>
                                        <p>
                                          <b>Employee Designation</b>:
                                          {data.designation
                                            ? data.designation
                                            : ""}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col className="col-md-6">
                                      <div>
                                        <p>
                                          <b>Employee Department</b>:{" "}
                                          {data.department
                                            ? data.department
                                            : ""}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="col-md-6">
                                      <div>
                                        <p>
                                          <b>Reporting Officer</b>:{" "}
                                          {data.reportingOfficer
                                            ? data.reportingOfficer
                                            : "Not assigned"}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col className="col-md-6">
                                      <div>
                                        <p>
                                          <b>Reviewing Officer</b>:{" "}
                                          {data.reviewingOfficer
                                            ? data.reviewingOfficer
                                            : "Not assigned"}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="col-md-6">
                                      <div>
                                        <p>
                                          <b>Second Reviewing Officer</b>:{" "}
                                          {data.secondReportingOfficer
                                            ? data.secondReportingOfficer
                                            : "Not assigned"}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col className="col-md-6">
                                      <div>
                                        <p>
                                          <b>Second Reviewing Officer</b>:{" "}
                                          {data.secondReviewingOfficer
                                            ? data.secondReportingOfficer
                                            : "Not assigned"}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="col-md-6">
                                      <div>
                                        <p>
                                          <b>Approving Officer</b>:{" "}
                                          {data.approvedBy.map(
                                            (d: any, i: any) => {
                                              return (
                                                <span>
                                                  {d.empCode
                                                    ? d.empCode
                                                    : "Not assigned"}
                                                </span>
                                              );
                                            }
                                          )}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </Col>
                  </Row>
                  <div className="card mt-3">
                    <div className="card-header">
                      <b>Goal List</b>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Activity Name</th>
                              {/* <th scope="col">Comments</th> */}
                              <th scope="col">Department</th>
                              <th scope="col">Behaviour</th>
                              <th scope="col"> Year Cycle</th>
                              <th scope="col">Month Cycle</th>
                              <th scope="col">Created Date</th>
                              <th scope="col">Weightage</th>
                              <th scope="col">Self Appraisal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {goalList === undefined && (
                              <tr>
                                <td className="text-center" colSpan={8}>
                                  Loading...
                                </td>
                              </tr>
                            )}
                            {goalList !== undefined && goalList.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={8}>
                                  No records found
                                </td>
                              </tr>
                            )}
                            {goalList !== undefined &&
                              goalList.map((item: any, ind: number) => {
                                return (
                                  <tr>
                                    <td
                                      title={`${item.activity
                                        .map((res: any) => res.name)
                                        .join(",   ")}`}
                                    >
                                      {item.activity
                                        .map((res: any) => res.name)
                                        .join(",  ")
                                        .substr(0, 40)}
                                      {item.activity.length > 8 ? "..." : ""}
                                    </td>
                                    {/* <td>{item.comments}</td> */}
                                    <td
                                      title={`${item.department
                                        .map((res: any) => res.name)
                                        .join(",   ")}`}
                                    >
                                      {item.department
                                        .map((res: any) => res.name)
                                        .join(",  ")
                                        .substr(0, 40)}
                                      {item.department.length > 8 ? "..." : ""}
                                    </td>
                                    <td
                                      title={`${item.behaviour
                                        .map((res: any) => res.name)
                                        .join(",   ")}`}
                                    >
                                      {item.behaviour
                                        .map((res: any) => res.name)
                                        .join(",  ")
                                        .substr(0, 40)}
                                      {item.behaviour.length > 8 ? "..." : ""}
                                    </td>
                                    <td>
                                      {item.yearCycle}
                                    </td>
                                    <td>
                                      {item.monthCycle}
                                    </td>
                                    <td>
                                      {moment
                                        .unix(item.createdOn)
                                        .format("MMM Do YYYY")}
                                    </td>
                                    <td
                                      title={`${item.department
                                        .map((res: any) => res.weightage)
                                        .join(", ")}, ${item.behaviour
                                          .map((res: any) => res.weightage)
                                          .join(", ")}, ${item.activity
                                            .map((res: any) => res.weightage)
                                            .join(", ")}`}
                                    >
                                      {item.department
                                        .map((res: any) => res.weightage)
                                        .join(", ")
                                        .substr(0, 8)}
                                      {item.activity.length > 8 ? "..." : ""},
                                      {item.behaviour
                                        .map((res: any) => res.weightage)
                                        .join(", ")
                                        .substr(0, 8)}
                                      {item.activity.length > 8 ? "..." : ""},
                                      {item.activity
                                        .map((res: any) => res.weightage)
                                        .join(", ")
                                        .substr(0, 8)}
                                      {item.activity.length > 8 ? "..." : ""}
                                    </td>
                                    {item.approved === "approved" && (
                                      <th scope="col">
                                        <Link
                                          to={CONSTANT.url.empEditGoal.replace(
                                            ":code",
                                            item.code
                                          )}
                                        >
                                          <img
                                            className="img-list"
                                            src={menu_icon1}
                                          />
                                        </Link>
                                        &nbsp;
                                        {/* <button onClick={() => this.deleteValue(item.code)} className="btn btn-sm btn-danger"><i className='fa fa-trash'></i></button> */}
                                      </th>
                                    )}
                                    {(item.approved == "reject" ||
                                      item.approved == "pending") && (
                                        <td title={`${item.comments}`}>
                                          {item.comments.length
                                            ? `${item.comments.substr(0, 15)} ${item.comments.length > 8
                                              ? "..."
                                              : ""
                                            }`
                                            : "Approval Pending."}
                                        </td>
                                      )}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }

  private getAllPerformance = () => {
    getGoal(undefined).then(
      (response: any) => {
        this.setState({
          goalList: response.result,
          showLoader: false,
        });
      },
      (error: any) => {
        alert(error.Message);
        this.setState({ showLoader: false });
      }
    );
  };

  // private deleteValue = (item:any) => {
  //     debugger
  //     deleteData(item).then((response: any) => {
  //         // this.setState({
  //         //     // goalList: response.result,
  //         //     showLoader: false
  //         // });
  //         console.log(response);

  //     }, (error: any) => {
  //         alert(error.Message);
  //         this.setState({ showLoader: false });
  //     });
  // }
}

export default SetGoal;
