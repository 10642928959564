import * as React from 'react';
import { Link } from 'react-router-dom';

import { getEmployeeAndAppraisal } from '../../../action/Master';
import { isValidDate, getCookie, validateForm } from '../../../utils';
import CONSTANT from '../../../constant';

class AppraislEmployee extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            trasnfer: false,
            appraisalList: undefined,
        }
    }

    componentDidMount() {
        this.loadList();
    }

    public render() {

        const { appraisalList, trasnfer } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">

                                <div className="col-lg-12">

                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">Appraisal Cycle(s)</h5>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="card mt-3">
                                        <div className="card-header">
                                            <b>Employee
                                           </b>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Year Cycle</th>
                                                            <th scope="col">Feedback</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            appraisalList === undefined && <tr>
                                                                <td className="text-center" colSpan={6}>Loading...</td>
                                                            </tr>
                                                        }
                                                        {
                                                            appraisalList !== undefined && appraisalList.length === 0 && <tr>
                                                                <td className="text-center" colSpan={6}>No records found</td>
                                                            </tr>
                                                        }
                                                        {
                                                            appraisalList !== undefined &&
                                                            appraisalList.filter((item: any, ind: number) => (item.userName == getCookie('userName')))
                                                                .map((item: any, ind: number) => {
                                                                    return (
                                                                        <tr>
                                                                            {/* <td>{item.employeeCode}</td> */}
                                                                            <td>{item.name}</td>
                                                                            <td>{item.startDate}</td>
                                                                            <td>{item.endDate}</td>
                                                                            <td>{item.yearCycle}</td>
                                                                            <td>{item.remark}</td>
                                                                            <td> <span className={item.status == 'Completed' ? "completed" : "pending"}>{item.status}</span></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </React.Fragment>

        )
    }

    transferEmp = () => {
        this.setState({ trasnfer: !this.state.trasnfer })
    }


    private loadList = () => {
        getEmployeeAndAppraisal(undefined).then((res: any) => {
            if (res && res.result)
                this.setState({
                    appraisalList: res.result,
                    showLoader: false

                });
        })
    }

}

export default AppraislEmployee;