import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';

import menu_icon1 from '../../assets/images/home-icon1.png';
// import { validateForm, isValidDate } from '../../../utils';
import { getGoalAdmin,deleteGoalAdminData } from '../../action/employee/Goal';
import CONSTANT from "../../constant";
import { rejects } from 'assert';
import { isEmpty, onChange, setCookie } from './../../utils';
import { get } from 'jquery';


class ViewGoal extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            goalList: undefined,
            empCode: { name: 'empCode', value: '', isRequired: true, error: '' },
        }


    }

    componentDidMount() {
        // this.getAllPerformance();
    }

    public render() {

        const { goalList,empCode } = this.state;
        
        return (
            <React.Fragment>


                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-6 col-lg-6 pl-0">
                                                {/* <h5 className="heading-h1">Start Appraisal Cycle</h5> */}
                                            </div>
                                    </div>
                                    <div className="card mt-3">
                                        <div className="card-header">
                                        <div className="col-lg-6 pull-right">
                                        {/* {  goalList !== undefined &&
            goalList.map((item: any, ind: number) => (
                <button onClick={() => this.getAllPerformance(item.code)} className="btn btn-sm btn-danger"><i className='fa fa-trash'></i></button>

            ))
            } */}
                                            <button onClick={this._onSubmit} className="col-lg-6 btn pull-right action-btn">Submit</button>

                                                   {"  "} <input
                                                        className="col-lg-6 form-control pull-right"
                                                        placeholder='Search By Employee Id or Name'
                                                        name={empCode.name}
                                                        value={empCode.value}
                                                        onChange={this._onChange}
                                                    />

                                                </div>
                                            <b>Goal List
                                </b>

                                         </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Activity Name</th>
                                                            <th scope="col">Department</th>
                                                            <th scope="col">Behaviour</th>
                                                            <th scope="col">yearCycle</th>
                                                            <th scope="col">quarter</th>
                                                            <th scope="col">Weightage</th>
                                                            <th scope="col">Self Appraisal</th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            goalList === undefined && <tr>
                                                                <td className="text-center" colSpan={7}>Loading...</td>
                                                            </tr>
                                                        }
                                                        {
                                                            goalList !== undefined && goalList.length === 0 && <tr>
                                                                <td className="text-center" colSpan={7}>No records found</td>
                                                            </tr>
                                                        }
                                                        {
                                                            goalList !== undefined &&
                                                            goalList.map((item: any, ind: number) => {
                                                                console.log(item);

                                                                return (
                                                                    <tr>
                                                                        <td title={`${item.activity.map((res: any) => res.name).join(',   ')}`}>
                                                                            {item.activity.map((res: any) => res.name).join(',  ').substr(0, 40)}{item.activity.length > 8 ? '...' : ''}
                                                                        </td>
                                                                        {/* <td>{item.comments}</td> */}
                                                                        <td title={`${item.department.map((res: any) => res.name).join(',   ')}`}>
                                                                            {item.department.map((res: any) => res.name).join(',  ').substr(0, 40)}{item.department.length > 8 ? '...' : ''}
                                                                        </td>
                                                                        <td title={`${item.behaviour.map((res: any) => res.name).join(',   ')}`}>
                                                                            {item.behaviour.map((res: any) => res.name).join(',  ').substr(0, 40)}{item.behaviour.length > 8 ? '...' : ''}
                                                                        </td>
                                                                        <td>{(item.yearCycle)}</td>
                                                                        <td>{(item.monthCycle)}</td>

                                                                        <td title={`${item.department.map((res: any) => res.weightage).join(', ')}, ${item.behaviour.map((res: any) => res.weightage).join(', ')}, ${item.activity.map((res: any) => res.weightage).join(', ')}`}>
                                                                            {item.department.map((res: any) => res.weightage).join(', ').substr(0, 8)}{item.activity.length > 8 ? '...' : ''},
                                                                            {item.behaviour.map((res: any) => res.weightage).join(', ').substr(0, 8)}{item.activity.length > 8 ? '...' : ''},
                                                                            {item.activity.map((res: any) => res.weightage).join(', ').substr(0, 8)}{item.activity.length > 8 ? '...' : ''}
                                                                        </td>
                                                                        {item.approved === 'approved' && <th scope="col">
                                                                            <Link to={CONSTANT.url.empEditGoal.replace(':code', item.code)}>
                                                                                <img className="img-list" src={menu_icon1} />
                                                                            </Link>&nbsp;
                                                                            <button onClick={() => this.deleteAdminValue(item.code)} className="btn btn-sm btn-danger pull-right"><i className='fa fa-trash'></i></button>
                                                                        </th>}
                                                                        {(item.approved == "reject" || item.approved == "pending") && <td title={`${item.comments}`}>
                                                                            {item.comments.length ? (`${item.comments.substr(0, 9)} ${item.comments.length > 8 ? '...' : ''}`) :`Pending`}
                                                                            <button onClick={() => this.deleteAdminValue(item.code)} className="btn btn-sm btn-danger pull-right"><i className='fa fa-trash'></i></button>

                                                                        </td>}

                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>



            </React.Fragment>

        )
    }
    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value)
    }


    private _onSubmit = (e: any) => {
        e.preventDefault();

        const st = this.state;
        const model = {
            empCode: st.empCode.value
        };
      
    this.setState({ showLoader: true })
    getGoalAdmin(model).then((response: any) => {
            this.setState({
                goalList: response.result,
                showLoader: false
            });
        }, (error: any) => {
            alert(error.Message);
            this.setState({ showLoader: false });
        });
    }

    private deleteAdminValue = (item:any) => {
        deleteGoalAdminData(item).then((response: any) => {
            window.location.reload()
            
        }, (error: any) => {
            alert(error.Message);
            this.setState({ showLoader: false });
        });
    }


}

export default ViewGoal;