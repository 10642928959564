import React from 'react';
import moment from 'moment';
import Loader from '../../../component/common/Loader';
import { Link } from 'react-router-dom';
import { onChange } from '../../../utils';
//import { getEmployee } from '../../../action/admin/Employee';
import { getAppraisalCycle, getPendingReportByAppraisalCycle } from '../../../action/Master';


class PendingAssesementEmployee extends React.Component<any, any>{
    constructor(props: any) {
        super(props);

        this.state = {
            employeeList: undefined,
            search: { name: 'search', value: '', },
            yearCycle: { value: '', name: 'yearCycle', options: [], error: '', isRequired: true },
            monthCycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            showLoader: false,
            goalList: undefined,
            acList: [],

        }


    }
    componentDidMount() {
        this.laodAppraisalList();
        //console.log("listload", this.loadEmployee);
        // this.loadEmployeeList();
    }

    public render() {

        const { search, yearCycle, monthCycle, employeeList, Loader, acList } = this.state;
        let filterEmp: any = [];
        //console.log("List", acList);

        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">List of Pending Assesement Employee</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Appraisal
                                            </b>
                                        </div>
                                        <div className="card-body">

                                            {
                                                <form>
                                                    <div className="col-lg-12 mb-2">
                                                        <div className="row">
                                                            <div className="col-lg-6 form-group">
                                                                <label > Appraisal Cycle  </label>
                                                                {this.renderSelect(yearCycle, false)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Sub Performance </label>
                                                                {this.renderSelect(monthCycle, true)}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a href="" className="col-lg-2 pull-right">
                                                                <button onClick={this.loadEmployee} className="btn button-full action-btn">Filter</button>

                                                            </a>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6">
                                                        {"  "} <input
                                                            className="col-lg-6 form-control pull-right"
                                                            placeholder='Search By Employee Id or Name'
                                                            name={search.name}
                                                            value={search.value}
                                                            onChange={this._onChange}
                                                        />

                                                    </div> */}
                                                </form>
                                            }
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-2">
                        <div className="card-header"><b>Pending Status</b></div>
                        <div className="card-body">
                            <div className="list-container">

                                <table className="table table-bordered table-hover">
                                    <tbody>
                                        <tr>
                                            <th scope="col">Emp ID</th>
                                            <th scope="col">Quarter</th>
                                            <th scope="col">Goal Setting</th>
                                            <th scope="col">Goal Approval</th>
                                            <th scope="col">Self Appraisal</th>
                                            <th scope="col">Appraisal by Reporting Officer</th>
                                            <th scope="col">Appraisal by ReViewing Officer</th>
                                            <th scope="col">Second Reporting Officer </th>
                                            <th scope="col">Second Reviewing Officer </th>
                                            <th scope="col">Status </th>
                                        </tr>
                                        {
                                            acList === undefined || acList.length === undefined && <tr>
                                                <td colSpan={10} className="text-center">No records found</td>
                                            </tr>
                                        }
                                        {
                                            acList !== undefined && acList.length === 0 && <tr>
                                                <td colSpan={10} className="text-center">No records found</td>
                                            </tr>
                                        }
                                        {
                                            acList !== undefined && acList.map((item: any, index: number) => {
                                                console.log("item", acList);

                                                /***Add By Jitender**** */
                                                const finalApproved = item.appraisalByReportingOfficer && item.appraisalByReviewingOfficer ? 'Approved' : 'Pending'
                                                /****End**** */

                                                return (
                                                    <tr>
                                                        <th>{item.empCode}</th>
                                                        <th>{item.quarter[0]}</th>
                                                        <th>{item.noOfGoals == "0" ? 0 : item.noOfGoals}</th>
                                                        <th>{item.approved != "approved" ? item.approved : "Completed"}</th>
                                                        <th>{item.selfAppraisal == "0" ? 'Pending' : 'Completed'}</th>
                                                        {/* <th>{item.approved == "pending" ? (item.appraisalByReportingOfficer == "0" && `Pending : ${item.reportingManager[0].name}`) : item.approved}</th> */}
                                                        <th>{item.appraisalByReportingOfficer == 0 ? "Pending" : "Approved"}</th>
                                                        <th>{item.appraisalByReviewingOfficer == 0 ? "Pending" : "Approved"}</th>
                                                        {/* <th>{item.approved == "pending" ? (item.appraisalByReviewingOfficer == "0" && `Pending : ${item.reviewingManager[0].name}`) : item.approved}</th> */}
                                                        <th>{item.secondReportingOfficer.length < 1 ? "No Data" : item.secondReportingOfficer[0].name}</th>
                                                        <th>{item.secondReviewingOfficer.length < 1 ? "No Data" : item.secondReviewingOfficer[0].name}</th>
                                                        {/* <th>{item.approved}</th> */}
                                                        <th>{finalApproved}</th>

                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        )
    }
    renderSelect = (select: any, f: boolean) => {
        //console.log(select, f);
        return (<select name={select.name} value={select.value}
            onChange={this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {select.options.length && select.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.code}>{f ? e.name : e.yearCycle}</option>)
                })}
            </React.Fragment>
        </select>)
    }
    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value, () => {
            if (name === this.state.yearCycle.name) {
                let mnth = value && this.state.yearCycle.options.filter((res: any) => { return res.code == value; }).map((res: any) => res.performance)
                this.setState({
                    monthCycle: { ...this.state.monthCycle, options: this.sort(mnth[0]) },
                })
            }
        })

    }
    private sort = (arr: any, order?: number) => {
        if (arr.length > 1)
            return arr.sort((a: any, b: any,) => b.createdOn - a.createdOn)
        else
            return arr
    }
    private laodAppraisalList = () => {
        this.setState({ showLoader: true })
        getAppraisalCycle().then((res: any) => {
            if (res && res.result && res.result.length > 0) {
                const mnth = res.result[0].performance.length > 0 ? this.sort(res.result[0].performance) : [];
                this.setState({
                    // yearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[0].code },
                    // monthCycle: { ...this.state.monthCycle, options: mnth, value: mnth.length > 0 ? mnth[0].code : '' },
                    yearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[0].code },
                    monthCycle: { ...this.state.monthCycle, options: res.result[0].performance.length > 0 ? res.result[0].performance : [], value: res.result[0].performance.length > 0 ? res.result[0].performance[0].code : '' },
                    showLoader: false,
                }, () => {
                    //this.loadEmployee()
                    //this.loadEmployeeList()
                    //this.loadReportByAppraisalCycle()
                });
            }
        });
    }


    loadEmployee = () => {
        debugger
        this.setState({ showLoader: true })
        const { yearCycle, monthCycle } = this.state;
        const model = {
            yearCycle: yearCycle.value,
            monthCycle: monthCycle.value,
        }
        getPendingReportByAppraisalCycle(model)
            .then((res: any) => {
                console.log("res", res);

                this.setState({ showLoader: false })
                if (res)
                    this.setState({ acList: res.result })

            })

    }
}
export default PendingAssesementEmployee;
