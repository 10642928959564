import * as axios from 'axios';
import { getAuthHeader } from '../../utils';

export const addAppraisal: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/appraisal`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const addSubPerformance: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/sub-performance`;
    return axios.post(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}
export const associateEmployee: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/associate-emp`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const associateEmployeeS: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/associate-emp-sub`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}
export const updateAppraisalType: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/appraisal`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const updatePeformanceType: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/sub-performance`;
    return axios.put(url, reqObj, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const deleteAppraisalType: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/appraisal?code=${reqObj}`;
    return axios.delete(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const deletePeformanceType: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/sub-performance?code=${reqObj}`;
    return axios.delete(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const getAppraisalStatus: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/setting/status`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const getAppraisal: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/appraisal?code=${reqObj}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const getPerformance: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/sub-performance?code=${reqObj.code}&yearCycleCode=${reqObj.yearCycle}`;
    return axios.get(url, reqObj)
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

