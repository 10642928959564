import * as axios from 'axios';


/**  |||||||||||||||||||||||||||||||||||   ADD  |||||||||||||||||||||||||||||||||||||||||||||||||*/

export const addWeightage: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/weightage`;
    return axios.post(url, reqObj)
    .then((response: any) => {
        if (response)
            if (response.data.status)
                return response.data;
            else
                alert(response.data.error)
    })
    .catch((response: any) => {
        console.log('error', response);
        alert('NetWork Error')
    });

}

/**  |||||||||||||||||||||||||||||||||||   UPDATE  |||||||||||||||||||||||||||||||||||||||||||||||||*/


export const updateWeightage: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/weightage`;
    return axios.put(url, reqObj)
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')
        });

}

/**  |||||||||||||||||||||||||||||||||||   GET  |||||||||||||||||||||||||||||||||||||||||||||||||*/


export const getWeightage: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/weightage?code=${reqObj}`;
    return axios.get(url, reqObj)
    .then((response: any) => {
        if (response)
            if (response.data.status)
                return response.data;
            else
                alert(response.data.error)
    })
    .catch((response: any) => {
        console.log('error', response);
        alert('NetWork Error')
    });

}
export const getWeightageById: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/weightage-byId?code=${reqObj}`;
    return axios.get(url, reqObj)
    .then((response: any) => {
        if (response)
            if (response.data.status)
                return response.data;
            else
                alert(response.data.error)
    })
    .catch((response: any) => {
        console.log('error', response);
        alert('NetWork Error')
    });

}