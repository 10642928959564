
import * as axios from 'axios';
import { getAuthHeader } from '../../utils';

export const addBand: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/band`;
    return axios.post(url, reqObj)
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const updateBandType: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/band`;
    return axios.put(url, reqObj)
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const getBand: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/band?code=${reqObj}`;
    return axios.get(url, reqObj)
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}
