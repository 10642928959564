import * as React from 'react';
import * as XLSX from 'xlsx';
import { UncontrolledTooltip } from 'reactstrap';

import { uploadEmployeeByXL } from '../../../action/admin/Employee'
import { validateForm, isValidDate } from '../../../utils';
import Loader from '../../../component/common/Loader';
import CONSTANT from '../../../constant';

class UploadEmployee extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            employee: [],
            appraisalList: undefined,
            showLoader: false,
            trasnfer: false,
            key: 0,
        }


    }

    componentDidMount() {

    }

    public render() {

        const { showLoader, key } = this.state;
        return (
            <React.Fragment>

                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">

                                <div className="col-lg-12 mx-auto">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-6 col-lg-6 pl-0">
                                                <h5 className="heading-h1">Add Employee</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Upload Employee</b>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <label >Upload Employee Sheet </label>
                                                        </div>

                                                        <div className="col-lg-4 mt-2 mb-2">
                                                            <input
                                                                type="file"
                                                                key={key}
                                                                className=" mb-2"
                                                                onChange={this.handleFileOpen}
                                                            />
                                                        </div>

                                                        <div className="col-lg-4 pr-0 pl-0">
                                                            <a href={require('../../../assets/PMS-Sample.ods')}>Download Sample Sheet</a>

                                                            <button className="btn button-full action-btn col-lg-6 pull-right" onClick={this.onSubmit}>Submit</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-striped mb-0 mt-3">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Employee Id</th>
                                                            <th scope="col">Email Id</th>
                                                            <th scope="col">Mobile No</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Designation</th>
                                                            <th scope="col">Department</th>
                                                            <th scope="col">Location</th>
                                                            <th scope="col">Reporting Officer</th>
                                                            <th scope="col">Second Reporting Officer</th>
                                                            <th scope="col">Reviewing Officer</th>
                                                            <th scope="col">Second Reviewing Officer</th>
                                                            <th scope="col">Status</th>
                                                            {/* <th scope="col">Remark</th> */}

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.renderEmployeeTable()}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    showLoader && <Loader />
                }
            </React.Fragment>

        )
    }


    private renderEmployeeTable = () => {
        return this.state.employee.map((item: any, index: number) => {
            return (<tr key={index}>
                {item['Emp Id'] == '' || item['Emp Id'] == undefined ? <td id={`emp_id_${index}`} className="table-danger">{item['Emp Id']} <UncontrolledTooltip target={`emp_id_${index}`}>Check Emp Id!</UncontrolledTooltip></td> : <td>{item['Emp Id']}</td>}
                {item['Email Id'] == '' || item['Email Id'] == undefined ? <td id={`emp_id_${index}`} className="table-danger">{item['Email Id']} <UncontrolledTooltip target={`emp_id_${index}`}>Check Email Id!</UncontrolledTooltip></td> : <td>{item['Email Id']}</td>}
                {item['Mobile No'] == '' || item['Mobile No'] == undefined ? <td id={`emp_id_${index}`} className="table-danger">{item['Mobile No']} <UncontrolledTooltip target={`emp_id_${index}`}>Check Mobile No!</UncontrolledTooltip></td> : <td>{item['Mobile No']}</td>}
                {item['Name'] == '' || item['Name'] == undefined ? <td id={`emp_name_${index}`} className="table-danger">{item['Name']} <UncontrolledTooltip target={`emp_name_${index}`}>Check employee Name!</UncontrolledTooltip></td> : <td>{item['Name']}</td>}
                {item['Designation'] == '' || item['Designation'] == undefined ? <td id={`emp_name_${index}`} className="table-danger">{item['Designation']} <UncontrolledTooltip target={`emp_name_${index}`}>Check Designation!</UncontrolledTooltip></td> : <td>{item['Designation']}</td>}
                {item['Department'] == '' || item['Department'] == undefined ? <td id={`emp_name_${index}`} className="table-danger">{item['Department']} <UncontrolledTooltip target={`emp_name_${index}`}>Check Department!</UncontrolledTooltip></td> : <td>{item['Department']}</td>}
                {item['Location'] == '' || item['Location'] == undefined ? <td id={`emp_name_${index}`} className="table-danger">{item['Location']} <UncontrolledTooltip target={`emp_name_${index}`}>Check Location!</UncontrolledTooltip></td> : <td>{item['Location']}</td>}
                {item['Reporting Officer'] == '' || item['Reporting Officer'] == undefined ? <td id={`emp_name_${index}`} className="table-danger">{item['Reporting Officer']} <UncontrolledTooltip target={`emp_name_${index}`}>Check Reporting Officer!</UncontrolledTooltip></td> : <td>{item['Reporting Officer']}</td>}
                {item['Second Reporting Officer'] == '' || item['Second Reporting Officer'] == undefined ? <td id={`emp_name_${index}`} className="table-danger">{item['Second Reporting Officer']} <UncontrolledTooltip target={`emp_name_${index}`}>Check Second Reporting Officer!</UncontrolledTooltip></td> : <td>{item['Second Reporting Officer']}</td>}
                {item['Second Reviewing Officer'] == '' || item['Second Reviewing Officer'] == undefined ? <td id={`emp_name_${index}`} className="table-danger">{item['Second Reviewing Officer']} <UncontrolledTooltip target={`emp_name_${index}`}>Check Second Reviewing Officer!</UncontrolledTooltip></td> : <td>{item['Second Reviewing Officer']}</td>}
                {item['Reviewing Officer'] == '' || item['Reviewing Officer'] == undefined ? <td id={`emp_name_${index}`} className="table-danger">{item['Reviewing Officer']} <UncontrolledTooltip target={`emp_name_${index}`}>Check Reviewing Officer!</UncontrolledTooltip></td> : <td>{item['Reviewing Officer']}</td>}
                {item['Status'] == '' || item['Status'] == undefined ? <td id={`emp_name_${index}`} className="table-danger">{item['Status']} <UncontrolledTooltip target={`emp_name_${index}`}>Check Status!</UncontrolledTooltip></td> : <td>{item['Status']}</td>}

            </tr>)
        })
    }

    private handleFileOpen = (e: any) => {
        var context = this;
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = function (e: any) {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array' });
            var json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
            context.setState({ employee: json });
            console.log(f.name);
        };
        reader.readAsArrayBuffer(f);
    }


    onSubmit = (model: any) => {
        let valid = true;

        this.state.employee.forEach((item: any, i: number) => {
            const index = i + 1;
            if (item['Emp Id'] == '' || item['Emp Id'] == undefined) {
                alert(`'Emp Id is empty or invalid string in Emp Id at row ${index}`)
                valid = false;
                return;
            }

            if (item['Mobile No'] == '' || item['Mobile No'] == undefined) {
                alert(`Mobile No is empty or invalid string in Mobile No at row ${index}`)
                valid = false;
                return;
            }

            if (item['Name'] == '' || item['Name'] == undefined) {
                alert(`Name is empty or invalid string in Name at row ${index}`)
                valid = false;
                return;
            }

            if (item['Designation'] == '' || item['Designation'] == undefined) {
                alert(`Designation is empty or invalid string in Designation at row ${index}`)
                valid = false;
                return;
            }

            if (item['Department'] == '' || item['Department'] == undefined) {
                alert(`Department is empty or invalid string in Department at row ${index}`)
                valid = false;
                return;
            }

            if (item['Location'] == '' || item['Location'] == undefined) {
                alert(`Location is empty or invalid string in Location at row ${index}`)
                valid = false;
                return;
            }

            if (item['Reporting Officer'] == '' || item['Reporting Officer'] == undefined) {
                alert(`Reporting Officer is empty or invalid string in Reporting Officer at row ${index}`)
                valid = false;
                return;
            }

            if (item['Status'] == '' || item['Status'] == undefined) {
                alert(`Status is empty or invalid string in Status at row ${index}`)
                valid = false;
                return;
            }

        })

        console.log('is valid =', valid)
        if (valid) {
            const payload = this.state.employee.map((item: any) => ({
                reportingOfficer: item['Reporting Officer'],
                secondReportingOfficer: item['Second Reporting Officer'],
                secondReviewingOfficer: item['Second Reviewing Officer'],
                reviewingOfficer: item['Reviewing Officer'],
                designation: item['Designation'],
                department: item['Department'],
                location: item['Location'],
                status: item['Status'],
                emailId: item['Email Id'],
                mobile: item['Mobile No'],
                empCode: item['Emp Id'],
                name: item['Name'],
            }))

            this.setState({ showLoader: true });
            uploadEmployeeByXL(payload).then((resp: any) => {
                this.setState({ showLoader: false, employee: [], key: Math.random() });
                if (resp) {
                    alert('Record Update Successfully')
                    this.setState({ showLoader: false, employee: [] });
                    this.props.history.push(CONSTANT.url.employeeList)
                }
            })

        }
    }

}

export default UploadEmployee;