import * as axios from 'axios';
import { setCookie, getAuthHeader } from '../utils';


export const login: any = (userDetail: any) => {
    const loginUrl = `${process.env.REACT_APP_BASE_URL}/v1/login`;
    return axios.post(loginUrl, userDetail)
        .then((response: any) => {
            if (response)
                if (response.data.status) {
                    setCookie('token', response.data.result.token);
                    return response.data;
                }
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('Network Error.')
        });
}


export const changePassword = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/change-password`;
    return axios.post(url, model, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status) {
                    return response.data;
                }
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('Network Error.')
        });
}

export const forgotPassword = (model: any) => {
    debugger
    const url = `${process.env.REACT_APP_BASE_URL}/v1/forgot-password`;
    return axios.post(url, model)
        .then((response: any) => {
            if (response)
                if (response.data.status) {
                    return response.data;
                }
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('Network Error.')
        });
}

export const resetPassword = (model: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/reset-password`;
    return axios.post(url, model)
        .then((response: any) => {
            if (response)
                if (response.data.status) {
                    return response.data;
                }
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('Network Error.')
        });
}