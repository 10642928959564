import * as React from 'react';
import { Link } from 'react-router-dom';

import { getAppraisal } from '../../../action/admin/Appraisal';
import { getEmployee } from '../../../action/admin/Employee';
import { isValidDate,validateForm } from '../../../utils';
import TransferEmp from '../../admin/employee/Transfer';
import CONSTANT from '../../../constant';

class ReviewCycle extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            trasnfer: false,
            appraisalList: undefined,
            employeeList: undefined
        }


    }

    componentDidMount() {
        this.getAllAppraisal();
        this.getAllEmployeeList();
    }

    public render() {

        const { appraisalList, employeeList, trasnfer } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">

                                <div className="col-lg-12">

                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">List of Employee</h5>
                                            </div>
                                            <div className="col-6 col-lg-6 text-right pr-0">
                                                <a className="action-btn" onClick={this.transferEmp}><i className="fa fa-check-square-o"></i>&nbsp;Employee Transfer </a>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Appraisal
                                           </b>
                                        </div>
                                        <div className="card-body">

                                            {
                                                trasnfer &&
                                                <TransferEmp />
                                            }
                                            {
                                                !trasnfer && <form>
                                                    <div className="col-lg-12 mb-2">
                                                        <div className="row">
                                                            <div className="col-lg-6 form-group">
                                                                <label > Appraisal Cycle  </label>
                                                                <select className="form-control">
                                                                    <option>Appraisal Cycle - 2020</option>
                                                                    <option>Appraisal Cycle - 2019</option>
                                                                </select>

                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Sub Performance </label>

                                                                <select className="form-control">
                                                                    <option>3 Month Cycle</option>
                                                                    <option>6 Month Cycle</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            }

                                        </div>

                                    </div>
                                    <div className="card mt-3">
                                        <div className="card-header">
                                            <b>Employee
                                           </b>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Employee Name</th>
                                                            <th scope="col">Designation</th>
                                                            <th scope="col">Department</th>
                                                            {/* <th scope="col">Created Date</th> */}
                                                            <th scope="col">Location</th>
                                                            <th scope="col">Reporting Officer</th>
                                                            {/* <th scope="col">Created Date</th> */}
                                                            {/* <th scope="col">Remark</th> */}
                                                            <th scope="col">Status</th>
                                                            <th scope="col"></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            employeeList === undefined && <tr>
                                                                <td className="text-center" colSpan={7}>Loading...</td>
                                                            </tr>
                                                        }
                                                        {
                                                            employeeList !== undefined && employeeList.length === 0 && <tr>
                                                                <td className="text-center" colSpan={7}>No records found</td>
                                                            </tr>
                                                        }
                                                        {
                                                            employeeList !== undefined &&
                                                            employeeList.map((item: any, ind: number) => {
                                                                return (
                                                                    <tr>
                                                                        {/* <td>{item.employeeCode}</td> */}
                                                                        <td>{item.name}</td>
                                                                        <td>{item.designation}</td>
                                                                        <td>{item.department}</td>

                                                                        <td>{item.location}</td>
                                                                        <td>{item.reportingOfficer}</td>
                                                                        <td>{item.status}</td>

                                                                        <th scope="col">

                                                                            {/* <Link to={CONSTANT.url.editAppraisal.replace(':code', item.code)}>
                                                                                <a>
                                                                                    <i className="fa fa-pencil"></i>
                                                                                </a>
                                                                            </Link> */}

                                                                        </th>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </React.Fragment>

        )
    }

    transferEmp = () => {
        this.setState({ trasnfer: !this.state.trasnfer })
    }

    // onSubmit = (model: any) => {
    //     addSubPerformance(model)
    //         .then((res: any) => {

    //             this.props.history.push(CONSTANT.url.performanceList)
    //         })
    //         .catch((err: any) => {
    //             alert(err)

    //         })

    // }

    private getAllAppraisal = () => {
        getAppraisal(undefined).then((response: any) => {

            this.setState({
                appraisalList: response.result,
                showLoader: false

            });
        }, (error: any) => {
            alert(error.Message);
            this.setState({ showLoader: false });
        });
    }


    private getAllEmployeeList = () => {
        getEmployee(undefined).then((response: any) => {

            this.setState({
                employeeList: response.result,
                showLoader: false

            });
        }, (error: any) => {
            alert(error.Message);
            this.setState({ showLoader: false });
        });
    }

}

export default ReviewCycle;