import * as React from 'react';

import { updateWeightage, getWeightageById } from '../../../action/admin/weightage';
import AddWeightage from '../../../component/Weightage'
import CONSTANT from '../../../constant';

class EditWeightage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            code: { value: props.match.params.code },
            weightage: undefined
        }
    }
    componentDidMount() {
        this.laodWeightage();

    }
    public render() {
        return (
            <React.Fragment>
                <React.Fragment>
                    {
                        this.state.weightage &&
                        <AddWeightage
                            weightage={this.state.weightage}
                            onSubmit={this.onSubmit}
                            Weightage={this.state.weightage}
                        />}
                </React.Fragment >
            </React.Fragment >

        )
    }

    private laodWeightage = () => {
        this.setState({ showLoader: true })
        getWeightageById(this.state.code.value).then((response: any) => {
            if (response && response.result)
                this.setState({
                    weightage: response.result,
                    showLoader: false,
                });

        });
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        updateWeightage(model)
            .then((res: any) => {
                alert('Record Update Successfully')
                this.setState({
                    weightage: res.result,
                    showLoader: false
                });
                this.props.history.push(CONSTANT.url.weightage)
            })
    }

}

export default EditWeightage;