import moment from 'moment';
import * as React from 'react';
import * as  XLSX from "xlsx";
import Loder from '../../../component/common/Loader'
import JsonToPdf from '../../../component/common/Json-Pdf'
import { validateForm, onChange, setOptions } from '../../../utils';
import {
    getAppraisalCycle, getDepartmentList, getBehaviourList, getReport,
    userDetailSuperReports
} from '../../../action/Master';
import CONSTANT from '../../../constant';


class Appraise extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            yearCycle: { name: 'yearCycle', value: '', options: [], error: '', isRequired: true },
            monthCycle: { name: 'monthCycle', value: '', options: [], error: '', isRequired: true },
            designation: { name: 'designation', value: '', options: [], error: '', isRequired: true },
            department: { name: 'department', value: '', options: [], error: '', isRequired: true, },
            filter: { name: 'filter', value: 'all', error: '', isRequired: true, },
            empCode: { name: 'empCode', value: '', error: '', isRequired: true },
            data: undefined,
            data1: undefined,
            showLoader: false,
            status: true

        }
    }

    componentDidMount() {
        this.loadList()
    }
    public render() {

        const { empCode, showLoader, data, data1, department, filter, designation, yearCycle, monthCycle } = this.state;

        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-6 col-lg-6 pl-0">
                                                <h5 className="heading-h1">Appraisal Report</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Appraisal
                                            </b>
                                        </div>
                                        <div className="card-body">
                                            {
                                                <form>
                                                    <div className="col-lg-12 mb-2">
                                                        <div className="row">
                                                            <div className="col-lg-6 form-group">
                                                                <label > Appraisal Cycle  </label>
                                                                {this.renderSelect(yearCycle, false)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Sub Performance </label>
                                                                {this.renderSelect(monthCycle, true)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Designation </label>
                                                                {this.renderSelect(designation, true)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <button className="col-lg-4 btn button-full mt-4 " onClick={this.generateDesignationPdf}>View  Report</button>
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Department</label>
                                                                {this.renderSelect(department, true)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <button className="col-lg-4 btn button-full mt-4" onClick={this.generateDepartmentPdf}>View  Report</button>
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <div></div>
                                                                <label > Employee Id </label>
                                                                <input
                                                                    name={empCode.name}
                                                                    onChange={this._onChange}
                                                                    value={empCode.value}
                                                                    type="text"
                                                                    className={empCode.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                                    placeholder="Enter Employee Id"
                                                                />
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <button className="col-lg-4 btn button-full mt-4 pull" id="card-body" onClick={this.generateEmpPdf}>View  Report</button>
                                                            </div>
                                                            <div className="col-lg-4 form-group">
                                                                <button className=" btn button-full mt-4" onClick={this.genrateXl}>View  Report</button>
                                                            </div>
                                                        </div>

                                                        {(data1) &&
                                                            <div className='row'>
                                                                <div className="col-lg-6 form-group">
                                                                    <label >Goal Tracker</label>
                                                                    <select name={filter.name} value={filter.value}
                                                                        onChange={this._onChange}
                                                                        className={filter.error.length > 0 ? "form-control is-invalid" : "form-control"}>
                                                                        <option value="all">All Employee</option>
                                                                        <option value="pending">Pending Employee</option>
                                                                        <option value="approved">Approved Employee</option>
                                                                        <option value="reject">Rejectd Employee</option>
                                                                        <option value="completed">Completed Employee</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-6 form-group">
                                                                    <button className="col-lg-6 btn  button-full mt-6" onClick={this.downloadXL}>Export Excel</button>
                                                                </div>
                                                            </div>

                                                        }
                                                        <div className="row">

                                                            <div className="col-lg-4 form-group">
                                                                <button className=" btn button-full mt-4  pull" onClick={this.userDetailSuperReport}>Employee Detail Super Report</button>
                                                            </div>

                                                            <div className="col-lg-4 form-group">
                                                                <button className="btn button-full mt-4 pull" onClick={this.userMarksSuperReport} >Employee Marks Super Report</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </form>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                 <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">

                                    {
                                        data &&
                                        <JsonToPdf
                                            data={data}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    showLoader && <Loder />
                }
            </React.Fragment >

        )
    }

    private downloadXL = (e: any) => {
        debugger
        e.preventDefault()
        // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        // const fileExtension = '.xlsx';

        // const exportToCSV = (csvData, fileName) => {
        //     const ws = XLSX.utils.json_to_sheet(csvData);
        //     const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        //     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        //     const data = new Blob([excelBuffer], { type: fileType });
        //     FileSaver.saveAs(data, fileName + fileExtension);
        // }
        if (validateForm(this, this.state.filter)) {

            let date = new Date();
            let filename = `tract-record-${date.getTime()}.xlsx`;
            let ws_name = "Sheet1";
            const data = this.state.data1 && this.state.data1.length > 0 && this.state.data1.filter((item: any) => {
                // 
                if (this.state.filter.value === 'completed')
                    return item.goal.selfAppraisal === true;
                else if (this.state.filter.value === 'pending')
                    return item.goal.approved === 'pending';
                else if (this.state.filter.value === 'reject')
                    return item.goal.approved === 'reject';
                else if (this.state.filter.value === 'approved')
                    return item.goal.approved === 'approved';
                else return true
            })

            let dataToExport = data.length > 0 ? data.map((item: any, index: any) => {
                return {
                    'Employee Code': item.empCode,
                    'Name': item.name,
                    'Email Id': item.emailId,
                    'Designation': item.designation,
                    'Department': item.department,
                    'Goal Set Complete': item.goal.selfAppraisal ? 'YES' : 'NO',
                    'Approval of Reporting Officer': item.goal.approved,
                }

            })
                : []
            var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(dataToExport);
            // add worksheet to workbook /
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            // write workbook /
            XLSX.writeFile(wb, filename);
        }
    }

    renderSelect = (select: any, f: boolean) => {
        return (<select name={select.name} value={select.value}
            onChange={this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {(select.name === 'designation' || select.name === 'department') && <option value="all">All</option>}
                {select.options.length && select.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={(select.name === 'designation' || select.name === 'department') ? e.name : e.code}>{f ? e.name : e.yearCycle}</option>)
                })}
            </React.Fragment>
        </select>)
    }

    private sort = (arr: any, order?: number) => {
        if (arr.length > 1)
            return arr.sort((a: any, b: any,) => b.createdOn - a.createdOn)
        else
            return arr
    }
    //for employee Code
    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value, () => {
            if (name === this.state.yearCycle.name) {
                let mnth = value && this.state.yearCycle.options.filter((res: any) => { return res.code == value; }).map((res: any) => res.performance)
                this.setState({
                    monthCycle: { ...this.state.monthCycle, options: this.sort(mnth[0]) },
                })
            }
        })

    }
    //for employee Code

    private loadList = () => {
        getAppraisalCycle().then((res: any) => {
            if (res && res.result && res.result.length) {
                const mnth = res.result[0].performance.length > 0 ? this.sort(res.result[0].performance) : [];
                this.setState({
                    yearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[0].code },
                    monthCycle: { ...this.state.monthCycle, options: mnth, value: mnth.length > 0 ? mnth[0].code : '' },
                    showLoader: false,
                });
            }
        });

        getBehaviourList().then((response: any) => {
            if (response && response.result && response.result.length > 0)
                setOptions(this, 'designation', response.result)

        });
        getDepartmentList().then((response: any) => {
            if (response && response.result && response.result.length > 0)
                setOptions(this, 'department', response.result)

        });

    }

    private genrateXl = (e: any) => {
        debugger
        e.preventDefault()
        const { yearCycle, monthCycle } = this.state
        if (validateForm(this, { yearCycle, monthCycle })) {
            this.setState({ showLoader: true })
            getReport({ yearCycle: yearCycle.value, monthCycle: monthCycle.value, designation: 'all' })
                .then((response: any) => {
                    this.setState({ showLoader: false })
                    if (response && response.result && response.result.length > 0)
                        console.log(" response.result", response.result);

                    this.setState({ data1: response.result, })
                });
        }
    }

    private generateDesignationPdf = (e: any) => {
        e.preventDefault()
        const { yearCycle, monthCycle, designation } = this.state
        console.log("designation", designation.value);

        if (validateForm(this, { yearCycle, monthCycle, designation })) {
            this.setState({ showLoader: true })
            getReport({ yearCycle: yearCycle.value, monthCycle: monthCycle.value, designation: designation.value })
                .then((response: any) => {
                    this.setState({ showLoader: false })

                    if (response && response.result && response.result.length > 0)
                        console.log(" resssss    >>>>>>>>>>>> data ", response.result);

                    this.setState({ data: response.result })

                    console.log("data2", this.state.data);

                });
        }
    }

    private generateDepartmentPdf = (e: any) => {
        e.preventDefault()
        const { yearCycle, monthCycle, department } = this.state
        if (validateForm(this, { yearCycle, monthCycle, department })) {
            this.setState({ showLoader: true })
            getReport({ yearCycle: yearCycle.value, monthCycle: monthCycle.value, department: department.value })
                .then((response: any) => {
                    this.setState({ showLoader: false })
                    if (response && response.result && response.result.length > 0)
                        this.setState({ data: response.result, })
                });
        }
    }

    private generateEmpPdf = (e: any) => {
        /// do

        e.preventDefault()
        const { yearCycle, monthCycle, empCode } = this.state
        if (validateForm(this, { yearCycle, monthCycle, empCode })) {
            this.setState({ showLoader: true })
            getReport({ yearCycle: yearCycle.value, monthCycle: monthCycle.value, empCode: empCode.value })
                .then((response: any) => {
                    this.setState({ showLoader: false })
                    if (response && response.result && response.result.length > 0)
                        this.setState({ data: response.result, })

                });

        }
        //     //  @ts-ignore 
        //     var printContents = document.getElementById("card-body").innerHTML;
        //     var originalContents = document.body.innerHTML;
        //     document.body.innerHTML = printContents;
        //     window.print();
        //    document.body.innerHTML = originalContents;
    }



    private checkAss = (goal: any, userId: any) => {
        debugger
        const ass = goal ? [...goal.department, ...goal.behaviour, ...goal.activity] : [];
        return ass.length > 0 ?
            ass.map((e: any) => {

                if (e.pfaByMn.length > 0) {
                    const pfaByMn = e.pfaByMn.filter((e: any) => {

                        if (e.userCode == userId)
                            return true
                    })
                    return { ...e, pfaByMn }
                }
                else if (e.pfaByRm.length > 0) {
                    const pfaByRm = e.pfaByRm.filter((e: any) => {
                        if (e.userCode == userId)
                            return true
                    })
                    return { ...e, pfaByRm }
                }
                else {
                    return { ...e, pfaByRm: [], pfaByMn: [] }
                }
            })
            :
            []
    }

    private userDetailSuperReport = (e: any) => {

        e.preventDefault()
        const { yearCycle, monthCycle } = this.state
        if (validateForm(this, { yearCycle, monthCycle })) {
            this.setState({ showLoader: true })
            userDetailSuperReports({ yearCycle: yearCycle.value, monthCycle: monthCycle.value })
                .then((response: any) => {

                    this.setState({ showLoader: false })
                    if (response && response.result && response.result.length > 0) {
                        let date = new Date();
                        let filename = `employee-detail-super-report-${date.getTime()}.xlsx`;
                        let ws_name = "Sheet1";

                        let dataToExport = response.result.map((item: any, index: any) => {
                            const ass1 = item.reportingOfficer.length > 0 ? this.checkAss(item.goal, item.qreportingOfficer[0].code) : []
                            const ass2 = item.reviewingOfficer.length > 0 ? this.checkAss(item.goal, item.qreviewingOfficer[0].code) : []
                            const ass3 = item.secondReportingOfficer.length > 0 ? this.checkAss(item.goal, item.qsecondReportingOfficer[0].code) : []
                            const goalApprovel: any = [...item.reportingOfficer, ...item.secondReportingOfficer].filter((e) => {
                                if (e)
                                    if (item.approvedBy && item.approvedBy.length > 0 && e.empCode == item.approvedBy[0].empCode)
                                        return true
                            })
                            const qgoalApprovel: any = [...item.qreportingOfficer, ...item.qsecondReportingOfficer].filter((e) => {
                                if (e)
                                    if (item.approvedBy && item.approvedBy.length > 0 && e.empCode == item.approvedBy[0].empCode)
                                        return true
                            })

                            return {
                                'Appraisal Cycle': item.yearCycle.yearCycle,
                                'Sub Appraisal Cycle': item.subPerformanceCycle.name,
                                'Maximum Weightage For Behavior': item.weightage ? item.weightage.maxBehaviour : '',
                                'Weightage  For Behavior': item.weightage ? item.weightage.behaviour : '',
                                'Maximum Weightage For Department': item.weightage ? item.weightage.maxDepartment : '',
                                'Weightage  For Department': item.weightage ? item.weightage.department : '',
                                'Maximum Weightage For KRA ': item.weightage ? item.weightage.maxActivities : '',
                                'Weightage  For KRA ': item.weightage ? item.weightage.activities : '',
                                'EMP CODE': item.empCode,
                                'EMP NAME': item.name,
                                'Designation': item.designation,
                                'Department': item.department,
                                'Employee Email Id': item.emailId,
                                'Mobile No': 'N/A',
                                'User Type': item.userType,
                                'Location': item.location,
                                'Status': item.status,
                                'Goal Submit': item.goal ? 'YES' : 'NO',

                                'Self Appraisal Complete': item.goal ? (item.selfAppraisal ? 'Yes' : 'No') : 'N/A',
                                'Goal approval Officer Code': goalApprovel.length > 0 ? goalApprovel[0].empCode : 'N/A',
                                'Goal approval Officer Name': goalApprovel.length > 0 ? goalApprovel[0].name : 'N/A',
                                'Goal approval  Officer Designation ': qgoalApprovel.length > 0 ? qgoalApprovel[0].designation : 'N/A',
                                'Goal approval Officer Email': goalApprovel.length > 0 ? goalApprovel[0].emailId : 'N/A',
                                'Goal Approved': item.goal ? (item.approved == 'approved' ? 'Yes' : 'No') : 'N/A',
                                'Goal Rejected': item.goal ? (item.approved == 'reject' ? 'Yes' : 'No') : 'N/A',

                                'First Reporting Officer Code': item.reportingOfficer.length > 0 ? item.reportingOfficer[0].empCode : 'N/A',
                                'First Reporting Officer Name': item.reportingOfficer.length > 0 ? item.reportingOfficer[0].name : 'N/A',
                                'First Reporting Officer Designation ': item.qreportingOfficer.length > 0 ? item.qreportingOfficer[0].designation : 'N/A',
                                'First Reporting Officer Email': item.reportingOfficer.length > 0 ? item.reportingOfficer[0].emailId : 'N/A',
                                'First Reporting Officer Assessment Made': ass1.length > 0 ? ass1.every((e) => (e.pfaByRm.length > 0)) == true ? 'YES' : 'NO' : 'N/A',

                                'Reporting  and Reviewing officer are Same  ': item.reportingOfficer.length > 0 ? (item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].code == item.reportingOfficer[0].code ? 'YES' : 'NO' : 'N0') : 'N/A',

                                'Reviewing Officer Code': item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].empCode : 'N/A',
                                'Reviewing Officer Name': item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].name : 'N/A',
                                'Reviewing Officer Designation': item.qreviewingOfficer.length > 0 ? item.qreviewingOfficer[0].designation : 'N/A',
                                'Reviewing Officer Email': item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].emailId : 'N/A',
                                'Reviewing Officer Assessment Made': ass2.length > 0 ? ass2.every((e) => (e.pfaByRm.length > 0)) == true ? 'YES' : 'NO' : 'N/A',

                                'Dual Reporting ': item.secondReportingOfficer.length > 0 ? 'YES' : 'NO',

                                'Second Reporting Officer Code': item.secondReportingOfficer.length > 0 ? item.secondReportingOfficer[0].empCode : 'N/A',
                                'Second Reporting Officer Name': item.secondReportingOfficer.length > 0 ? item.secondReportingOfficer[0].name : 'N/A',
                                'Second Reporting Officer Designation ': item.qsecondReportingOfficer.length > 0 ? item.qsecondReportingOfficer[0].designation : 'N/A',
                                'Second Reporting Officer Email': item.secondReportingOfficer.length > 0 ? item.secondReportingOfficer[0].emailId : 'N/A',
                                'Second Reporting Officer Assessment Made': ass3.length > 0 ? ass3.every((e) => (e.pfaByRm.length > 0)) == true ? 'YES' : 'NO' : 'N/A',
                            }
                        })
                        // console.log(dataToExport)
                        var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(dataToExport);
                        // add worksheet to workbook /
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);
                        // write workbook /
                        XLSX.writeFile(wb, filename);
                    }

                });
        }
    }

    private userMarksSuperReport = (e: any) => {

        e.preventDefault()
        const { yearCycle, monthCycle } = this.state
        if (validateForm(this, { yearCycle, monthCycle })) {
            this.setState({ showLoader: true })
            userDetailSuperReports({ yearCycle: yearCycle.value, monthCycle: monthCycle.value })
                .then((response: any) => {
                    this.setState({ showLoader: false })
                    if (response && response.result && response.result.length > 0) {

                        let date = new Date();
                        let filename = `employee-marks-super-report-${date.getTime()}.xlsx`;
                        let ws_name = "Sheet1";

                        let dataToExport = response.result.map((item: any, index: any) => {
                            if (item.goal) {
                                return [...item.goal.department, ...item.goal.behaviour, ...item.goal.activity].map((g: any, index: any) => {
                                    return {
                                        'Appraisal Cycle': item.yearCycle.yearCycle,
                                        'Sub Appraisal Cycle': item.subPerformanceCycle.name,
                                        'EMP CODE': item.empCode,
                                        'EMP NAME': item.name,
                                        'Designation': item.designation,
                                        'Department': item.department,
                                        'Employee Email Id': item.emailId,
                                        'Mobile No': 'N/A',
                                        'User Type': item.userType,
                                        'Location': item.location,
                                        'Status': item.status,
                                        'Goal Submit': item.goal ? 'YES' : 'NO',

                                        'First Reporting Officer Code': item.reportingOfficer.length > 0 ? item.reportingOfficer[0].empCode : 'N/A',
                                        'Reviewing Officer Code': item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].empCode : 'N/A',
                                        'Reporting  and Reviewing officer are Same  ': item.reportingOfficer.length > 0 ? (item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].code == item.reportingOfficer[0].code ? 'YES' : 'NO' : 'N0') : 'N/A',
                                        'Dual Reporting ': item.secondReportingOfficer.length > 0 ? 'YES' : 'NO',
                                        'Second Reporting Officer Code': item.secondReportingOfficer.length > 0 ? item.secondReportingOfficer[0].empCode : 'N/A',
                                        'Second Reviewing Officer Code': item.secondReviewingOfficer.length > 0 ? item.secondReviewingOfficer[0].empCode : 'N/A',

                                        'Maximum Weightage For Behavior': item.weightage.maxBehaviour,
                                        'Weightage  For Behavior': item.weightage.behaviour,
                                        'Maximum Weightage For Department': item.weightage.maxDepartment,
                                        'Weightage  For Department': item.weightage.department,
                                        'Maximum Weightage For KRA ': item.weightage.maxActivities,
                                        'Weightage  For KRA ': item.weightage.activities,
                                        'Skills Type': g.name,
                                        'Weightage': g.weightage,
                                        'Self Marks': g.pfaByEmp.length > 0 ? g.pfaByEmp[0].value : 'Not Enter',

                                        'Reporting Officer Marks': item.reportingOfficer.length > 0 ? (g.pfaByMn.length > 0 ? g.pfaByEmp[0].value : 'Not Enter') : 'N/A',
                                        'Reviewing Officer Marks': item.reviewingOfficer.length > 0 ? (g.pfaByRm.length > 0 ? g.pfaByEmp[0].value : 'Not Enter') : 'N/A',
                                        'Second Reporting Officer Marks': item.secondReportingOfficer.length > 0 ? (g.pfaByRm.length > 1 ? g.pfaByRm[1].value : 'Not Enter') : 'N/A',
                                        'Second Reviewing Officer Marks': item.secondReviewingOfficer.length > 0 ? (g.pfaByMn.length > 1 ? g.pfaByMn[1].value : 'Not Enter') : 'N/A'

                                    }
                                })
                            }
                            else
                                return [
                                    {
                                        'Appraisal Cycle': item.yearCycle.yearCycle,
                                        'Sub Appraisal Cycle': item.subPerformanceCycle.name,
                                        'EMP CODE': item.empCode,
                                        'EMP NAME': item.name,
                                        'Designation': item.designation,
                                        'Department': item.department,
                                        'Employee Email Id': item.emailId,
                                        'Mobile No': 'N/A',
                                        'User Type': item.userType,
                                        'Location': item.location,
                                        'Status': item.status,
                                        'Goal Submit': item.goal ? 'YES' : 'NO',

                                        'First Reporting Officer Code': item.reportingOfficer.length > 0 ? item.reportingOfficer[0].empCode : 'N/A',
                                        'Reviewing Officer Code': item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].empCode : 'N/A',
                                        'Reporting  and Reviewing officer are Same  ': item.reportingOfficer.length > 0 ? (item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].code == item.reportingOfficer[0].code ? 'YES' : 'NO' : 'N0') : 'N/A',
                                        'Dual Reporting ': item.secondReportingOfficer.length > 0 ? 'YES' : 'NO',
                                        'Second Reporting Officer Code': item.secondReportingOfficer.length > 0 ? item.secondReportingOfficer[0].empCode : 'N/A',
                                        'Second Reviewing Officer Code': item.secondReviewingOfficer.length > 0 ? item.secondReviewingOfficer[0].empCode : 'N/A',

                                        'Maximum Weightage For Behavior': item.weightage ? item.weightage.maxBehaviour : '',
                                        'Weightage  For Behavior': item.weightage ? item.weightage.behaviour : '',
                                        'Maximum Weightage For Department': item.weightage ? item.weightage.maxDepartment : '',
                                        'Weightage  For Department': item.weightage ? item.weightage.department : '',
                                        'Maximum Weightage For KRA ': item.weightage ? item.weightage.maxActivities : '',
                                        'Weightage  For KRA ': item.weightage ? item.weightage.activities : '',
                                        'Skills Type': 'N/A',
                                        'Weightage': 'N/A',
                                        'Self Marks': 'N/A',

                                        'Reporting Officer Marks': 'N/A',
                                        'Reviewing Officer Marks': 'N/A',
                                        'Second Reporting Officer Marks': 'N/A',
                                        'Second Reviewing Officer Marks': 'N/A',
                                    }
                                ]
                        })
                        var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(dataToExport.flat());
                        // add worksheet to workbook /
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);
                        // write workbook /
                        XLSX.writeFile(wb, filename);
                    }

                });
        }
    }

}

export default Appraise;