import * as React from 'react';
import { Link } from 'react-router-dom';

import { getAppraisalStatus } from '../../action/admin/Appraisal';
import { validateForm } from '../../utils';
import CONSTANT from '../../constant'

class Appraisal extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const performanceDetail = props.performanceDetail;

        this.state = {
            name: { value: performanceDetail ? performanceDetail.name : '', name: 'name', error: '', isRequired: true },
            startDate: { value: performanceDetail ? performanceDetail.startDate : '', name: 'startDate', error: '', isRequired: true },
            endDate: { value: performanceDetail ? performanceDetail.endDate : '', name: 'endDate', error: '', isRequired: true },
            yearCycleCode: { value: performanceDetail ? performanceDetail.yearCycleCode : '', name: 'yearCycleCode', options: [], error: '', isRequired: false },
            status: { value: performanceDetail ? performanceDetail.status : '', name: 'status', error: '', isRequired: true },
            remark: { value: performanceDetail ? performanceDetail.remark : '', name: 'remark', error: '', isRequired: true },
            statusList: { name: 'statusList', value: performanceDetail ? performanceDetail.statusList : '', error: '', options: [], },
            yearCycleList: props.appraisalList ? props.appraisalList : [],
            code: { name: 'code', value: performanceDetail ? performanceDetail.code : '', }

        }
    }

    componentDidMount() {
        this.getStatus()
    }

    public render() {
        const { code,name, startDate, endDate, yearCycleCode, status, remark, statusList, yearCycleList } = this.state;
        return (
            <React.Fragment>

                <div className="card mt-2">
                    <div className="card-header">
                        <b>Sub Performance</b>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="col-lg-12 mb-2">
                                <div className="row">
                                    <div className="col-lg-4 form-group">
                                        <label>Sub Performance </label>

                                        <input
                                            name={name.name}
                                            onChange={this._onChange}
                                            value={name.value}
                                            type="text"
                                            className={name.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter name"
                                            disabled={this.props.performanceDetail ? true : false}
                                        />
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Start Date </label>

                                        <input
                                            name={startDate.name}
                                            onChange={this._onChange}
                                            value={startDate.value}
                                            type="date"
                                            className={startDate.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter start date"
                                            disabled={this.props.performanceDetail ? true : false}
                                        />
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>End Date</label>
                                        <input
                                            name={endDate.name}
                                            onChange={this._onChange}
                                            value={endDate.value}
                                            type="date"
                                            className={endDate.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter end date"
                                            disabled={this.props.performanceDetail ? true : false}
                                        />

                                    </div>

                                    <div className="col-lg-4 form-group">
                                        <label>Appraisal Year Cycle</label>
                                        {this.renderSelect(yearCycleCode, yearCycleList)}
                                    </div>

                                    <div className="col-lg-4 form-group">
                                        <label>Status</label>
                                        {this.renderDepartmentSelect(status, statusList)}
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Remark</label>
                                        <input
                                            name={remark.name}
                                            onChange={this._onChange}
                                            value={remark.value}
                                            type="text"
                                            className={remark.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="Enter remark" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 ">
                                <button onClick={this._onSubmit} className="col-lg-2 btn button-full pull-right">Submit</button>
                                <Link to={CONSTANT.url.performanceList} className="col-lg-2 btn  btn-secondary mr-3 pull-right">Cancel</Link>

                            </div>
                        </form>
                    </div>
                </div>

            </React.Fragment>

        )
    }

    private _onChange = (e: any, callback?: any) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: { ...this.state[name], value } }, callback);
    }

    renderDepartmentSelect = (status: any, statusList: any) => {
        return (<select name={status.name} value={status.value}
            onChange={this._onChange} className={status.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {statusList.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.status}>{e.name}</option>)
                })}
            </React.Fragment>
        </select>)
    }

    renderSelect = (status: any, appraisalList: any) => {
        console.log(appraisalList);

        return (
            <select name={status.name} value={status.value}
                onChange={this._onChange} className={status.error.length > 0 ? "form-control is-invalid" : "form-control"}
                disabled={this.props.performanceDetail ? true : false}
            >
                <React.Fragment>
                    <option>--Select--</option>
                    {appraisalList.length > 0 && appraisalList.map((e: any, dIndex: number) => {
                        return e && (<option key={dIndex} value={e.code}>{e.yearCycle}</option>)
                    })}
                </React.Fragment>
            </select>)
    }

    getStatus = () => {
        this.setState({ showLoader: true })
        getAppraisalStatus()
            .then((res: any) => {
                if (res) {
                    this.setState({
                        statusList: { ...this.state.statusList, options: res.result },
                        showLoader: false
                    })
                }
            })
            .catch((err: any) => {
                console.log(err);

            })
    }

    private _onSubmit = (e: any) => {
        e.preventDefault();
        const st = this.state;
        if (validateForm(this, st)) {
            const model = {
                name: st.name.value,
                startDate: st.startDate.value,
                endDate: st.endDate.value,
                yearCycleCode: st.yearCycleCode.value,
                status: st.status.value,
                remark: st.remark.value,
                code: st.code.value,
            };
            this.setState({
                 name:{ ...this.state.name, value:''},
                startDate:{ ...this.state.startDate, value:''},
                endDate:{ ...this.state.endDate, value:''},
                yearCycleCode:{ ...this.state.yearCycleCode, value:''},
                status:{ ...this.state.status, value:''},
                remark:{ ...this.state.remark, value:''},
                statusList:{ ...this.state.statusList, value:''},
                yearCycleList:{ ...this.state.yearCycleList, value:''},
                code:{ ...this.state.code, value:''},
            })
            this.props.onSubmit(model);
        }
    }
}

export default Appraisal;