import * as React from 'react';
import { Link } from 'react-router-dom';
import '../../../../node_modules/react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

import { getBehaviourList, getDepartmentList, getOfficerList } from '../../../action/Master';
import { addEmployee } from '../../../action/admin/Employee';
import { onChange, setOptionsArray, validateForm } from '../../../utils';
import CONSTANT from '../../../constant';

class TransferEmp extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            secondReportingOfficer: { name: 'secondReportingOfficer', value: '', options: [], isRequired: false, error: '' },
            secondReviewingOfficer: { name: 'secondReviewingOfficer', value: '', options: [], isRequired: false, error: '' },
            reviewingOfficer: { name: 'reviewingOfficer', value: '', options: [], isRequired: true, error: '' },
            reportingOfficer: { name: 'reportingOfficer', value: '', options: [], isRequired: true, error: '' },
            designation: { name: 'designation', value: '', options: [], isRequired: true, error: '' },
            approvedBy: { name: 'approvedBy', value: '', options: [], isRequired: true, error: '' },
            department: { name: 'department', value: '', options: [], isRequired: true, error: '' },
            emailId: { name: 'emailId', value: '', options: [], isRequired: true, error: '' },
            mobile: { name: 'mobile', value: '', options: [], isRequired: true, error: '' },
            userType: { name: 'userType', value: '', isRequired: true, error: '' },
            location: { name: 'location', value: '', isRequired: true, error: '' },
            empCode: { name: 'empCode', value: '', isRequired: true, error: '' },
            status: { name: 'status', value: '', isRequired: true, error: '' },
            name: { name: 'name', value: '', isRequired: true, error: '' },
            dualReporting: { name: 'dualReporting', value: false },
            rof1Check: { name: 'rof1Check', value: false },
            rof2Check: { name: 'rof2Check', value: true },

        }
    }

    componentDidMount() {
        this.loadList()
    }

    public render() {

        const { empCode, name, userType, emailId, mobile, approvedBy, dualReporting, rof1Check, rof2Check, designation, secondReviewingOfficer, department, location, reportingOfficer, secondReportingOfficer, reviewingOfficer, status, } = this.state;
        return (
            <React.Fragment>
                <div className="fluid-container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="col-lg-12 mt-4">
                                <div className="row">
                                    <div className="col-lg-6 pl-0">
                                        <h5 className="heading-h1">Add Employee</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card mt-2">
                                <div className="card-header">
                                    <b>Employee Detail
                                           </b>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="col-lg-12 mb-2">
                                            <div className="row">
                                                <div className="col-lg-3 form-group">
                                                    <label > Employee Code </label>
                                                    <input

                                                        name={empCode.name}
                                                        onChange={this.onChange}
                                                        value={empCode.value}
                                                        type="text"
                                                        className={empCode.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                        placeholder="Enter Employee Code" />
                                                </div>
                                                <div className="col-lg-3 form-group">
                                                    <label >Name</label>
                                                    <input
                                                        name={name.name}
                                                        onChange={this.onChange}
                                                        value={name.value}
                                                        type="text"
                                                        className={name.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                        placeholder="Enter Name" />
                                                </div>
                                                <div className="col-lg-3 form-group">
                                                    <label >Email Id</label>
                                                    <input
                                                        name={emailId.name}
                                                        onChange={this.onChange}
                                                        value={emailId.value}
                                                        type="text"
                                                        className={emailId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                        placeholder="Enter Email Id" />
                                                </div>
                                                <div className="col-lg-3 form-group">
                                                    <label >Mobile No</label>
                                                    <input
                                                        name={mobile.name}
                                                        onChange={this.onChange}
                                                        value={mobile.value}
                                                        type="text"
                                                        className={mobile.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                        placeholder="Enter Mobile No" />
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3 form-group">
                                                    <label >Designation</label>
                                                    <Typeahead
                                                        id="ta-designation_name"
                                                        multiple={false}
                                                        checked={this.filterByCallback}
                                                        placeholder="Select Designation "
                                                        name={designation.name}
                                                        minLength={0}
                                                        options={designation.options}
                                                        onChange={(e: any) => this._typeaheadOnChange(designation.name, e)}
                                                        selected={designation.value}
                                                        labelKey="name"
                                                        isInvalid={designation.error.length > 0}
                                                    />
                                                </div>
                                                <div className="col-lg-3 form-group">
                                                    <label >Department</label>
                                                    <Typeahead
                                                        id="ta-department_name"
                                                        multiple={false}
                                                        checked={this.filterByCallback}
                                                        placeholder="Select Department "
                                                        name={department.name}
                                                        minLength={0}
                                                        options={department.options}
                                                        onChange={(e: any) => this._typeaheadOnChange(department.name, e)}
                                                        selected={department.value}
                                                        labelKey="name"
                                                        isInvalid={department.error.length > 0}
                                                    />
                                                </div>
                                                <div className="col-lg-3 form-group">
                                                    <label >Location</label>
                                                    <input
                                                        name={location.name}
                                                        onChange={this.onChange}
                                                        value={location.value}
                                                        type="text"
                                                        className={location.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                        placeholder="Enter Location" />
                                                </div>
                                                <div className="col-lg-3 form-group">
                                                    <label >Status</label>
                                                    <select
                                                        name={status.name}
                                                        value={status.value}
                                                        onChange={this.onChange}
                                                        className={status.error.length > 0 ? "form-control is-invalid" : "form-control"}

                                                    >
                                                        <React.Fragment>
                                                            <option >--Select--</option>
                                                            <option value="Active">Active</option>
                                                            <option value="Inactive">Inactive</option>
                                                        </React.Fragment>

                                                    </select>
                                                </div>

                                                <div className="col-lg-3 form-group">
                                                    <label>User Type</label>
                                                    <select
                                                        name={userType.name}
                                                        value={userType.value}
                                                        onChange={this.onChange}
                                                        className={userType.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                                    >
                                                        <React.Fragment>
                                                            <option >--Select--</option>
                                                            <option value="manager">Manager</option>
                                                            <option value="user">User</option>
                                                        </React.Fragment>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card mt-2">
                                <div className="card-header">
                                    <b>Transfer
                                           </b>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="col-lg-12 mb-2">
                                            <div className="row">
                                                <div className="col-lg-3 form-group">
                                                    <label >Reporting Officer </label>
                                                    <Typeahead
                                                        id="ta-reporting_manager"
                                                        multiple={false}
                                                        checked={this.filterByCallback}
                                                        placeholder="Select Reporting Officer"
                                                        name={reportingOfficer.name}
                                                        minLength={0}
                                                        options={reportingOfficer.options}
                                                        onChange={(e: any) => this._typeaheadOnChange(reportingOfficer.name, e)}
                                                        selected={reportingOfficer.value}
                                                        labelKey="name"
                                                        isInvalid={reportingOfficer.error.length > 0}
                                                    />
                                                </div>
                                                {rof1Check.value === false &&
                                                    <div className="col-lg-3 form-group">
                                                        <label >Reviewing Officer </label>
                                                        <Typeahead
                                                            id="ta-reviewing_manager"
                                                            multiple={false}
                                                            checked={this.filterByCallback}
                                                            placeholder="Select Reviewing Officer"
                                                            name={reviewingOfficer.name}
                                                            minLength={0}
                                                            options={reviewingOfficer.options}
                                                            onChange={(e: any) => this._typeaheadOnChange(reviewingOfficer.name, e)}
                                                            selected={reviewingOfficer.value}
                                                            labelKey="name"
                                                            isInvalid={reviewingOfficer.error.length > 0}
                                                        />
                                                    </div>
                                                }
                                                <div className="col-lg-3 form-group">
                                                    <label >Goal Approved By </label>
                                                    <Typeahead
                                                        id="ta-reviewing_manager"
                                                        multiple={false}
                                                        placeholder="Select  Officer"
                                                        name={approvedBy.name}
                                                        minLength={0}
                                                        options={approvedBy.options}
                                                        onChange={(e: any) => this._typeaheadOnChange(approvedBy.name, e)}
                                                        selected={approvedBy.value}
                                                        labelKey="name"
                                                        isInvalid={approvedBy.error.length > 0}
                                                    />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 form-group">
                                                    <div className="form-check mt-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name={rof1Check.name}
                                                            checked={rof1Check.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <label className="form-check-label"> Reporting Manager same as Reviewing Manager </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 form-group">
                                                    <div className="form-check mt-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name={dualReporting.name}
                                                            checked={dualReporting.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <label className="form-check-label">Dual Reporting </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {dualReporting.value && < div className="row">
                                                <div className="col-lg-3 form-group">
                                                    <label >Second Reporting Officer </label>
                                                    <Typeahead
                                                        id="ta-second_reporting_manager"
                                                        multiple={false}
                                                        checked={this.filterByCallback}
                                                        placeholder="Select Second Reporting Officer"
                                                        name={secondReportingOfficer.name}
                                                        minLength={0}
                                                        options={secondReportingOfficer.options}
                                                        onChange={(e: any) => this._typeaheadOnChange(secondReportingOfficer.name, e)}
                                                        selected={secondReportingOfficer.value}
                                                        labelKey="name"
                                                        isInvalid={secondReportingOfficer.error.length > 0}
                                                    />
                                                </div>
                                                {rof2Check.value == false &&
                                                    <div className="col-lg-3 form-group">
                                                        <label >Second Reviewing Officer </label>
                                                        <Typeahead
                                                            id="ta-reviewing_manager"
                                                            multiple={false}
                                                            checked={this.filterByCallback}
                                                            placeholder="Select Reviewing Officer"
                                                            name={secondReviewingOfficer.name}
                                                            minLength={0}
                                                            options={secondReviewingOfficer.options}
                                                            onChange={(e: any) => this._typeaheadOnChange(secondReviewingOfficer.name, e)}
                                                            selected={secondReviewingOfficer.value}
                                                            labelKey="name"
                                                            isInvalid={secondReviewingOfficer.error.length > 0}
                                                        />
                                                    </div>
                                                }
                                            </div>}

                                            {dualReporting.value && <div className="row">
                                                <div className="col-lg-6 form-group">
                                                    <div className="form-check mt-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name={rof2Check.name}
                                                            checked={rof2Check.value}
                                                            onChange={this.onChange}
                                                        />
                                                        <label className="form-check-label"> Reporting Manager same as Reviewing Manager </label>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-3 mb-4">
                        <div className="col-lg-12 ">
                            <button onClick={this.onSubmit} className="col-lg-2 btn button-full pull-right">Submit </button>
                            <Link to={CONSTANT.url.employeeList} className="col-lg-2 btn  btn-secondary mr-3 pull-right">Cancel</Link>

                        </div>
                    </div>
                </div>

            </React.Fragment >

        )
    }

    private filterByCallback = (option: any, props: any) => (
        option.capital.toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
        option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1
    );

    private _typeaheadOnChange(name: string, e: any) {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        else if (name === this.state.reportingOfficer.name) {
            onChange(this, name, value,
                () => {
                    setOptionsArray(this, this.state.approvedBy.name, value)
                }
            );
        }
        else if (name === this.state.secondReportingOfficer.name) {
            onChange(this, name, value,
                () => {
                    setOptionsArray(this, this.state.approvedBy.name, value)
                }
            );
        }
        else
            onChange(this, name, value);
    }

    private onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === this.state.rof1Check.name) {
            value = e.target.checked
            onChange(this, name, value);
            onChange(this, this.state.reviewingOfficer.name, this.state.reportingOfficer.value);
            onChange(this, this.state.approvedBy.name, this.state.reportingOfficer.value);
        }
        else if (name === this.state.rof2Check.name) {
            value = e.target.checked
            onChange(this, name, value);
            onChange(this, this.state.secondReviewingOfficer.name, this.state.secondReportingOfficer.value);
        }
        else if (name === this.state.dualReporting.name) {
            value = e.target.checked
            onChange(this, name, value);
        }
        else
            onChange(this, name, value);
    }


    loadList = () => {

        getBehaviourList().then((res: any) => {
            if (res && res.result)
                this.setState({
                    designation: { ...this.state.designation, options: res.result }
                })
        })

        getDepartmentList().then((res: any) => {
            if (res && res.result)
                this.setState({
                    department: { ...this.state.department, options: res.result }
                })
        })

        getOfficerList().then((res: any) => {
            if (res && res.result)
                this.setState({
                    reportingOfficer: { ...this.state.reportingOfficer, options: res.result },
                    secondReportingOfficer: { ...this.state.secondReportingOfficer, options: res.result },
                    reviewingOfficer: { ...this.state.reviewingOfficer, options: res.result },
                    secondReviewingOfficer: { ...this.state.secondReviewingOfficer, options: res.result }
                })
        })
    }

    onSubmit = (e: any) => {
        e.preventDefault()
        const { secondReportingOfficer, secondReviewingOfficer, emailId, mobile, reviewingOfficer, reportingOfficer, designation, approvedBy, department, location, empCode, status, userType, name, } = this.state;
        const model = {
            secondReportingOfficer: secondReportingOfficer.value.length ? secondReportingOfficer.value[0].empCode : null,
            secondReviewingOfficer: secondReviewingOfficer.value.length ? secondReviewingOfficer.value[0].empCode : null,
            reviewingOfficer: reviewingOfficer.value.length ? reviewingOfficer.value[0].empCode : null,
            reportingOfficer: reportingOfficer.value.length ? reportingOfficer.value[0].empCode : null,
            designation: designation.value.length ? designation.value[0].name : null,
            approvedBy: approvedBy.value.length ? approvedBy.value : [],
            department: department.value.length ? department.value[0].name : null,
            location: location.value,
            userType: userType.value,
            emailId: emailId.value,
            empCode: empCode.value,
            status: status.value,
            mobile: mobile.value,
            name: name.value,
        }
        console.log(model);

        if (validateForm(this, { reviewingOfficer, reportingOfficer, mobile, emailId, designation, approvedBy, department, location, empCode, status, userType, name, }))
            addEmployee(model)
                .then((res: any) => {
                    if (res) {
                        alert('Record Update Successfully')
                        this.props.history.push(CONSTANT.url.employeeList)
                    }
                })
    }






}

export default TransferEmp;