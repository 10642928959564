import * as React from 'react';
import { Link } from 'react-router-dom';

import { getAppraisalStatus } from '../../action/admin/Appraisal';
import { validateForm, onChange } from '../../utils';
import CONSTANT from '../../constant'

class Band extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const bandDetail = props.bandDetail;
        this.state = {
            appraisalCycle: { value: bandDetail ? bandDetail.appraisalCycle.code: '', name: 'appraisalCycle', error: '', isRequired: true },
            fromMark: { value: bandDetail ? bandDetail.fromMark : '', name: 'fromMark', error: '', isRequired: true },
            toMark: { value: bandDetail ? bandDetail.toMark : '', name: 'toMark', error: '', isRequired: true },
            grade: { value: bandDetail ? bandDetail.grade : '', name: 'grade', error: '', isRequired: true },

            yearCycleList: props.appraisalList ? props.appraisalList : [],
            code: { name: 'code', value: bandDetail ? bandDetail.code : '' }
        }
    }


    public render() {
        const { fromMark, toMark, grade, yearCycleList, appraisalCycle

        } = this.state;
        return (
            <React.Fragment>

                <div className="card mt-2">
                    <div className="card-header">
                        <b>Band</b>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="col-lg-12 form-group">
                                <div className="row">
                                    <div className="col-lg-6 form-group">
                                        <label className="mt-2">
                                            <b>Choose Appraisal Cycle</b>
                                        </label>
                                        {this.renderSelect(appraisalCycle, yearCycleList)}
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label className="mt-2">
                                            <b>Grade</b>
                                        </label>
                                        <select
                                            name={grade.name}
                                            value={grade.value}
                                            className={grade.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                            onChange={this._onChange}
                                        >
                                            <option >--Select--</option>
                                            <option value="Out Standing">Out Standing</option>
                                            <option value="Very Good">Very Good</option>
                                            <option value="Good">Good</option>
                                            <option value="Satisfactory">Satisfactory</option>
                                            <option value="UnSatisfactory">UnSatisfactory</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label className="mt-2">
                                            <b>From</b>
                                        </label>
                                        <input
                                            name={fromMark.name}
                                            onChange={this._onChange}
                                            value={fromMark.value}
                                            type="text"
                                            className={fromMark.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder="30 %" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label className="mt-2">
                                            <b>To</b>
                                        </label>
                                        <input
                                            name={toMark.name}
                                            onChange={this._onChange}
                                            value={toMark.value}
                                            type="text"
                                            className={toMark.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                            placeholder=" 50 %" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 ">
                                <button onClick={this.onSubmit} className="col-lg-2 btn button-full pull-right">Submit</button>
                                <Link to={CONSTANT.url.bandList} className="col-lg-2 btn  btn-secondary mr-3 pull-right">Cancel</Link>

                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment >

        )
    }

    private _onChange = (e: any, callback?: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value)
    }

    renderSelect = (status: any, appraisalList: any) => {

        return (<select name={status.name} value={status.value.code}
            onChange={this._onChange} className={status.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {appraisalList.length > 0 && appraisalList.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.code}>{e.yearCycle}</option>)
                })}
            </React.Fragment>
        </select>)
    }



    private onSubmit = (e: any) => {
        e.preventDefault();
        {
            const st = this.state;
            const model = {
                code: st.code.value,
                appraisalCycle: this.props.appraisalList.filter((e: any) => e.code === st.appraisalCycle.value).map((e: any) => ({ code: e.code, yearCycle: e.yearCycle }))[0],
                fromMark: st.fromMark.value,
                toMark: st.toMark.value,
                grade: st.grade.value,

            };
            if (validateForm(this, st))
                this.props.onSubmit(model);
        }
    }

}

export default Band;