import * as axios from 'axios';


/**  |||||||||||||||||||||||||||||||||||   ADD  |||||||||||||||||||||||||||||||||||||||||||||||||*/ 

export const addDepartment: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/department`;
    return axios.post(url, reqObj)
    .then((response: any) => {
        if (response)
            if (response.data.status)
                return response.data;
            else
                alert(response.data.error)
    })
    .catch((response: any) => {
        console.log('error', response);
        alert('NetWork Error')
    });

}

/**  |||||||||||||||||||||||||||||||||||   UPDATE  |||||||||||||||||||||||||||||||||||||||||||||||||*/ 


export const updateDepartment: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/department`;
    return axios.put(url, reqObj,)
    .then((response: any) => {
        if (response)
            if (response.data.status)
                return response.data;
            else
                alert(response.data.error)
    })
    .catch((response: any) => {
        console.log('error', response);
        alert('NetWork Error')
    });

}

/**  |||||||||||||||||||||||||||||||||||   GET  |||||||||||||||||||||||||||||||||||||||||||||||||*/ 


export const getDepartment : any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/admin/department?code=${reqObj}`;
    return axios.get(url, reqObj)
    .then((response: any) => {
        if (response)
            if (response.data.status)
                return response.data;
            else
                alert(response.data.error)
    })
    .catch((response: any) => {
        console.log('error', response);
        alert('NetWork Error')
    });

}