
import * as axios from 'axios';
import { getAuthHeader } from '../utils';

export const getDashboard = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/dashboard?yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getReportByAppraisalCycle = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/report-appraisal-cycle?yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}


export const getEmpReportByAppraisalCycle = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/emp/report-appraisal-cycle?yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

/***Add By Jitender** */
export const getPendingReportByAppraisalCycle = (reqObj: any) => {
    debugger
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/Pendingreport-appraisal-cycle?yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}
/***End*** */

export const getEmpDashboard = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/emp/dashboard?yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getrDashboard = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/goalDataReport?yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}&reportcode=${reqObj.reportcode}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}


export const getEmployeeAndAppraisal: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/employee-apraisal`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getReportingManagerDetailsOfEmp: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/employee-managers?yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getAppraisalCycle: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/apraisal-cycle`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getEmpList: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/rating-report-Emplist/pdf`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}
// getEmpGrade
export const getEmpGrade: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/rating-report-grade/pdf`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}
export const getAppraiseEmployee: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/apraise-employee?yearCycle=${reqObj.yearCycle}&monthCycle=${reqObj.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {

            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getSkillsList: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/skills-list`;
    return axios.post( url, {department:reqObj} ,  getAuthHeader() )
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getCompetenciesList: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/competencies-list?designation=${reqObj}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getBehaviourList: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/behaviour-list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getDepartmentList: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/department-list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getOfficerList: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/officer-list`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const gradeList: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/grades`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getRepotie: any = (reqObj: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/repotie`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')
        });
}

export const getWeightage: any = (designation: string, yearCycle: string, monthCycle: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/Weightage?designation=${designation}&yearCycle=${yearCycle}&monthCycle=${monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}
export const getGoalHistory: any = (reqObj?: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/master/calculations?yearCycle=${reqObj}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
        });
}

export const getReport: any = (q: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/report/pdf?yearCycle=${q.yearCycle}&monthCycle=${q.monthCycle}&designation=${q.designation}&department=${q.department}&empCode=${q.empCode}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const getRatingReport: any = (q: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/rating-report/pdf?yearCycle=${q.yearCycle}&monthCycle=${q.monthCycle}&designation=${q.designation}&department=${q.department}&empCode=${q.empCode}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}
export const userDetailSuperReports: any = (q: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/super-report/xl?yearCycle=${q.yearCycle}&monthCycle=${q.monthCycle}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}

export const empDetailSuperReports: any = (q: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/super-report/xl?yearCycle=${q.yearCycle}&monthCycle=${q.monthCycle}&code=${q.code}`;
    return axios.get(url, getAuthHeader())
        .then((response: any) => {
            if (response)
                if (response.data.status)
                    return response.data;
                else
                    alert(response.data.error)
        })
        .catch((response: any) => {
            console.log('error', response);
            alert('NetWork Error')

        });
}