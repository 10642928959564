import * as React from 'react';
import { Link } from 'react-router-dom';

import complete from '../../assets/images/menu-icon2.png';
import total from '../../assets/images/home-icon1.png';
import app from '../../assets/images/home-icon2.png';
import add from '../../assets/images/home-icon4.png';
import Loader from '../../component/common/Loader';
import { getDashboard, getAppraisalCycle, getrDashboard, getEmployeeAndAppraisal, getReportByAppraisalCycle } from '../../action/Master'
import CONSTANT from '../../constant';
import { onChange } from '../../utils';

class Dashboard extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            yearCycle: { value: '', name: 'yearCycle', options: [], error: '', isRequired: true },
            monthCycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            goalduecycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            list: [],
            showLoader: false,
            statusList: { value: '', name: 'statusList', error: '', options: [{ code: 0, name: '---Select---' }, { code: 1, name: 'Set Goal Due Report' }, { code: 2, name: 'Goal Approval Due Report.' }, { code: 3, name: 'Self-Appraisal Due Report' }, { code: 4, name: 'Appraisal Due Report' }, { code: 5, name: 'Final Approval Due Report' }], isRequired: true },
            rList: [],
            acList: []
        }
    }

    componentWillMount() {
        this.loadList()
    }

    public render() {
        const { list, yearCycle, monthCycle, showLoader, statusList, rList, acList } = this.state;
        return (
            <React.Fragment>

                <div className="col-lg-12 main-container">
                    <div className="fluid-container">
                        <div className="card mt-2">
                            <div className="card-header">
                                <b>Filter</b>
                                <div className="col-lg-6 pull-right">
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    <form>
                                        <div className="col-lg-12 mb-2">
                                            <div className="row">
                                                <div className="col-lg-6 form-group">
                                                    <label > Appraisal Cycle  </label>
                                                    {this.renderSelect(yearCycle, false)}
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                }
                            </div>
                        </div>
                        <div className="list-container">
                            <table className="table table-bordered table-hover">
                                <tbody>
                                    <tr>
                                        {/* <th scope="col">Assessment Year</th> */}
                                        <th scope="col">Quarter</th>
                                        <th scope="col">Goal Setting</th>
                                        <th scope="col">Goal approved</th>
                                        <th scope="col">Self Appraisal</th>
                                        <th scope="col">Appraisal by Reporting Officer</th>
                                        <th scope="col">Appraisal by ReViewing Officer</th>
                                        <th scope="col">Completed </th>
                                        <th scope="col">Total </th>
                                    </tr>
                                    {
                                        acList === undefined && <tr>
                                            <td colSpan={10} className="text-center">No records found</td>
                                        </tr>
                                    }
                                    {
                                        acList !== undefined && acList.length === 0 && <tr>
                                            <td colSpan={10} className="text-center">No records found</td>
                                        </tr>
                                    }
                                    {
                                        acList !== undefined && acList.map((item: any, index: number) => {
                                            return (
                                                <tr>
                                                    {/* <th>{this.renderSelect(monthCycle, true)}</th> */}
                                                    <th>{item.name ? item.name : 0}</th>
                                                    <th>{item.noOfGoals ? item.noOfGoals : 0}</th>
                                                    <th>{item.approvedGoals ? item.approvedGoals : 0}</th>
                                                    <th>{item.selfAppraisal ? item.selfAppraisal : 0}</th>
                                                    <th>{item.appraisalByReportingOfficer ? item.appraisalByReportingOfficer : 0}</th>
                                                    <th>{item.appraisalByReviewingOfficer ? item.appraisalByReviewingOfficer : 0}</th>
                                                    <th>{item.completed ? item.completed : 0}</th>
                                                    <th>{item.total ? item.total : 0}</th>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* ...................................... Second report implementation ....................... */}
                        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Due Report </p>
                        <div className="list-container">
                            <div className="col-lg-12 mb-2">
                                <div className="row">
                                    <div className="col-lg-6 form-group">
                                        <label > Appraisal Cycle  </label>
                                        {this.renderSelect(monthCycle, true)}
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label > Report</label>
                                        {this.renderSelect(statusList, true)}
                                    </div>
                                </div>
                            </div>
                            <table className="table table-bordered table-hover">
                                <tbody>
                                    <tr>
                                        <th scope="col">Sl No</th>
                                        <th scope="col">EMP Code</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Department</th>
                                        <th scope="col">Designation</th>
                                        <th scope="col">Email ID </th>
                                        <th scope="col">Reporting Officer </th>
                                        {/* <th scope="col">Goal Status</th> */}
                                    </tr>
                                    {
                                        rList === undefined && <tr>
                                            <td colSpan={10} className="text-center">No records found</td>
                                        </tr>
                                    }
                                    {
                                        rList !== undefined && rList.length === 0 && <tr>
                                            <td colSpan={10} className="text-center">No records found</td>
                                        </tr>
                                    }
                                    {
                                        rList !== undefined && rList.map((item: any, index: number) => {
                                            return (
                                                <tr>
                                                    <th>{index + 1}</th>
                                                    <th>{item.empCode}</th>
                                                    <th>{item.name}</th>
                                                    <th>{item.department}</th>
                                                    <th>{item.designation}</th>
                                                    <th>{item.emailId}</th>
                                                    {/* <th>{item.remarks}</th> */}
                                                    <th>{item.reportingOfficer[0].name ? item.reportingOfficer[0].name : item.reportingOfficer}</th>
                                                    {/* <th>{item.goal}</th> */}
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* ................................................................................................. */}
                    </div>
                </div>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    private _setError(name: string, error: string) {
        this.setState({ [name]: { ...this.state[name], error } });
    }


    renderSelect = (select: any, f: boolean) => {

        return (
            <select name={select.name}
                value={select.value}
                onChange={this._onChange}
                className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
                <React.Fragment>
                    {/* <option>--Select--</option> */}
                    {select.options.length && select.options.map((e: any, dIndex: number) => {
                        return e && (<option key={dIndex} value={e.code}>{f ? e.name : e.yearCycle}</option>)
                    })}
                </React.Fragment>
            </select>)
    }

    // private renderStatus = (status: any, statusList: any) => {
    //         return (<select name={status.name} value={status.value}
    //             onChange={this._onChange} className={status.error.length > 0 ? "form-control is-invalid" : "form-control"}>
    //             <React.Fragment>
    //                 <option>--Select--</option>
    //                 {statusList.options.map((e: any, dIndex: number) => {
    //                     return e && (<option key={dIndex} value={e.status}>{e.name}</option>)
    //                 })}
    //             </React.Fragment>
    //         </select>)
    //     }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log("value", value, "name", name);

        onChange(this, name, value, () => {
            if (name === this.state.yearCycle.name) {
                let mnth = value && this.state.yearCycle.options.filter((res: any) => { return res.code == value; }).map((res: any) => res.performance)
                mnth = mnth.length && mnth[0].sort((a: any, b: any,) => b.createdOn - a.createdOn);
                this.setState({
                    monthCycle: { ...this.state.monthCycle, options: mnth },
                }, () => {
                    this.loadReportByAppraisalCycle();
                    this.loadEmployee();

                })
            }
            if (name == this.state.monthCycle.name) {
                this.loadEmployee()
            } if (name == this.state.statusList.name) {
                this.loadReportData()
            }
        })

    }

    private loadList = () => {
        getAppraisalCycle()
            .then((res: any) => {
                if (res && res.result && res.result.length)
                    this.setState({
                        yearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[0].code },
                        monthCycle: { ...this.state.monthCycle, options: res.result[0].performance.length > 0 ? res.result[0].performance : [], value: res.result[0].performance.length > 0 ? res.result[0].performance[0].code : '' },
                    }, () => {
                        // this.loadEmployee();
                        this.loadReportByAppraisalCycle();
                    });
            });
    }

    private loadReportByAppraisalCycle = () => {

        const { yearCycle, monthCycle, statusList } = this.state;
        const model = {
            yearCycle: yearCycle.value,
            monthCycle: monthCycle.value,
        }
        getReportByAppraisalCycle(model).then((res: any) => {

            this.setState({ acList: res.result });
        })
    }


    loadEmployee = () => {
        this.setState({ showLoader: true })
        const { yearCycle, monthCycle, statusList } = this.state;
        const model = {
            yearCycle: yearCycle.value,
            monthCycle: monthCycle.value,

        }
        const rmodel = {
            reportcode: statusList.value,
            yearCycle: yearCycle.value,
            monthCycle: monthCycle.value,
        }
        getDashboard(model)
            .then((res: any) => {
                this.setState({ showLoader: false })
                if (res)
                    this.setState({ list: [res.result] })

            })

    }

    // -----------------------------------------------------
    loadReportData = () => {
        this.setState({ showLoader: true })
        const { yearCycle, statusList, monthCycle } = this.state;
        const model = {
            reportcode: statusList.value,
            yearCycle: yearCycle.value,
            monthCycle: monthCycle.value,
        }
        getrDashboard(model)
            .then((res: any) => {
                this.setState({ showLoader: false })
                if (res)
                    this.setState({ rList: res.result.setgoalDueReport })
                console.log("test", res.result.setgoalDueReport);

            })

    }


}

export default Dashboard;