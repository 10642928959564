import * as React from 'react';

import { addBehaviour } from '../../../action/admin/Behaviour';
import Loader from '../../../component/common/Loader';
import Behaviour from '../../../component/Behaviour';
import { validateForm } from '../../../utils';
import CONSTANT from '../../../constant';

class AddBehaviour extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showLoader: false
        }
    }

    public render() {
        const { showLoader } = this.state
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">Add Behaviour Skills</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <Behaviour
                                        onSubmit={this.onSubmit}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>

        )
    }

    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
      
        if (validateForm(this, model)) {
            addBehaviour(model)
                .then((res: any) => {
                    alert('Record Update Successfully')
                    this.setState({ showLoader: false });
                    this.props.history.push(CONSTANT.url.behaviourList)
                })
        }
    }

}

export default AddBehaviour;