import uniqId from 'uniqid'
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';

import { getAppraisalCycle, getCompetenciesList, getSkillsList, gradeList, getRepotie } from '../../../action/Master';
import { validateForm, onChange, onArrayChange, isEmpty, getCookie } from '../../../utils';
import { getGoalReview, updateGoalMarks } from '../../../action/employee/Goal';
import ModalWindow from '../../../component/common/ModalWindow';
import CONSTANT from '../../../constant';
import queryString from 'query-string';
import { Console } from 'console';


class Review extends React.Component<any, any> {
     
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            updated: false,
            type: 'behaviour',
            user: undefined,
            designation: undefined,
            repOff: undefined,
            revOff: undefined,
            secRepOff: undefined,
            secRevOff: undefined,
            monthCycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            yearCycle: { value: '', name: 'yearCycle', options: [], error: '', isRequired: true },
            department: { value: [], name: 'department', error: '', isRequired: true },
            selfAppraisal: { value: false, name: 'selfAppraisal', error: '', isRequired: true },
            behaviour: { value: [], name: 'behaviour', error: '', isRequired: true },
            activity: { value: [], name: 'activity', error: '', isRequired: true },
            userCode: { value: [], name: 'userCode', error: '', isRequired: true },
            comments: { value: '', name: 'comments', error: '', isRequired: true },
            checkAggry: { value: false, name: 'checkAggry', error: '', isRequired: true },
            check: { value: false, name: 'check', error: '', isRequired: true },
            code: { value: [] , name: 'code', error: '', isRequired: true },

            name: { value: [], name: 'name', error: '', boptions: [], doptions: [], aoptions: [], isRequired: true },
            remarks: { value: [], name: 'remarks', error: '', isRequired: true },
            remarksm: { value: [], name: 'remarksm', error: '', isRequired: true },
            remarksr: { value: [], name: 'remarksr', error: '', isRequired: true },
            pfaByRm: { value: [], name: 'pfaByRm', options: [], error: '', isRequired: true },
            pfaByEmp: { value: [], name: 'pfaByEmp', options: [], error: '', isRequired: true },
            pfaByMn: { value: [], name: 'pfaByMn', options: [], error: '', isRequired: true },
            weightage: { value: '', name: 'weightage', options: [], error: '', isRequired: true },
            userId: JSON.parse(window.atob(getCookie('token').split('.')[1])).code,
            same: queryString.parse(this.props.location.search).same,
            updateedCode: undefined,
            repotie: [],
            edit: false


        }

    }

    componentDidMount() {
        const { designation, department, } = JSON.parse(window.atob(getCookie('token').split('.')[1]));

        this.loadList(designation, department)
    }




    public render() {
        const { user, designation, updated, same, edit, showModal, pfaByEmp, pfaByMn, checkAggry, check, userId, weightage, remarksm, remarksr, remarks, pfaByRm, repotie, type, selfAppraisal,
             comments, behaviour, department, activity, name, repOff, revOff, secRepOff, secRevOff } = this.state;

             let indexToShow = (userId == repOff || userId == revOff) ? 0 : 1

        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">Start Appraisal / Review</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>  Appraisal / Review
                                            </b>
                                        </div>

                                        <div className="card-body">
                                            <p>  <span style={{ fontWeight: 'bold' }}>Employee Code :</span>&nbsp;&nbsp;<span>{user && user.empCode}</span></p>
                                            <p>  <span style={{ fontWeight: 'bold' }}>Employee Name :</span>&nbsp;&nbsp;<span>{user && user.name}</span></p>
                                            <p><span style={{ fontWeight: 'bold' }}>Employee Designation :</span>&nbsp;&nbsp;<span>{designation && designation}</span></p>
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="md-widthh2"> Behaviour</th>
                                                            <th scope="col" className="md-widthh2">Weightage</th>
                                                            <th scope="col" className="md-widthh2">Percentage Of Achievement By Employee (Self Rating)</th>
                                                            <th scope="col" className="md-widthh2">Percentage Of Achievement By Reporting Manager</th>
                                                            <th scope="col" className="md-widthh2">Percentage Of Achievement By Reviewing Manager</th>
                                                            <th scope="col" className="md-widthh2">Edit</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {behaviour.error.length > 0 && <tr>
                                                            <td className="text-center" colSpan={7}><span className="text-danger"> Please selecet minimum 2 behaviour</span> </td>
                                                        </tr>}
                                                        {
                                                            behaviour.value.length > 0 && behaviour.value.map((item: any, ind: any) => {
                                                                console.log("item", item);

                                                                let pfaByRm = item.pfaByRm.length && item.pfaByRm.filter((m: any) => m.userCode == userId)
                                                                let pfaByMn = item.pfaByMn.length && item.pfaByMn.filter((m: any) => {
                                                                    for (let Mn of repotie) {
                                                                        if (Mn.code === m.userCode || Mn.code !== m.userCode)
                                                                            return true
                                                                    }
                                                                })
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td> {item.weightage} </td>
                                                                        <td> {item.pfaByEmp.length && item.pfaByEmp[0].percentage}

                                                                        </td>
                                                                        <td>{item.pfaByMn.length && pfaByMn[0]?.percentage}</td>

                                                                        <td> {item.pfaByRm.length && pfaByRm[0]?.percentage} </td>
                                                                        <th>
                                                                            {edit ? <i className="fa fa-pencil  add-plus"></i> :
                                                                                <i className="fa fa-pencil  add-plus" onClick={() => this.onEdit(ind, item, behaviour.name,)}></i>
                                                                            }
                                                                        </th>

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="md-widthh2"> Skills</th>
                                                            <th scope="col" className="md-widthh2">Weightage</th>
                                                            <th scope="col" className="md-widthh2">Percentage Of Achievement By Employee (Self Rating)</th>
                                                            <th scope="col" className="md-widthh2">Percentage Of Achievement By Reporting Manager</th>
                                                            <th scope="col" className="md-widthh2">Percentage Of Achievement By Reviewing Manager</th>
                                                            <th scope="col" className="md-widthh2">Edit</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {department.error.length > 0 && <tr>
                                                            <td className="text-center" colSpan={7}><span className="text-danger"> Please selecet minimum 2 department</span> </td>
                                                        </tr>}
                                                        {console.log("shubham1", department.value)}
                                                        {
                                                            department.value.length > 0 && department.value.map((item: any, ind: any) => {
                                                                let pfaByRm = item.pfaByRm.length && item.pfaByRm.filter((m: any) => m.userCode == userId)
                                                                let pfaByMn = item.pfaByMn.length && item.pfaByMn.filter((m: any) => {
                                                                    for (let Mn of repotie) {
                                                                        if (Mn.code === m.userCode || Mn.code !== m.userCode)
                                                                            return true
                                                                    }
                                                                })
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td> {item.weightage} </td>
                                                                        <td> {item.pfaByEmp.length && item.pfaByEmp[0].percentage}
                                                                        </td>
                                                                        <td> {item.pfaByMn.length && pfaByMn[0]?.percentage} </td>
                                                                        <td> {item.pfaByRm.length && pfaByRm[0]?.percentage} </td>
                                                                        <th>
                                                                            {edit ? <i className="fa fa-pencil  add-plus"></i> :
                                                                                <i className="fa fa-pencil  add-plus" onClick={() => this.onEdit(ind, item, department.name)}></i>
                                                                            }
                                                                        </th>

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="md-width2">Activities</th>
                                                            <th scope="col" className="md-width2">Weightage</th>
                                                            <th scope="col" className="md-width1">Check if Worked</th>
                                                            <th scope="col" className="md-width2">Percentage Of Achievement By Employee (Self Rating)</th>
                                                            <th scope="col" className="md-width2">Percentage Of Achievement By Reporting Manager</th>
                                                            <th scope="col" className="md-width2">Percentage Of Achievement By Reviewing Manager</th>
                                                            <th scope="col" className="md-width2">Edit</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            activity.value.length > 0 && activity.value.map((item: any, ind: any) => {
                                                                let pfaByRm = item.pfaByRm.length && item.pfaByRm.filter((m: any) => m.userCode == userId)
                                                                let pfaByMn = item.pfaByMn.length && item.pfaByMn.filter((m: any) => {
                                                                    for (let Mn of repotie) {
                                                                        if (Mn.code === m.userCode || Mn.code !== m.userCode)
                                                                            return true
                                                                    }
                                                                })
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td> &nbsp;&nbsp;{item.name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>( {selfAppraisal.value ? (item.new ? 'New' : 'Old') : ''} )</small> </td>
                                                                        <td> {item.weightage} </td>
                                                                        <td>
                                                                            {item.check ? 'Yes' : 'No'}
                                                                        </td>
                                                                        <td> {item.pfaByEmp.length && item.pfaByEmp[0].percentage}

                                                                        </td>

                                                                        <td> {item.pfaByMn.length && pfaByMn[0]?.percentage} </td>

                                                                        {/* <td> {same == "true" ? pfaByRm.length && pfaByRm[0].percentage : pfaByMn.length && pfaByMn[0].percentage} </td> */}
                                                                        <td> {item.pfaByRm.length && pfaByRm[0]?.percentage} </td>
                                                                        <th>
                                                                            {edit ? <i className="fa fa-pencil  add-plus"></i> :
                                                                                <i className="fa fa-pencil  add-plus" onClick={() => this.onEdit(ind, item, activity.name)}></i>}
                                                                        </th>
                                                                    </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Comments </b>
                                        </div>
                                        <div className="card-body">
                                            <form>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-12 form-group">
                                                            <textarea
                                                                name={comments.name}
                                                                onChange={this._onChange}
                                                                value={comments.value}
                                                                className={comments.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                                placeholder="Enter comment" >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div> */}
                                </div>
                                {edit ? <div className="col-lg-12 mt-3 mb-3"><Link to={CONSTANT.url.empAppraiseEmployee} className="col-lg-2 btn btn-secondary mr-3 pull-right"> Cancel </Link></div> :
                                    <div className="col-lg-12 mt-3 mb-3">
                                        <button

                                            disabled={selfAppraisal.value == false ? true : false}
                                            onClick={this.onSubmit}
                                            className="col-lg-2 btn button-full pull-right"
                                        >
                                            Submit
                                        </button>
                                        <Link to={CONSTANT.url.empAppraiseEmployee} className="col-lg-2 btn btn-secondary mr-3 pull-right"> Cancel </Link>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </section>




                {
                    showModal && (
                        <ModalWindow
                            title={type === this.state.department.name ? 'Directorate Specific Skill' : (type === this.state.behaviour.name ? 'Behavioral Skill' : 'Job Responsibilities')}
                            backdrop="static"
                            toggleModal={() => { this.toggleWindow() }}
                            className="modal-lg" >
                            <div>
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div>
                                            <form>
                                                <div className="mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-6 form-group">
                                                            <label> Name  </label>
                                                            <Typeahead
                                                                id="ta-name_name"
                                                                multiple={false}
                                                                allowNew={type == activity.name ? true : false}
                                                                placeholder="Select name"
                                                                name={name.name}
                                                                minLength={0}
                                                                options={type == behaviour.name ? name.boptions : type == department.name ? name.doptions : name.aoptions}
                                                                onChange={(e: any) => this._typeaheadOnChange(name.name, e)}
                                                                selected={name.value}
                                                                isInvalid={name.error.length > 0}
                                                                disabled
                                                                labelKey="name"
                                                            />

                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label>Weightage </label>
                                                            <input
                                                                disabled
                                                                name={weightage.name}
                                                                onChange={this._onChange}
                                                                value={weightage.value}
                                                                type="text"
                                                                className={weightage.error.length ? 'form-control is-invalid' : 'form-control'}
                                                                placeholder="Enter weightage"
                                                            />

                                                        </div>
                                                        <div className="col-lg-12 form-group" style={{ borderTop: "1px solid #e1e1e1" }}>
                                                            <h4 className="text-center">Employees’ Assessment</h4>
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label>Percentage of Achievement By Employee</label>
                                                            <Typeahead
                                                                disabled
                                                                id="ta-name_name"
                                                                multiple={false}
                                                                placeholder="Select Percentage"
                                                                name={pfaByEmp.name}
                                                                minLength={0}
                                                                options={pfaByEmp.options}
                                                                onChange={(e: any) => this._typeaheadOnChange(pfaByEmp.name, e)}
                                                                selected={pfaByEmp.value}
                                                                isInvalid={pfaByEmp.error.length > 0}
                                                                labelKey="percentage"
                                                            />

                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label>Comments of Employees </label>
                                                            <textarea
                                                                disabled
                                                                name={remarks.name}
                                                                onChange={this._onChange}
                                                                value={remarks.value}
                                                                className={remarks.error.length ? 'form-control is-invalid' : 'form-control'}
                                                            />
                                                        </div>
                                                        <div className="col-lg-12 form-group" style={{ borderTop: "1px solid #e1e1e1" }}>
                                                            <h4 className="text-center">Assessment by Reporting Officer</h4>
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            {console.log("pfaByMn", pfaByMn)}
                                                            <label>Percentage Of Achievement By Reporting Officer </label>

                                                            {console.log("item1", pfaByMn.value)}

                                                            <Typeahead
                                                                //disabled
                                                                id="ta-name_name"
                                                                multiple={false}
                                                                placeholder="Select Percentage"
                                                                name={pfaByMn.name}
                                                                minLength={0}
                                                                options={pfaByMn.options}
                                                                onChange={(e: any) => this._typeaheadOnChange(pfaByMn.name, e)}
                                                                //selected={same == "true" ? pfaByMn.value : pfaByMn.value}
                                                                selected={pfaByMn.value}
                                                                isInvalid={pfaByMn.error.length > 0}
                                                                labelKey="percentage"
                                                                disabled={true}
                                                            />

                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label>Comments of Reporting Officer </label>

                                                            {/* <textarea
                                                                name={remarksm.name}
                                                                onChange={this._onChange}
                                                                value={same == "true" ? remarksr.value : remarksm.value}
                                                                className={remarksm.error.length ? 'form-control is-invalid' : 'form-control'}
                                                               /> 
                                                               old one 
                                                               */
                                                            }


                                                            <textarea
                                                                //disabled
                                                                name={remarksr.name}
                                                                onChange={this._onChange}
                                                               // value={remarksr.value}
                                                                //value={same == "true" ? remarksr.value : remarksr.value}
                                                                className={remarksr.error.length ? 'form-control is-invalid' : 'form-control'}
                                                                disabled={true}
                                                            />

                                                        </div>
                                                        <div className="col-lg-12 form-group" style={{ borderTop: "1px solid #e1e1e1" }}>
                                                            <h4 className="text-center">Assessment by Reviewing Officer</h4>
                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label>Percentage Of Achievement Reviewing Officer </label>
                                                            <Typeahead
                                                                id="ta-name_name"
                                                                multiple={false}
                                                                placeholder="Select Percentage"
                                                                name={pfaByRm.name}
                                                                minLength={0}
                                                                options={pfaByRm.options}
                                                                onChange={(e: any) => this._typeaheadOnChange(pfaByRm.name, e)}
                                                                selected={pfaByRm.value}
                                                                //selected={same == "true" ? pfaByMn.value : pfaByRm.value}
                                                                isInvalid={pfaByRm.error.length > 0}
                                                                labelKey="percentage"
                                                            />

                                                        </div>

                                                        <div className="col-lg-6 form-group">
                                                            <label>Comments of Reviewing Officer </label>
                                                            <textarea
                                                                name={remarksr.name}
                                                                onChange={this._onChange}
                                                                value={remarksr.value}
                                                                className={remarksr.error.length ? 'form-control is-invalid' : 'form-control'}
                                                                placeholder="Enter remarks"
                                                            />
                                                             
                                                        </div>
                                                        <div className="col-lg-12">
                                                            {same == 'false' && <div className="col-lg-6 form-check ">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="gridCheck"
                                                                    name={checkAggry.name}
                                                                    checked={checkAggry.value}
                                                                    onChange={this._onChange}
                                                                />
                                                                <label className="form-check-label"> Agree With Reporting Officer Rating</label>
                                                            </div>}

                                                        </div>

                                                        <div className="col-lg-12 ">
                                                            <button disabled={selfAppraisal.value ? false : true} onClick={this.onUpdate} className="col-lg-2 btn button-full pull-right">Submit</button>
                                                            <button onClick={() => { this.toggleWindow() }} className="col-lg-2 btn btn-secondary mr-3 pull-right">Cancel</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ModalWindow>
                    )
                }
            </React.Fragment >

        )
    }

    private toggleWindow = (e?: any,) => {
        const { showModal, } = this.state
        e && this.setState({ type: e })
        this.setState({ showModal: !showModal });
        if (showModal)
            this.setState({
                weightage: { ...this.state.weightage, value: '', error: '' },
            })
    }

    renderSelect = (select: any, f: boolean) => {
        return (<select name={select.name} value={select.value}
            onChange={this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {select.options.length && select.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.code}>{f ? e.startDate : e.yearCycle}</option>)
                })}
            </React.Fragment>
        </select>)
    }

    private onEdit = (i: Number, item: any, type: String,) => {
       
        const { same } = this.state;
        
        //new Changes
        const m = item.pfaByMn ? item.pfaByMn.filter((m: any) => m.userCode == this.state.userId) : []
       
        //new Changes
        const rm = item.pfaByRm.length ? item.pfaByRm.filter((m: any) => m.userCode == this.state.userId) : []
        
        const remarksm = item.pfaByMn.length ? item.pfaByMn.filter((m: any) => {
            if (same == "true")
                return true
            else
                for (let Mn of this.state.repotie) {
                    if (Mn.code === m.userCode)
                        return true
                }
        }) : []
       
        this.setState({
            name: { ...this.state.name, value: [{ code: item.code, name: item.name }] },
            pfaByRm: { ...this.state.pfaByRm, value: rm },
            pfaByEmp: { ...this.state.pfaByEmp, value: item.pfaByEmp },
            remarksr: { ...this.state.remarksr, value: rm.length > 0 ? rm[0].remarks : "" },
            check: { ...this.state.check, value: item.check },
            remarks: { ...this.state.remarks, value: item.remarks },
            updateByName: item.name,
            weightage: { ...this.state.weightage, value: item.weightage },
            checkAggry: { ...this.state.checkAggry, value: false },
            pfaByMn: {
                ...this.state.pfaByMn, value:
                    item.pfaByMn.length ? item.pfaByMn.filter((m: any) => {
                        if (same == "true")
                            return true
                        else
                            for (let Mn of this.state.repotie) {
                                if (Mn.code === m.userCode)
                                    return true
                            }
                    })
                        : ''
            },

            remarksm: { ...this.state.remarksm, value: remarksm.length > 0 ? remarksm[0].remarks : '' },

        }, () => {
            this.toggleWindow(type)
        });


    }

    private onUpdate = (e: any) => {
        debugger
        e.preventDefault()
        const { same } = this.state;
        const { type, userId, activity, behaviour, updateByName, department, remarksr, name, remarks, pfaByRm, }: any = this.state
        if (validateForm(this, { name, pfaByRm, })) {
            if (type == activity.name) {

                const index = activity.value.findIndex((item: any) => item.name === updateByName);
                let pfaByRms = activity.value[index].pfaByRm;
                const index1 = pfaByRms.findIndex((item: any) => item.userCode === userId);
                pfaByRms[index1 ? pfaByRms.length : index1] = { userCode: userId, ...pfaByRm.value[0], remarks: remarksr.value }
                activity.value[index] = { ...activity.value[index], name: name.value[0].name, code: activity.value[index].code, pfaByRm: pfaByRms, pfaByMn: (same == "true" ? pfaByRms : activity.value[index].pfaByMn), remarks: remarks.value, }

                onChange(this, activity.name, activity.value);
            }
            else if (type == behaviour.name) {

                const index = behaviour.value.findIndex((item: any) => item.name === updateByName);
                let pfaByRms = behaviour.value[index].pfaByRm;
                const index1 = pfaByRms.findIndex((item: any) => item.userCode === userId);
                pfaByRms[index1 ? pfaByRms.length : index1] = { userCode: userId, ...pfaByRm.value[0], remarks: remarksr.value }
                behaviour.value[index] = { ...behaviour.value[index], name: name.value[0].name, code: behaviour.value[index].code, pfaByRm: pfaByRms, pfaByMn: (same == "true" ? pfaByRms : behaviour.value[index].pfaByMn), remarks: remarks.value, }

                onChange(this, behaviour.name, behaviour.value);
            }
            else if (type == department.name) {

                const index = department.value.findIndex((item: any) => item.name === updateByName);
                let pfaByRms = department.value[index].pfaByRm;
                const index1 = pfaByRms.findIndex((item: any) => item.userCode === userId);
                pfaByRms[index1 ? pfaByRms.length : index1] = { userCode: userId, ...pfaByRm.value[0], remarks: remarksr.value }
                department.value[index] = { ...department.value[index], name: name.value[0].name, code: department.value[index].code, pfaByRm: pfaByRms, pfaByMn: (same == "true" ? pfaByRms : department.value[index].pfaByMn), remarks: remarks.value, }
                console.log(department.value)
                onChange(this, department.name, department.value);
            }
            this.toggleWindow()
        }
    }

    private _typeaheadOnChange(name: string, e: any) {
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];

        }
        onChange(this, name, value);
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;

        if (name === this.state.checkAggry.name) {
            value = e.target.checked;

            let value1 = this.state.pfaByMn.value.length ? this.state.pfaByMn.value.filter((m: any) => {
                for (let Mn of this.state.repotie) {
                    if (Mn.code === m.userCode)
                        return true
                }
            }) : []
            onChange(this, name, value, () => {
                if (value && value1.length > 0)
                    onChange(this, this.state.pfaByRm.name, [{ ...value1[0], userCode: this.state.userId }])
                else
                    onChange(this, this.state.pfaByRm.name, [])

            })
        }
        else
            onChange(this, name, value, () => {
                if (name == this.state.yearCycle.name || name == this.state.monthCycle.name) {
                    this.loadReview()
                }
            })


    }


    private checkAss = (goal: any, userId: any) => {
        const ass = [...goal.department, ...goal.behaviour, ...goal.activity];
        return ass.map((e: any) => {
            if (e.pfaByRm.length > 0) {
                const pfaByRm = e.pfaByRm.filter((e: any) => {
                    if (e.userCode == userId)
                        return true
                })
                return { ...e, pfaByRm }
            }
            else {
                return { ...e, pfaByRm: [], pfaByMn: [] }
            }
        })

    }

    private loadList = (designation: String, department: String) => {

        let params = this.props
        console.log("params ", params);

        getRepotie().then((res: any) => {

            if (res && res.result) {
                this.setState({
                    repotie: res.result
                })
            }
        });

        gradeList().then((res: any) => {
            if (res && res.result && res.result.length)
                this.setState({ pfaByRm: { ...this.state.pfaByRm, options: res.result } })
            this.setState({ pfaByMn: { ...this.state.pfaByMn, options: res.result } })
        });

        getCompetenciesList(designation).then((res: any) => {
            if (res && res.result && !isEmpty(res.result))
                this.setState({ name: { ...this.state.name, boptions: res.result.competencies }, });
        });

        getSkillsList(department).then((res: any) => {
            if (res && res.result && !isEmpty(res.result))
                this.setState({ name: { ...this.state.name, doptions: res.result.skills }, });
        });
        this.loadReview()
    }

    loadReview = () => {

        const values = queryString.parse(this.props.location.search);
        getGoalReview(values).then((res: any) => {

            if (res.result) {
                const access = this.checkAss(res.result, this.state.userId)
                this.setState({
                    user: res.result.user,
                    designation: res.result.designation,
                    department: { ...this.state.department, value: res.result ? res.result.department : [] },
                    behaviour: { ...this.state.behaviour, value: res.result ? res.result.behaviour : [] },
                    selfAppraisal: { ...this.state.selfAppraisal, value: res.result ? res.result.selfAppraisal : false },
                    activity: { ...this.state.activity, value: res.result ? res.result.activity : [] },
                    comments: { ...this.state.comments, value: res.result ? res.result.comments : '' },
                    userCode: { ...this.state.userCode, value: res.result ? res.result.userCode : '' },
                    code: { ...this.state.code, value: res.result ? res.result.code : '' },
                    repOff: res.result.reportingOfficer,
                    revOff: res.result.reviewingOfficer,
                    secRepOff: res.result.secondReportingOfficer,
                    secRevOff: res.result.secondReviewingOfficer,
                    edit: access.length > 0 ? (access.every((b: any) => (b.pfaByRm.length > 0))) : false
                })
            }
        });
    }

    private onSubmit = (e: any) => {
        debugger
        const { yearCycle, monthCycle, code, userCode, behaviour, department, activity, comments, } = this.state
        e.preventDefault();
        const model = {
            yearCycle: yearCycle.value,
            monthCycle: monthCycle.value,
            behaviour: behaviour.value,
            department: department.value,
            activity: activity.value,
            comments: comments.value,
            code: code.value,
            userCode: userCode.value,
        };
         
        if (validateForm(this, { behaviour, department, activity })) {
            let r = window.confirm("I have confirm my Assessment");
            if (r) {
                // this.setState({ showLoader: true })
                updateGoalMarks(model).then((response: any) => {
                    alert('Record Update Successfully')
                    // this.setState({ showLoader: false });
                    // if (response) {
                    //     this.props.history.push(CONSTANT.url.empAppraiseEmployee);
                    // }
                    if (response) {
                        this.props.history.push(CONSTANT.url.empAppraiseEmployee);
                    }
                });
            }
        }
    }
}

export default Review;
