import * as React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo_full.png';
import CONSTANT from '../../constant';
import { getCookie } from '../../utils';


class Header extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {

    }
    public render() {
        const { } = this.state;
        return (
            <React.Fragment>
                <header>
                    <div className="header-container">
                        <div className="fluid-container">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-4 col-lg-3">
                                        <div className="logo">
                                            <Link to={CONSTANT.url.dashboard}>
                                                <img src={logo} />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-8 col-lg-9">
                                        <nav className="navbar navbar-expand-lg menu-bar">
                                            <button className="navbar-toggler top-navbar-toggler" type="button" data-toggle="collapse"
                                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                                aria-expanded="false" aria-label="Toggle navigation">
                                                <i className="fa fa-bars" aria-hidden="true"></i>
                                            </button>
                                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                                <ul className="navbar-nav ml-auto">

                                                    <div className="btn-group user-profile" role="group">
                                                        <button id="btnGroupDrop1" type="button" className="btn dropdown-toggle"
                                                            data-toggle="dropdown">
                                                            {/* <img src="../PMS-admin/../PMS-admin/images/user-img.jpg" /> */}
                                                            {
                                                                JSON.parse(window.atob(getCookie('token').split('.')[1])).name
                                                            }                                                        </button>
                                                        <div className="dropdown-menu  dropdown-menu-right">

                                                            <Link className="dropdown-item" to={CONSTANT.url.changePassword}>Change Password</Link>
                                                            <Link className="dropdown-item" to={CONSTANT.url.logout}>Log Out</Link>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>

        )
    }
}

export default Header;