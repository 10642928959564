import * as React from 'react';
import { Link } from 'react-router-dom';

import { addSubPerformance, getAppraisal } from '../../../action/admin/Appraisal';
import Performance from '../../../component/common/Performance';
import Loader from '../../../component/common/Loader';
import { validateForm } from '../../../utils';
import CONSTANT from '../../../constant';

class EditEmployee extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            showLoader: false,
            appraisalList: undefined
        }


    }

    componentDidMount() {
        this.getAllAppraisal();
    }

    public render() {

        const { appraisalList, showLoader } = this.state;
        return (
            <React.Fragment>


                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">Add Sub Performance</h5>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        appraisalList &&
                                        <Performance
                                            onSubmit={this.onSubmit}
                                            appraisalList={appraisalList}
                                        />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>

        )
    }


    onSubmit = (model: any) => {
        this.setState({ showLoader: true });
        addSubPerformance(model)
            .then((res: any) => {
                this.setState({ showLoader: false });
                if (res) {
                    alert('Record Update Successfully')
                    this.props.history.push(CONSTANT.url.performanceList)
                }
            })
            .catch((err: any) => {
                alert(err)
                this.setState({ showLoader: false });

            })

    }

    private getAllAppraisal = () => {
        getAppraisal(undefined).then((response: any) => {
            this.setState({
                appraisalList: response.result,
            });
        }, (error: any) => {
            alert(error.Message);
            this.setState({ showLoader: false });
        });
    }

}

export default EditEmployee;