import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import CONSTANT from './constant';
import HEADER from './component/common/Header';
import SIDEBAR from './component/common/Sidebar';

import Login from './container/auth/login';
import Logout from './container/auth/logout';
import ForgetPassword from './container/auth/ForgetPassword';
import ResetPassword from './container/auth/ResetPassword';
import ChnagePassword from './container/auth/ChnagePassword';
import Dashboard from './container/admin/Dashboard';

import StartAppraisal from './container/admin/appraisal/List';
import EditAppraisal from './container/admin/appraisal/Edit';
import AddAppraisal from './container/admin/appraisal/Add';
import AppraisalHistory from './container/admin/appraisal/History';
import ViewGoal from './container/admin/ViewGoal';
import pendingAssement from './container/admin/appraisal/pendingAssesementEmployee';

import SubPerformanceList from './container/admin/subPerformance/List';
import EditSubPerformance from './container/admin/subPerformance/Edit';
import AddSubPerformance from './container/admin/subPerformance/Add';

import UploadEmployee from './container/admin/employee/upload';
import EmployeeList from './container/admin/employee/List';
import EditEmployee from './container/admin/employee/Edit';
import AddEmployee from './container/admin/employee/Add';
import EmployeeTransfer from './container/admin/employee/Transfer';

import BandList from './container/admin/band/BandList';
import AddBand from './container/admin/band/AddBand';
import EditBand from './container/admin/band/Editband';

import BehaviourList from './container/admin/behaviour/BehaviourList';
import AddBehaviour from './container/admin/behaviour/AddBehaviour';
import EditBehaviour from './container/admin/behaviour/EditBehaviour';

import DepartmentList from './container/admin/department/DepartmentList';
import AddDepartment from './container/admin/department/AddDepartment';
import EditDepartment from './container/admin/department/EditDepartment';
import Weightage from './container/admin/weightage/List';
import AddWeightage from './container/admin/weightage/Add';
import EditWeightage from './container/admin/weightage/Edit';
import Review from './container/employee/review/ReviewRm';
import GoalReview from './container/employee/review/ReviewByMn';
import EmployeeDashboard from './container/employee/Dashboard';

import EmpAppraisalCycle from './container/employee/appraisal/EmployeeAppraislList';
import EmpAppraisEmployee from './container/employee/appraisal/AppraisedEmpList';
import EmpGoalHistory from './container/employee/setGoal/GoalHistory';
import EmpReviewCycle from './container/employee/reviewCycle/List';
import EmpGoalApproved from './container/employee/setGoal/Approve';
import EmpEditGoal from './container/employee/setGoal/Edit';
import EmpDashboard from './container/employee/Dashboard';
import EmpAddGoal from './container/employee/setGoal/Add';
import EmpSetGoal from './container/employee/setGoal/List';

/**   EMPLOYEE */

import Report from './container/admin/reports/Reports';
import RatingReport from './container/admin/reports/Rating';

import { getCookie } from './utils';

const PrivateRouteAdmin = (props: any) => {
    const { userType } = JSON.parse(window.atob(getCookie('token').split('.')[1]));
    if (getCookie('token')) {
        if (userType === "admin") {
            return (
                <div>
                    <HEADER />
                    <SIDEBAR />
                    <Route {...props} />
                </div>

            )
        }
        else {
            return (
                <Redirect exact={true} to={{ pathname: CONSTANT.url.login, state: { from: props.path } }} />
            )
        }

    } else {
        return (
            <Redirect exact={true} to={{ pathname: CONSTANT.url.login, state: { from: props.path } }} />
        )
    }
};

const PrivateRouteEmployee = (props: any) => {
    const { userType } = JSON.parse(window.atob(getCookie('token').split('.')[1]));
    console.log(userType)
    if (getCookie('token')) {
        if (userType !== "admin") {
            return (
                <div>
                    <HEADER />
                    <SIDEBAR />
                    <Route {...props} />
                </div>

            )
        }
        else {
            return (
                <Redirect exact={true} to={{ pathname: CONSTANT.url.login, state: { from: props.path } }} />
            )
        }

    } else {
        return (
            <Redirect exact={true} to={{ pathname: CONSTANT.url.login, state: { from: props.path } }} />
        )
    }
};

const PrivateRoute = (props: any) => {
    if (getCookie('token')) {
        return (
            <div>
                <HEADER />
                <SIDEBAR />
                <Route {...props} />
            </div>
        )
    } else {
        return (
            <Redirect exact={true} to={{ pathname: CONSTANT.url.login, state: { from: props.path } }} />
        )
    }
};

const Router = (props: any) => {
    return (
        <div>
            <Switch>
                <Route exact={true} path={CONSTANT.url.login} component={Login} />
                <Route exact={true} path={CONSTANT.url.logout} component={Logout} />
                <Route exact={true} path={CONSTANT.url.forgetPassword} component={ForgetPassword} />
                <Route exact={true} path={CONSTANT.url.resetPassword} component={ResetPassword} />
                <PrivateRoute exact={true} path={CONSTANT.url.changePassword} component={ChnagePassword} />

                {/* EMPLOYEE ROUTES */}
                <PrivateRouteEmployee exact={true} path={CONSTANT.url.employeeDashboard} component={EmpDashboard} />
                <PrivateRouteEmployee exact={true} path={CONSTANT.url.empAppraisal} component={EmpAppraisalCycle} />
                <PrivateRouteEmployee exact={true} path={CONSTANT.url.empReviewCycle} component={EmpReviewCycle} />
                <PrivateRouteEmployee exact={true} path={CONSTANT.url.empGoalHistory} component={EmpGoalHistory} />
                <PrivateRouteEmployee exact={true} path={CONSTANT.url.empApproved} component={EmpGoalApproved} />
                <PrivateRouteEmployee exact={true} path={CONSTANT.url.empEditGoal} component={EmpEditGoal} />
                <PrivateRouteEmployee exact={true} path={CONSTANT.url.empSetGoal} component={EmpSetGoal} />
                <PrivateRouteEmployee exact={true} path={CONSTANT.url.empAddGoal} component={EmpAddGoal} />

                <PrivateRouteEmployee exact={true} path={CONSTANT.url.review} component={Review} />
                <PrivateRouteEmployee exact={true} path={CONSTANT.url.goalReview} component={GoalReview} />
                {/* Admin ROUTES */}
                <PrivateRouteEmployee exact={true} path={CONSTANT.url.empAppraiseEmployee} component={EmpAppraisEmployee} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.dashboard} component={Dashboard} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.employeeDashboard} component={EmployeeDashboard} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.employeeTransfer} component={EmployeeTransfer} />

                <PrivateRouteAdmin exact={true} path={CONSTANT.url.startAppraisal} component={StartAppraisal} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.editAppraisal} component={EditAppraisal} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.addAppraisal} component={AddAppraisal} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.appraisalHiistory} component={AppraisalHistory} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.viewGoal} component={ViewGoal} />

                <PrivateRouteAdmin exact={true} path={CONSTANT.url.performanceList} component={SubPerformanceList} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.editPerformance} component={EditSubPerformance} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.addPerformance} component={AddSubPerformance} />

                <PrivateRouteAdmin exact={true} path={CONSTANT.url.behaviourList} component={BehaviourList} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.editBehaviour} component={EditBehaviour} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.addBehaviour} component={AddBehaviour} />

                <PrivateRouteAdmin exact={true} path={CONSTANT.url.employeeList} component={EmployeeList} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.editEmployee} component={EditEmployee} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.addEmployee} component={AddEmployee} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.uploadEmployee} component={UploadEmployee} />

                <PrivateRouteAdmin exact={true} path={CONSTANT.url.bandList} component={BandList} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.editband} component={EditBand} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.addband} component={AddBand} />

                <PrivateRouteAdmin exact={true} path={CONSTANT.url.departmentList} component={DepartmentList} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.editDepartment} component={EditDepartment} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.addDepartment} component={AddDepartment} />

                <PrivateRouteAdmin exact={true} path={CONSTANT.url.weightage} component={Weightage} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.addWeightage} component={AddWeightage} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.editWeightage} component={EditWeightage} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.pendinAssesment} component={pendingAssement} />

                <PrivateRouteAdmin exact={true} path={CONSTANT.url.report} component={Report} />
                <PrivateRouteAdmin exact={true} path={CONSTANT.url.rating} component={RatingReport} />



            </Switch>
        </div>
    );
}

export default Router;
