import * as React from 'react';
import { getAppraisal } from '../../../action/admin/Appraisal';

class AppraisalHistory extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            appraisalList: undefined

        }
    }

    componentDidMount() {
        this.getAllAppraisal();
    }

    public render() {
        const { appraisalList } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-6 col-lg-6 pl-0">
                                                <h5 className="heading-h1">Appraisal History</h5>
                                            </div>
                                          
                                        </div>
                                    </div>
                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Appraisal
                                          </b>

                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Year Cycle</th>
                                                            <th scope="col">Remark</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            appraisalList === undefined && <tr>
                                                                <td className="text-center" colSpan={7}>Loading...</td>
                                                            </tr>
                                                        }
                                                        {
                                                            appraisalList !== undefined && appraisalList.length === 0 && <tr>
                                                                <td className="text-center" colSpan={7}>No records found</td>
                                                            </tr>
                                                        }
                                                        {
                                                            appraisalList !== undefined &&
                                                            appraisalList.map((item: any, ind: number) => {
                                                                console.log(item);

                                                                return (
                                                                    <tr>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.startDate}</td>
                                                                        <td>{item.endDate}</td>
                                                                        <td>{item.yearCycle}</td>
                                                                        <td>{item.remark}</td>
                                                                        <td > <span className={item.status == "Completed" ? "completed" : "pending"}>{item.status}</span></td>
                                                                        
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }

    private getAllAppraisal = () => {
        getAppraisal(undefined).then((response: any) => {
            if (response && response.result && response.result.length > 0)
            this.setState({
                appraisalList: response.result,
            });
        });
    }

}

export default AppraisalHistory;