import * as React from 'react';

import { getBehavioural, updateBehavioural } from '../../../action/admin/Behaviour';
import Behaviour from '../../../component/Behaviour';
import Loader from '../../../component/common/Loader';
import CONSTANT from '../../../constant';
import { validateForm } from '../../../utils';

class Editbehaviour extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            code: { value: props.match.params.code },
            behaviourList: undefined,
            showLoader: false
        }
    }

    componentDidMount() {
        this.loadList()

    }

    public render() {
        const { behaviourList, showLoader } = this.state;
        return (
            <React.Fragment>

                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">Edit Behaviour Skills</h5>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        behaviourList &&
                                        <Behaviour
                                            onSubmit={this.onSubmit}
                                            behaviour={behaviourList}
                                        />
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>

        )
    }

    loadList = () => {
        this.setState({ showLoader: true })
        getBehavioural(this.state.code.value)
            .then((res: any) => {
                if (res) {
                    if (res.result) {
                        this.setState({
                            behaviourList: res.result,
                            showLoader: false
                        });
                    }
                }
            })
    }
    onSubmit = (model: any) => {
        //  model.leaveTypeId = this.state.leaveTypeId.value
        this.setState({ showLoader: true });
        updateBehavioural(model)
            .then((res: any) => {
                alert('Record Update Successfully')
                this.setState({ showLoader: false });
                this.props.history.push(CONSTANT.url.behaviourList)
            })
            .catch((err: any) => {
                alert(err)
            })
    }
}

export default Editbehaviour;