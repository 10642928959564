import moment from 'moment';
import * as React from 'react';
import * as XLSX from "xlsx";
import Loder from '../../../component/common/Loader'
import JsonToPdf from '../../../component/common/Json-Pdf'
import RatingToPdf from '../../../component/common/RatingPdf'
import { validateForm, onChange, setOptions } from '../../../utils';
import {
    getAppraisalCycle, getEmpList, getDepartmentList, getBehaviourList, getRatingReport,
    userDetailSuperReports, getEmpGrade
} from '../../../action/Master';
import CONSTANT from '../../../constant';
import { data } from 'jquery';


class RatingReport extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            yearCycle: { name: 'yearCycle', value: '', options: [], error: '', isRequired: true },
            monthCycle: { name: 'monthCycle', value: '', options: [], error: '', isRequired: true },
            designation: { name: 'designation', value: '', options: [], error: '', isRequired: true },
            department: { name: 'department', value: '', options: [], error: '', isRequired: true, },
            filter: { name: 'filter', value: 'all', error: '', isRequired: true, },
            data: [],
            data1: undefined,
            showLoader: false,
            status: true,
            OverAllPer: [],
            filterData: [],
            UserListData: [],
            updateData: [],
            yearCycleData: [],
            gradeValue: [],
            EmpList: [],
            Grade: []


        }
    }

    componentDidMount() {
        this.loadList()
        this.loadGrade()
        this.loadEmployee()
        console.log("data1", this.state.data);

    }

    refreshScreen = () => {
        window.location.reload();
    }

    public render() {

        const { gradeValue, empCode, showLoader, data, data1, department, filter, designation, yearCycle, monthCycle, OverAllPer, UserListData, yearCycleData } = this.state;
        return (
            <React.Fragment>

                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-6 col-lg-6 pl-0">
                                                <h5 className="heading-h1">Appraisal Rating Report</h5>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Appraisal</b>
                                            <b onClick={() => this.refreshScreen()} className="pull-right"><i className="fa fa-refresh pull-right" aria-hidden="true"></i></b>
                                        </div>
                                        <div className="card-body">
                                            {
                                                <form>
                                                    <div className="col-lg-12 mb-2">
                                                        <div className="row">
                                                            <div className="col-lg-6 form-group">
                                                                <label > Appraisal Cycle  </label>
                                                                {this.renderSelect(yearCycle, false)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <button className="col-lg-8 btn button-full mt-4" onClick={(e) => this.generateEmpPdf(e)}>Search By Year </button>
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Sub Performance </label>
                                                                {this.renderSelect(monthCycle, true)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <button className="col-lg-8 btn button-full mt-4" onClick={(e) => this.generateEmpQuaterPdf(e)}>Search By Quater</button>
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Designation </label>
                                                                {this.renderSelect(designation, true)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <button className="col-lg-8 btn button-full mt-4 " onClick={(e) => this.generateDesignationPdf(e)}>View  Rating Report By Designation</button>
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Department</label>
                                                                {this.renderSelect(department, true)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <button className="col-lg-8 btn button-full mt-4" onClick={(e) => this.generateDepartmentPdf(e)}>View  Rating Report By Department</button>
                                                            </div>
                                                        </div>
                                                        {(data1) &&
                                                            <div className='row'>
                                                                <div className="col-lg-6 form-group">
                                                                    <label >Goal Tracker</label>
                                                                    <select name={filter.name} value={filter.value}
                                                                        onChange={this._onChange}
                                                                        className={filter.error.length > 0 ? "form-control is-invalid" : "form-control"}>
                                                                        <option value="all">All Employee</option>
                                                                        <option value="pending">Pending Employee</option>
                                                                        <option value="approved">Approved Employee</option>
                                                                        <option value="reject">Rejectd Employee</option>
                                                                        <option value="completed">Completed Employee</option>
                                                                    </select>
                                                                </div>
                                                                {/* <div className="col-lg-6 form-group">
                                                                    <button className="col-lg-6 btn  button-full mt-6" onClick={this.downloadXL}>Export Excel</button>
                                                                </div> */}
                                                            </div>
                                                        }
                                                        {/* <div className="row">
                                                            <div className="col-lg-4 form-group">
                                                                <button className=" btn button-full mt-4  pull" onClick={this.userDetailSuperReport}>Employee Detail Super Report</button>
                                                            </div>
                                                            <div className="col-lg-4 form-group">
                                                                <button className="btn button-full mt-4 pull" onClick={this.userMarksSuperReport} >Employee Marks Super Report</button>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </form>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {
                                        this.state.yearCycleData.length > 0 &&
                                        <RatingToPdf
                                            data={data}
                                            OverAllPer={OverAllPer}
                                            updateData={this.state.updateData}
                                            Grade={this.state.Grade}
                                            UserListData={UserListData}
                                            yearCycleData={yearCycleData}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    showLoader && <Loder />
                }
            </React.Fragment >

        )
    }

    private downloadXL = (e: any) => {
        window.location.reload();
        e.preventDefault()
        if (validateForm(this, this.state.filter)) {
            let date = new Date();
            let filename = `tract-record-${date.getTime()}.xlsx`;
            let ws_name = "Sheet1";
            const data = this.state.data1 && this.state.data1.length > 0 && this.state.data1.filter((item: any) => {
                // 
                if (this.state.filter.value === 'completed')
                    return item.goal.selfAppraisal === true;
                else if (this.state.filter.value === 'pending')
                    return item.goal.approved === 'pending';
                else if (this.state.filter.value === 'reject')
                    return item.goal.approved === 'reject';
                else if (this.state.filter.value === 'approved')
                    return item.goal.approved === 'approved';
                else return true

            })

            let dataToExport = data.length > 0 ? data.map((item: any, index: any) => {
                return {
                    'Name': item.name,
                    'Email Id': item.emailId,
                    'Designation': item.designation,
                    'Department': item.department,
                    'Goal Set Complete': item.goal.selfAppraisal ? 'YES' : 'NO',
                    'Approval of Reporting Officer': item.goal.approved,
                }

            })
                : []
            var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(dataToExport);
            // add worksheet to workbook /
            XLSX.utils.book_append_sheet(wb, ws, ws_name);
            // write workbook /
            XLSX.writeFile(wb, filename);
        }
    }

    renderSelect = (select: any, f: boolean) => {
        return (
            <select name={select.name} value={select.value}
            
                onChange={this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
                <React.Fragment>
                    <option>--Select--</option>
                    {(select.name === 'designation' || select.name === 'department') && <option value="all">All</option>}
                    {select.options.length && select.options.map((e: any, dIndex: number) => {
                        return e && (<option key={dIndex} value={(select.name === 'designation' || select.name === 'department') ? e.name : e.code}>{f ? e.name : e.yearCycle}</option>)
                    })}
                </React.Fragment>

            </select>)

    }

    private sort = (arr: any, order?: number) => {
        if (arr.length > 1)
            return arr.sort((a: any, b: any,) => b.createdOn - a.createdOn)
        else
            return arr
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value, () => {
            if (name === this.state.yearCycle.name) {
                let mnth = value && this.state.yearCycle.options.filter((res: any) => { return res.code == value; }).map((res: any) => res.performance)
                this.setState({
                    monthCycle: { ...this.state.monthCycle, options: this.sort(mnth[0]) },
                })

            }
        })

    }
    private loadList = () => {
        getAppraisalCycle().then((res: any) => {
            if (res && res.result && res.result.length) {
                const mnth = res.result[0].performance.length > 0 ? this.sort(res.result[0].performance) : [];
                this.setState({
                    yearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[0].code },
                    monthCycle: { ...this.state.monthCycle, options: mnth, value: mnth.length > 0 ? mnth[0].code : '' },
                    showLoader: false,
                });
            }
        });

        getBehaviourList().then((response: any) => {
            if (response && response.result && response.result.length > 0)
                setOptions(this, 'designation', response.result)

        });
        getDepartmentList().then((response: any) => {
            if (response && response.result && response.result.length > 0)
                setOptions(this, 'department', response.result)
        });
    }

    private loadEmployee = () => {
        getEmpList().then((res: any) => {
            if (res) {
                this.setState({ EmpList: res.result });
            }
        });
    }

    private loadGrade = () => {
        getEmpGrade().then((res: any) => {
            if (res) {
                this.setState({ Grade: res.result });
            }
        });
    }

    private generateDesignationPdf = (e: any) => {
        e.preventDefault()
        const { yearCycle, monthCycle, designation } = this.state
        if (validateForm(this, { yearCycle, monthCycle, designation })) {
            this.setState({ showLoader: true })
            getRatingReport({ yearCycle: yearCycle.value, monthCycle: '--Select--', designation: designation.value })
                .then((response: any) => {
                    this.setState({ showLoader: false })
                    if (response && response.result && response.result.length > 0)
                        this.setState({ data: response.result, })
                    this.filterUser()
                });
        }
    }

    private generateDepartmentPdf = (e: any) => {
        e.preventDefault()
        const { yearCycle, monthCycle, department } = this.state
        if (validateForm(this, { yearCycle, monthCycle, department })) {
            this.setState({ showLoader: true })
            getRatingReport({ yearCycle: yearCycle.value, monthCycle: '--Select--', department: department.value })
                .then((response: any) => {
                    this.setState({ showLoader: false })
                    if (response && response.result && response.result.length > 0)
                        this.setState({ data: response.result, })
                    this.filterUser()
                });
        }
    }

    private generateEmpPdf = (e: any) => {
        e.preventDefault()

        const { yearCycle, monthCycle, empCode } = this.state
        if (validateForm(this, { yearCycle, monthCycle, empCode })) {
            this.setState({ showLoader: true })
            getRatingReport({ yearCycle: yearCycle.value, monthCycle: '--Select--' })
                .then((response: any) => {
                    this.setState({ showLoader: false })
                    if (response && response.result && response.result.length > 0)
                        this.setState({ data: response.result, })
                    this.filterUser()
                });
        }
    }
    /***Add by Jitender*** */
    private generateEmpQuaterPdf = (e: any) => {
        e.preventDefault()

        const { yearCycle, monthCycle, empCode } = this.state
        if (validateForm(this, { yearCycle, monthCycle, empCode })) {
            this.setState({ showLoader: true })
            getRatingReport({ yearCycle: yearCycle.value, monthCycle: monthCycle.value })
                .then((response: any) => {
                    this.setState({ showLoader: false })
                    if (response && response.result && response.result.length > 0)
                        this.setState({ data: response.result, })
                    this.filterUser()
                });
        }
    }
    /***End*** */


    filterUser = () => {
        const { data, EmpList } = this.state;
        
        let goalFilter: any = [];
        let snewFilterUser: any = [];

        for (let x = 0; x < EmpList.length; x++) {
            const item = EmpList[x];
            goalFilter = data.filter((i: any) => i.empCode === item.empCode)
            snewFilterUser.push(goalFilter)
            // x += goalFilter.length - 1
        }
        
        let filterData = snewFilterUser.filter((i: any) => i.length != 0)
        this.userDataCal(filterData)

        // const item = data;
        // data.filter(function (j: any) {
        //     goalFilter = item.filter((i: any) => (i.empCode == j.empCode))
        //     snewFilterUser.push(goalFilter)
        // });

    }

    userDataCal = (filterData: any) => {
        
        let OverallAverArr: any = []
        let newFilterUser: any = []
        let yearCycleData: any = []

        let au: any = []

        for (let value of filterData) {
            // if (value.length === 5) {
            //     au.push(value)
            
            let overAverage = 0;
            let marksGrade: any = []

            value.filter((i: any) => {
                
                if ((parseInt(i.goal.totalMarks) < 30)) {
                    marksGrade.push({ status: i.goal.activity[0].pfaByRm.length === 0 ? "" : "Un Satisfactory", monthCycle: i.goal.monthCycle })

                } else if (parseInt(i.goal.totalMarks) > 30 && parseInt(i.goal.totalMarks) <= 50) {
                    marksGrade.push({ status: "Satisfactory", monthCycle: i.goal.monthCycle })

                } else if (parseInt(i.goal.totalMarks) > 50 && parseInt(i.goal.totalMarks) <= 74) {
                    marksGrade.push({ status: "Good", monthCycle: i.goal.monthCycle })
                }
                else if (parseInt(i.goal.totalMarks) > 74 && parseInt(i.goal.totalMarks) <= 89) {
                    marksGrade.push({ status: "Very Good", monthCycle: i.goal.monthCycle })
                }
                else {
                    marksGrade.push({ status: "Out Standing", monthCycle: i.goal.monthCycle })
                }
                overAverage += parseInt((i.goal.totalMarks))
            })

            let average = overAverage / 4;

            if (average < 30) {
                OverallAverArr.push({ Grade: average === 0 ? "" : "Un Satisfactory", "average": average })
            }
            else if (average > 30 && average <= 50) {
                OverallAverArr.push({ Grade: "Satisfactory", "average": average })

            } else if (average > 50 && average <= 74) {
                OverallAverArr.push({ Grade: "Good", "average": average })
            }
            else if (average > 74 && average <= 89) {
                OverallAverArr.push({ Grade: "Very Good", "average": average })
            }
            else {
                OverallAverArr.push({ Grade: "Out Standing", "average": average })
            }
            
            newFilterUser.push({ "uniqueNames": value[0].name, "uniqueEmpCode": value[0].empCode, "uniqueDesignation": value[0].designation, "uniqueDepartment": value[0].department, "marksGrade": marksGrade });
            yearCycleData.push({ "yearCycle": value[0].yearCycle })
        }
       
        this.setState({ OverAllPer: OverallAverArr })
        this.setState({ updateData: newFilterUser })
        this.setState({ yearCycleData: yearCycleData })
    }



}

// finalReport = (newFilterUser: any) => {
//     debugger
//     let aveArr: any = []
//     newFilterUser.map((i: any) => {
//         let finalvalue = 0;
//         for (let j = 0; j < i.totalMarks.length; j++) {
//             let g = i.totalMarks[j]
//             finalvalue += parseInt(g);
//         }
//         let average = finalvalue / 4;

//         if (average < 30) {
//             aveArr.push({ Grade: "UnSatisfactory", "average": average })
//         }
//         else if (average > 30 && average <= 50) {
//             aveArr.push({ Grade: "Satisfactory", "average": average })

//         } else if (average > 50 && average <= 74) {
//             aveArr.push({ Grade: "Good", "average": average })
//         }
//         else if (average > 74 && average <= 89) {
//             aveArr.push({ Grade: "Very Good", "average": average })
//         }
//         else {
//             aveArr.push({ Grade: "Out Standing", "average": average })
//         }

//     })
//     this.setState({ OverAllPer: aveArr })

// }
// finalPercentage = (finalvalue: any) => {
//     let finalPercentage = 0;
//     finalPercentage = finalvalue / 4
//     this.setState({ OverAllPer: finalPercentage })
// }

//     private checkAss = (goal: any, userId: any) => {

//     const ass = goal ? [...goal.department, ...goal.behaviour, ...goal.activity] : [];
//     return ass.length > 0 ?
//         ass.map((e: any) => {

//             if (e.pfaByMn.length > 0) {
//                 const pfaByMn = e.pfaByMn.filter((e: any) => {

//                     if (e.userCode == userId)
//                         return true
//                 })
//                 return { ...e, pfaByMn }
//             }
//             else if (e.pfaByRm.length > 0) {
//                 const pfaByRm = e.pfaByRm.filter((e: any) => {
//                     if (e.userCode == userId)
//                         return true
//                 })
//                 return { ...e, pfaByRm }
//             }
//             else {
//                 return { ...e, pfaByRm: [], pfaByMn: [] }
//             }
//         })
//         :
//         []
// }

// refreshPage = (e: any) => {
//     window.location.reload(false);

// }






export default RatingReport;