import * as React from 'react';
import { Link } from 'react-router-dom';

import { login, forgotPassword } from '../../action/AuthAction'
import { isEmpty, onChange, setCookie } from './../../utils';
import Loader from '../../component/common/Loader';
import CONSTANT from './../../constant';

class Login extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            emailId: { name: 'emailId', value: '', isRequired: true, error: '' },
            empCode: { name: 'empCode', value: '', isRequired: true, error: '' },
            showLoader: false
        }
    }

    public render() {
        const { emailId,empCode, showLoader } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="login-container">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6 login-form-container mx-auto">
                                    <div className="card px-4 py-4">
                                        <h2 className="text-left">Forgot Password</h2>
                                        <p>Worried about payroll & compliance? PMS Consulting provides the best Payroll Outsourcing solution.</p>
                                        <form className="login-form">
                                            <div className="form-group">
                                                <label>Email ID</label>
                                                <input
                                                    type="email"
                                                    className={emailId.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                    placeholder="abc@12"
                                                    name={emailId.name}
                                                    value={emailId.value}
                                                    onChange={this._onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Employee Id</label>
                                                <input
                                                    type="text"
                                                    className={empCode.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                    placeholder="Mumbai2021"
                                                    name={empCode.name}
                                                    value={empCode.value}
                                                    onChange={this._onChange}
                                                />
                                            </div>
                                            <div className="mt-4">
                                                <button onClick={this._onSubmit} className="btn button-full float-right">Submit</button>
                                                <Link to={CONSTANT.url.login} className="btn col-lg-12  float-right btn-secondary mt-2  btn-secondary mt-2 pull-right">Cancel</Link>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value)
    }

    private _onSubmit = (e: any) => {
        debugger
        
        e.preventDefault();

        const st = this.state;
        const model = {
            emailId: st.emailId.value,
            empCode: st.empCode.value
        };

        this.setState({ showLoader: true })
        forgotPassword(model).then((response: any) => {
            this.setState({ showLoader: false });
            if (response) {
                this.props.history.push(CONSTANT.url.login);
            }
        });
    }



}

export default Login;