import * as React from 'react';

import { addWeightage } from '../../../action/admin/weightage';
import AddWeightage from '../../../component/Weightage'
import { onChange, } from '../../../utils';
import CONSTANT from '../../../constant';

class weightage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

    }

    public render() {
        return (
            <React.Fragment>
                <AddWeightage
                    weightage={this.state}
                    onSubmit={this.onSubmit}
                />
            </React.Fragment >

        )
    }




    onSubmit = (model: any) => {

        this.setState({ showLoader: true });
        addWeightage(model)
            .then((res: any) => {
                alert('Record Update Successfully')
                this.setState({ showLoader: false });
                this.props.history.push(CONSTANT.url.weightage)
            })
    }

}

export default weightage;