import * as React from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import { changePassword } from '../../action/AuthAction'
import { onChange, validateForm } from '../../utils';
import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';

class ChangePassword extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            password: { name: 'password', value: '', isRequired: true, error: '' },
            oldPassword: { name: 'oldPassword', value: '', isRequired: true, error: '' },
            showLoader: false
        }
    }

    public render() {
        const { password, oldPassword, showLoader } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="login-container">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-6 login-form-container mx-auto">
                                    <div className="card px-4 py-4">
                                        <h2 className="text-left">Change Password</h2>
                                        <p>Worried about payroll & compliance? PMS Consulting provides the best Payroll Outsourcing solution.</p>
                                        <form className="login-form">
                                            <div className="form-group">
                                                <label>Old Password</label>
                                                <input
                                                    type="email"
                                                    className={oldPassword.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                    placeholder="*******"
                                                    name={oldPassword.name}
                                                    value={oldPassword.value}
                                                    onChange={this._onChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>New Password</label>
                                                <input
                                                    type="email"
                                                    className={password.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                    placeholder="*******"
                                                    name={password.name}
                                                    value={password.value}
                                                    onChange={this._onChange}
                                                />
                                            </div>

                                            <div className="mt-4">
                                                <button onClick={this._onSubmit} className="btn button-full float-right">Submit</button>
                                                <Link to={CONSTANT.url.dashboard} className="btn col-lg-12  float-right  btn-secondary mt-2 pull-right">Cancel</Link>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    showLoader && <Loader />
                }
            </React.Fragment>
        )
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value)
    }

    private _onSubmit = (e: any) => {
        e.preventDefault();
        const { password, oldPassword } = this.state
        const model = { password: password.value, oldPassword: oldPassword.value };
        if (validateForm(this, { password, oldPassword })) {
            this.setState({ showLoader: true })
            changePassword(model).then((response: any) => {
                this.setState({ showLoader: false });
                if (response) {
                    alert('Password Update successfully.')
                    this.props.history.push(CONSTANT.url.dashboard);

                }
            });
        }
    }



}

export default ChangePassword;