import * as React from 'react';
import { Link } from 'react-router-dom';

import { getBand } from '../../../action/admin/Band';
import CONSTANT from '../../../constant';

class BandList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            bandList: undefined

        }
    }

    componentDidMount() {
        this.getAllBand();
    }

    public render() {
        const { bandList } = this.state;
        return (
            <React.Fragment>
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-6 col-lg-6 pl-0">
                                                <h5 className="heading-h1">Define Band List</h5>
                                            </div>

                                            <div className="col-6 col-lg-6 text-right pr-0">
                                                <Link to={CONSTANT.url.addband} className="action-btn">
                                                    <i
                                                        className="fa fa-check-square-o"></i>&nbsp; Add Band
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b> Band List</b>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Appraisal Cycle</th>
                                                            <th scope="col">Grade</th>
                                                            <th scope="col">From</th>
                                                            <th scope="col">To</th>
                                                            <th scope="col">Edit</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            bandList === undefined && <tr>
                                                                <td className="text-center" colSpan={7}>Loading...</td>
                                                            </tr>
                                                        }
                                                        {
                                                            bandList !== undefined && bandList.length === 0 && <tr>
                                                                <td className="text-center" colSpan={7}>No records found</td>
                                                            </tr>
                                                        }
                                                        {
                                                            bandList !== undefined &&
                                                            bandList.map((item: any, ind: number) => {
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.appraisalCycle.yearCycle}</td>
                                                                        <td>{item.grade}</td>
                                                                        <td>{item.fromMark}</td>
                                                                        <td>{item.toMark}</td>
                                                                       
                                                                        <th scope="col">

                                                                            <Link to={CONSTANT.url.editband.replace(':code', item.code)}> <i className="fa fa-pencil"></i> </Link>

                                                                        </th>
                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }

    private getAllBand = () => {
        getBand(undefined).then((response: any) => {
            if (response && response.result)
                this.setState({
                    bandList: response.result.length > 0 ? response.result : [],
                    showLoader: false
                });
        });
    }

}

export default BandList;