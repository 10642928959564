import * as XLSX from "xlsx";
import * as React from 'react';
import { Link } from 'react-router-dom';

import { getGoalHistory, getAppraisalCycle, empDetailSuperReports } from '../../../action/Master';
import { onChange, getCookie, validateForm } from '../../../utils';

class GoalHistory extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            excel: [],
            goalList: undefined,
            yearCycle: { value: '', name: 'yearCycle', options: [], error: '', isRequired: true },
            yearCycle1: { value: '', name: 'yearCycle1', options: [], error: '', isRequired: true },
            monthCycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            code: JSON.parse(window.atob(getCookie('token').split('.')[1])).code,
        }


    }

    componentDidMount() {
        getAppraisalCycle().then((res: any) => {
            if (res && res.result && res.result.length)
                this.setState({
                    yearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[0].code },
                    yearCycle1: { ...this.state.yearCycle1, options: res.result, value: res.result[0].code },
                    monthCycle: { ...this.state.monthCycle, options: res.result[0].performance },
                }, () => {
                    // this.loadList(this.state.yearCycle1.value)
                }
                );
        });
    }

    public render() {

        const { yearCycle, monthCycle, yearCycle1, goalList } = this.state;
        return (
            <React.Fragment>
                <section>

                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="card mt-2">
                                <div className="card-header">
                                    <b>Super Report </b>
                                </div>
                                <div className="card-body">
                                    {
                                        <form>
                                            <div className="col-lg-12 mb-2">
                                                <div className="row">
                                                    <div className="col-lg-6 form-group">
                                                        <label > Appraisal Cycle  </label>
                                                        {this.renderSelect(yearCycle, false)}
                                                    </div>
                                                    <div className="col-lg-6 form-group">
                                                        <label >Sub Performance </label>
                                                        {this.renderSelect(monthCycle, true)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 form-group">
                                                <button className="col-lg-4 btn button-full mt-4 pull-right" onClick={this.generateEmpEXCEL}>Genrate EXCEL Report</button>
                                            </div>
                                        </form>
                                    }
                                </div>
                            </div>
                            {/* {this.history()} */}
                        </div>
                    </div>
                </section>


            </React.Fragment>

        )
    }

    renderSelect = (select: any, f: boolean) => {
        return (<select name={select.name} value={select.value}
            onChange={this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {select.options.length && select.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.code}> {f ? e.name : e.yearCycle}</option>)
                })}
            </React.Fragment>
        </select>)
    }

    private history = () => {
        const { yearCycle1, goalList } = this.state;
        return (
            <div className="row">
                <div className="col-lg-12">
                    <div className="col-lg-12 mt-4">
                        <div className="row">
                            <div className="col-lg-6 pl-0">
                                <h5 className="heading-h1">Goal History </h5>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-2">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-lg-8 pt-2">
                                    <b >  Appraisal / Review
                                                     </b>
                                </div>
                                <div className="col-lg-4">
                                    {this.renderSelect(yearCycle1, false)}
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table ">
                                    <thead>

                                        <tr>
                                            <th scope="col">quarter</th>
                                            <th scope="col">Start Behaviour</th>
                                            <th scope="col">Weightage</th>
                                            <th scope="col">Reporting Officer Marks</th>
                                            <th scope="col">Reviewing Officer Marks</th>
                                            {goalList !== undefined && goalList.length > 0 && goalList[0].marks.behaviour[0].l3Marks && <th scope="col">Reporting Officer Marks</th>}
                                            {goalList !== undefined && goalList.length > 0 && goalList[0].marks.behaviour[0].l4Marks && <th scope="col">Reviewing Officer Marks</th>}
                                            <th scope="col">Total Marks</th>

                                        </tr>

                                    </thead>
                                    <tbody>

                                        {
                                            goalList === undefined && <tr>
                                                <td className="text-center" colSpan={9}>Loading...</td>
                                            </tr>
                                        }
                                        {
                                            goalList !== undefined && goalList.length === 0 && <tr>
                                                <td className="text-center" colSpan={9}>No records found</td>
                                            </tr>
                                        }

                                        {
                                            goalList !== undefined && goalList.length > 0 && goalList.map((list: any, ind: number) => (
                                                list.marks.behaviour && list.marks.behaviour.map((b: any, i: number) => (
                                                    <tr key={i} className={ind % 2 == 0 ? 'even' : ''}>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.monthCycle}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.name}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.weightage}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l1Marks}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l2Marks}</td>
                                                        {b.l3Marks && <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l3Marks}</td>}
                                                        {b.l4Marks && <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l4Marks}</td>}
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.totalMarks}</td>
                                                    </tr>
                                                ))

                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table ">
                                    <thead>
                                        <tr>
                                            <th scope="col">quarter</th>
                                            <th scope="col">Department Skills</th>
                                            <th scope="col">Weightage</th>
                                            <th scope="col">Reporting Officer Marks</th>
                                            <th scope="col">Reviewing Officer Marks</th>
                                            {goalList !== undefined && goalList.length > 0 && goalList[0].marks.department[0].l3Marks && <th scope="col">Reporting Officer Marks</th>}
                                            {goalList !== undefined && goalList.length > 0 && goalList[0].marks.department[0].l4Marks && <th scope="col">Reviewing Officer Marks</th>}
                                            <th scope="col">Total Marks</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            goalList === undefined && <tr>
                                                <td className="text-center" colSpan={9}>Loading...</td>
                                            </tr>
                                        }
                                        {
                                            goalList !== undefined && goalList.length === 0 && <tr>
                                                <td className="text-center" colSpan={9}>No records found</td>
                                            </tr>
                                        }

                                        {
                                            goalList !== undefined && goalList.length > 0 && goalList.map((list: any, ind: number) => (
                                                list.marks.department && list.marks.department.map((b: any, i: number) => (
                                                    <tr key={i} className={ind % 2 == 0 ? 'even' : ''}>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.monthCycle}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.name}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.weightage}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l1Marks}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l2Marks}</td>
                                                        {b.l3Marks && <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l3Marks}</td>}
                                                        {b.l4Marks && <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l4Marks}</td>}
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.totalMarks}</td>
                                                    </tr>
                                                ))

                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table ">
                                    <thead>
                                        <tr>
                                            <th scope="col">quarter</th>
                                            <th scope="col">Activities  / Task</th>
                                            <th scope="col">Weightage</th>
                                            <th scope="col">Reporting Officer Marks</th>
                                            <th scope="col">Reviewing Officer Marks</th>
                                            {goalList !== undefined && goalList.length > 0 && goalList[0].marks.activity[0].l3Marks && <th scope="col"> Second Reporting Officer Marks</th>}
                                            {goalList !== undefined && goalList.length > 0 && goalList[0].marks.activity[0].l4Marks && <th scope="col">Second Reviewing Officer Marks</th>}
                                            <th scope="col">Total Marks</th>


                                        </tr>
                                    </thead>
                                    {/* <tbody><td> saif</td> <tr> </tr> </tbody> */}
                                    <tbody>
                                        {
                                            goalList === undefined && <tr>
                                                <td className="text-center" colSpan={9}>Loading...</td>
                                            </tr>
                                        }
                                        {
                                            goalList !== undefined && goalList.length === 0 && <tr>
                                                <td className="text-center" colSpan={9}>No records found</td>
                                            </tr>
                                        }
                                        {
                                            goalList !== undefined && goalList.length > 0 && goalList.map((list: any, ind: number) => (
                                                list.marks.activity && list.marks.activity.map((b: any, i: number) => (
                                                    <tr key={i} className={ind % 2 == 0 ? 'even' : ''}>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.monthCycle}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.name}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.weightage}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l1Marks}</td>
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l2Marks}</td>
                                                        {b.l3Marks && <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l3Marks}</td>}
                                                        {b.l4Marks && <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.l4Marks}</td>}
                                                        <td className={ind % 2 == 0 ? 'evenTd' : ''}>{b.totalMarks}</td>
                                                    </tr>
                                                ))

                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private _onChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === this.state.yearCycle.name) {
            let mnth = value && this.state.yearCycle.options.filter((res: any) => { return res.code == value; }).map((res: any) => res.performance)
            mnth = mnth.length && mnth[0].sort((a: any, b: any,) => b.createdOn - a.createdOn);
            this.setState({ monthCycle: { ...this.state.monthCycle, options: mnth }, })
        }
        if (name === this.state.yearCycle1.name)
            this.loadList(value)

        onChange(this, name, value)
    }

    private generateEmpEXCEL = (e: any) => {
        e.preventDefault()
        const { yearCycle, monthCycle, code } = this.state
        if (validateForm(this, { yearCycle, monthCycle })) {
            empDetailSuperReports({ yearCycle: yearCycle.value, monthCycle: monthCycle.value, code })
                .then((response: any) => {
                    this.setState({ showLoader: false })
                    if (response && response.result && response.result.length > 0) {
                        let date = new Date();
                        let filename = `employee-detail-super-report-${date.getTime()}.xlsx`;
                        let ws_name = "Sheet1";

                        let dataToExport = response.result.map((item: any, index: any) => {
                            const ass1 = item.reportingOfficer.length > 0 ? this.checkAss(item.goal, item.reportingOfficer[0].code) : []
                            const ass2 = item.reviewingOfficer.length > 0 ? this.checkAss(item.goal, item.reviewingOfficer[0].code) : []
                            const ass3 = item.secondReportingOfficer.length > 0 ? this.checkAss(item.goal, item.secondReportingOfficer[0].code) : []
                            const goalApprovel: any = [...item.reportingOfficer, ...item.secondReportingOfficer].filter((e) => {
                                if (e)
                                    if (item.approvedBy && item.approvedBy.length > 0 && e.empCode == item.approvedBy[0].empCode)
                                        return true
                            })
                            return {
                                'Appraisal Cycle': item.yearCycle ? item.yearCycle.yearCycle:'',
                                'Sub Appraisal Cycle': item.subPerformanceCycle ? item.subPerformanceCycle.name:'',
                                'Maximum Weightage For Behavior': item.weightage ? item.weightage.maxBehaviour : '',
                                'Weightage  For Behavior': item.weightage ? item.weightage.behaviour : '',
                                'Maximum Weightage For Department': item.weightage ? item.weightage.maxDepartment : '',
                                'Weightage  For Department': item.weightage ? item.weightage.department : '',
                                'Maximum Weightage For KRA ': item.weightage ? item.weightage.maxActivities : '',
                                'Weightage  For KRA ': item.weightage ? item.weightage.activities : '',
                                'EMP CODE': item.empCode,
                                'EMP NAME': item.name,
                                'Designation': item.designation,
                                'Department': item.department,
                                'Employee Email Id': item.emailId,
                                'Mobile No': 'N/A',
                                'User Type': item.userType,
                                'Location': item.location,
                                'Status': item.status,
                                'Goal Submit': item.goal ? 'YES' : 'NO',
                                'Self Appraisal Complete': item.goal ? (item.selfAppraisal ? 'Yes' : 'No') : 'N/A',
                                'Goal approval Officer Code': goalApprovel.length > 0 ? goalApprovel[0].empCode : 'N/A',
                                'Goal approval Officer Name': goalApprovel.length > 0 ? goalApprovel[0].name : 'N/A',
                                'Goal approval  Officer Designation ': goalApprovel.length > 0 ? goalApprovel[0].designation : 'N/A',
                                'Goal approval Officer Email': goalApprovel.length > 0 ? goalApprovel[0].emailId : 'N/A',
                                'Goal Approved': item.goal ? (item.approved == 'approved' ? 'Yes' : 'No') : 'N/A',
                                'Goal Rejected': item.goal ? (item.approved == 'reject' ? 'Yes' : 'No') : 'N/A',
                                'First Reporting Officer Code': item.reportingOfficer.length > 0 ? item.reportingOfficer[0].empCode : 'N/A',
                                'First Reporting Officer Name': item.reportingOfficer.length > 0 ? item.reportingOfficer[0].name : 'N/A',
                                'First Reporting Officer Designation ': item.reportingOfficer.length > 0 ? item.reportingOfficer[0].designation : 'N/A',
                                'First Reporting Officer Email': item.reportingOfficer.length > 0 ? item.reportingOfficer[0].emailId : 'N/A',
                                'First Reporting Officer Assessment Made': ass1.length > 0 ? ass1.every((e) => (e.pfaByRm.length > 0)) == true ? 'YES' : 'NO' : 'N/A',
                                'Reporting  and Reviewing officer are Same  ': item.reportingOfficer.length > 0 ? (item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].code == item.reportingOfficer[0].code ? 'YES' : 'NO' : 'N0') : 'N/A',
                                'Reviewing Officer Code': item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].empCode : 'N/A',
                                'Reviewing Officer Name': item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].name : 'N/A',
                                'Reviewing Officer Designation': item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].designation : 'N/A',
                                'Reviewing Officer Email': item.reviewingOfficer.length > 0 ? item.reviewingOfficer[0].emailId : 'N/A',
                                'Reviewing Officer Assessment Made': ass2.length > 0 ? ass2.every((e) => (e.pfaByRm.length > 0)) == true ? 'YES' : 'NO' : 'N/A',
                                'Dual Reporting ': item.secondReportingOfficer.length > 0 ? 'YES' : 'NO',
                                'Second Reporting Officer Code': item.secondReportingOfficer.length > 0 ? item.secondReportingOfficer[0].empCode : 'N/A',
                                'Second Reporting Officer Name': item.secondReportingOfficer.length > 0 ? item.secondReportingOfficer[0].name : 'N/A',
                                'Second Reporting Officer Designation ': item.secondReportingOfficer.length > 0 ? item.secondReportingOfficer[0].designation : 'N/A',
                                'Second Reporting Officer Email': item.secondReportingOfficer.length > 0 ? item.secondReportingOfficer[0].emailId : 'N/A',
                                'Second Reporting Officer Assessment Made': ass3.length > 0 ? ass3.every((e) => (e.pfaByRm.length > 0)) == true ? 'YES' : 'NO' : 'N/A',
                            }
                        })
                        // console.log(dataToExport)
                        var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(dataToExport);
                        // add worksheet to workbook /
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);
                        // write workbook /
                        XLSX.writeFile(wb, filename);
                    }

                });
        }

    }

    private checkAss = (goal: any, userId: any) => {

        const ass = goal ? [...goal.department, ...goal.behaviour, ...goal.activity] : [];
        return ass.length > 0 ?
            ass.map((e: any) => {

                if (e.pfaByMn.length > 0) {
                    const pfaByMn = e.pfaByMn.filter((e: any) => {

                        if (e.userCode == userId)
                            return true
                    })
                    return { ...e, pfaByMn }
                }
                else if (e.pfaByRm.length > 0) {
                    const pfaByRm = e.pfaByRm.filter((e: any) => {
                        if (e.userCode == userId)
                            return true
                    })
                    return { ...e, pfaByRm }
                }
                else {
                    return { ...e, pfaByRm: [], pfaByMn: [] }
                }
            })
            :
            []
    }

    loadList = (yearCycle: any) => {
        getGoalHistory(yearCycle)
            .then((res: any) => {
                if (res)
                    this.setState({ goalList: res.result })
            })
    }



}

export default GoalHistory;